import React, { useState } from "react";
import { Modal } from "antd";
import { videoPreview } from "../../../../utils/imagepath";

const MediaGallery = (subItem: any, type: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");

  const handleOpenModal = (url: any) => {
    setMediaUrl(url);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setMediaUrl("");
  };

  return (
    <>
      {subItem.type == "image" &&
        subItem?.subItem?.images !== "" &&
        subItem?.subItem?.images.map((item: any, index: any) => {
          return (
            <>
              <li key={index}>
                <div className="gallery-widget">
                  <div
                    onClick={() =>
                      handleOpenModal(
                        `${item}`
                      )
                    }
                  >
                    <span className="icon-overlay">
                      <i className="ti ti-eye" />
                    </span>
                    <img
                      className="img-fluid"
                      src={`${item}`}
                      alt="Image"
                    />
                  </div>
                </div>
              </li>
            </>
          );
        })}

      {subItem.type == "video" && subItem?.subItem?.video !== "" && (
        <li>
          <div className="gallery-widget">
            <div
              onClick={() =>
                handleOpenModal(
                  `${subItem?.subItem?.video}`
                )
              }
            >
              <span className="icon-overlay">
                <i className="ti ti-player-play" />
              </span>
              <img
                className="img-fluid"
                src={videoPreview}
                alt="videoPreview"
              />
            </div>
          </div>
        </li>
      )}

      <Modal
        title={subItem.type === "video" ? "Video Player" : "Image Viewer"}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        centered
      >
        {subItem.type === "video" ? (
          <video controls src={mediaUrl} style={{ width: "100%" }} />
        ) : (
          <img src={mediaUrl} alt="Modal Content" style={{ width: "100%" }} />
        )}
      </Modal>
    </>
  );
};

export default MediaGallery;
