import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../../../utils/routes";

import PrimeDataTable from "../../../../components/data-table";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../utils/shared.module";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import AddEditVehicles from "./add-edit-vehicles";
import { ConfirmDialog } from "primereact/confirmdialog";
import { noImg } from "../../../../utils/imagepath";
import {
  setEditData,
  setVehicleEditData,
} from "../../../../core/redux/commonSlice";

const VehiclesList = () => {
  const { putData, getData, deleteData } = useContext(ApiServiceContext);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [count, setCount] = useState<number>(0);
  const [vehicleType, setVehicleType] = useState<any>([]);
  const planData = useSelector((state: any) => state.login.planDetails);
  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(listData.map((item) => item.vc_id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };
  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    vehicleId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(vehicleId);
    } else {
      newSelectedRows.delete(vehicleId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const truck_id = row;
    try {
      const payload = {
        id: truck_id,
        isActive: isChecked,
      };
      let url = end_points.update_truck_status.url;

      const response = await putData(url, payload);
      if (response.status === 200) {
        getList();
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };

  const getVehicleTypeId = async () => {
    let url = end_points.get_vehicletype.url;
    const response = await getData(url);
    const vehicleTypes = response?.data?.data?.vehicle_type?.map(
      (vehicle: any) => {
        return { name: vehicle?.type_name, code: vehicle?.id };
      }
    );
    setVehicleType(vehicleTypes);
  };

  useEffect(() => {
    getVehicleTypeId();
  }, []);

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data.vc_id)}
              onChange={(e) => handleRowCheckboxChange(e, data.vc_id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "ID",
      field: "vc_refid",
      key: "vc_refid",
      sortField: "vc_refid",
      // body: (data: any) => <Link to="vehicle-details.html">{data.vc_refid}</Link>,
    },
    {
      header: "Vehicle Name",
      field: "vehicle_name",
      key: "vehicle_name",
      sortField: "vehicle_name",
      body: (data: any) => (
        <h2 className="table-avatar">
          <Link
            to="#"
            className="avatar avatar-sm me-2"
            onClick={() => {
              handleView(data);
            }}
          >
            <img
              className="avatar-img rounded-circle"
              src={data.vehicle_image || noImg}
              alt="Truck"
            />
          </Link>
          <p>
            {data.vehicle_name}
          </p>
        </h2>
      ),
    },
    {
      header: "Year",
      field: "year",
      key: "year",
      sortField: "year",
    },
    {
      header: "Make",
      field: "make",
      key: "make",
      sortField: "make",
    },
    {
      header: "Modal",
      field: "model",
      key: "model",
      sortField: "model",
      body: (data: any) => <span className="text-muted">{data.model}</span>,
    },
    {
      header: "License Plate",
      field: "license_plate",
      key: "license_plate",
      sortField: "license_plate",
    },
    {
      header: "Type",
      field: "vehical_typename",
      key: "vehical_typename",
      sortField: "vehical_typename",
    },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortField: "status",
      body: (data: any) => (
        <div className="toggles">
          <label className="switch">
            <input
              type="checkbox"
              id="togBtn"
              checked={data.status === 1}
              onChange={(event) => handleToggle(event, data.vc_id)}
            />
            <div className="slider round">
              <span className="on">
                <i className="ti ti-check" />
              </span>
              <span className="off">
                <i className="ti ti-x" />
              </span>
            </div>
          </label>
        </div>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (item: any) => {
        return (
          <>
            {" "}
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  to={routes.vehiclesDetails.path}
                  className="dropdown-item"
                  onClick={() => {
                    dispatch(setVehicleEditData(item));
                  }}
                >
                  <i className="feather icon-eye" /> View
                </Link>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => {
                    handleOffcanvas();
                    handleEdit(item);
                  }}
                >
                  <i className="feather icon-edit" /> Edit
                </Link>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => handleDeleteClick(item)}
                >
                  <i className="feather icon-trash-2" /> Delete
                </Link>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.vehicle_paging.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.vehicleCreations);
        setCount(response?.data?.totalCount);
        setTotalRecords(response?.data?.totalCount);
        setCount(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  const [visible, setVisible] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null);

  const deleteVehicle = async (vehicleId: number) => {
    const url = `${end_points.delete_truck.url}?id=${vehicleId}`;

    if (vehicleId) {
      try {
        const response = await deleteData(url);
        if (response?.status === 200) {
          toast.success(response?.data?.response?.responseMessage);
          setVisible(false);
          getList();
        }
      } catch (error) {}
    }
  };

  const accept = () => {
    if (selectedVehicle) {
      deleteVehicle(selectedVehicle.vc_id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (vehicle: any) => {
    setSelectedVehicle(vehicle);
    setVisible(true);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  const handleEdit = (data: any) => {
    dispatch(setEditData(data));
  };
  const handleView = (data: any) => {
    navigate(routes.vehiclesDetails.path);
    dispatch(setEditData(data));
  };

  const [vehiclesCount, setVehiclesCount] = useState<any>({});

  const getCounts = async () => {
    try {
      const result = await getData(
        end_points.vehicle_assign_unassign_count.url
      );
      setVehiclesCount({
        assignedStatusCount: result.data.assignedStatusCount,
        unassignedStatusCount: result.data.unassignedStatusCount,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Vehicles</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Vehicles</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Vehicles */}
          <div className="vehicles-info">
            {/* Vehicles Info */}
            <div className="row">
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card drivers-card primary-border w-100">
                  <div className="card-body">
                    <div className="drivers-card-details">
                      <div className="drivers-text">
                        <p>All Vehicles</p>
                        <h5>{count || 0} </h5>
                      </div>
                      <div className="drivers-icon">
                        <span className="bg-primary-icon">
                          <i className="ti ti-truck" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card drivers-card success-border w-100">
                  <div className="card-body">
                    <div className="drivers-card-details">
                      <div className="drivers-text">
                        <p>Assigned Vehicles</p>
                        <h5>{vehiclesCount.assignedStatusCount || 0}</h5>
                      </div>
                      <div className="drivers-icon">
                        <span className="bg-success-icon">
                          <i className="ti ti-truck-delivery" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card drivers-card danger-border w-100">
                  <div className="card-body">
                    <div className="drivers-card-details">
                      <div className="drivers-text">
                        <p>Unassigned</p>
                        <h5>{vehiclesCount.unassignedStatusCount || 0}</h5>
                      </div>
                      <div className="drivers-icon">
                        <span className="bg-danger-icon">
                          <i className="ti ti-truck-off" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card drivers-card bg-primary w-100">
                  <div className="card-body">
                    <div className="create-driver-info">
                      <Link
                        to="#"
                        onClick={() => {
                          if (planData?.vehicle_companyplan == totalRecords) {
                            toast.warning("Kindly upgrade plan to add more");
                          } else {
                            handleOffcanvas();
                          }
                        }}
                      >
                        <i className="ti ti-circle-plus" /> Add Vehicles
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Vehicles Info */}
            {/* Sub Header */}
            <div className="sub-header">
              <div className="sub-header-content">
                <ul className="nav">
                  <li>
                    <h4>All Vehicles ({count || 0})</h4>
                  </li>
                </ul>
              </div>
            </div>
            {/* /Sub Header */}
            {/* Table */}
            <div className="card table-card">
              <div className="card-body">
                <div className="table-responsive prime-table">
                  <PrimeDataTable
                    setSearchQuery={setSearchQuery}
                    column={columns}
                    data={listData}
                    rows={rows}
                    setRows={setRows}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalRecords={totalRecords}
                  />
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
          {/* /Vehicles */}
        </div>
      </div>
      {/* /Page Wrapper */}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this vehicle?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
        draggable={false}
      />
      {offcanvas && (
        <AddEditVehicles successcall={getList} vehicleType={vehicleType} />
      )}
    </>
  );
};

export default VehiclesList;
