export const invoiceList = {
    status: "success", // Indicate the response status
    message: "Invoices retrieved successfully", // Optional message
    data: [
        {
            "invoice_Id": "#IN001",
            "service_type": "normal",
            "due_date": "31-10-2024",
            "status": "unpaid",
            "from": {
                "role": "Service Manager",
                "mail": "xxx@gmail.com",
                "mobile": "+91 97543 21898"
            },
            "to": {
                "role": "Service Manager",
                "mail": "xxx@gmail.com",
                "mobile": "+91 97543 21898"
            },
            "username": "this.name.first-this.name.last",
            "password": "4EYqBwF4XsdTQC3",
            "emails": [
                "Aubree65@example.com",
                "Irving_Kohler64@gmail.com"
            ],
            "repair-item": {
                "item-1": {
                    "item-name": "Spare Parts - Silencer Parts 22457863",
                    "item-price": 100
                },
                "item-2": {
                    "item-name": "Spare Parts - Silencer Parts 22457863",
                    "item-price": 100
                },
                "item-3": {
                    "item-name": "Spare Parts - Silencer Parts 22457863",
                    "item-price": 100
                }
            },
            "tax": 48,
            "domain": "slight-chemotaxis.org",
            "job": {
                "title": "Dynamic Interactions Manager",
                "descriptor": "Customer",
                "area": "Optimization",
                "type": "Liaison",
                "company": "Harber, Waters and Hoppe"
            },
            "creditCard": {
                "number": "5464-6392-7979-5259",
                "cvv": 416,
                "issuer": "mastercard"
            },
            "uuid": "b6ff08c0-44bf-4d7f-9229-b7339b829d38",
            "objectId": "671f5d4f21822ca87ddf65d3",
            "total": "1000"
        },
        {
            "invoice_Id": "#IN002", // Changed invoice number for uniqueness
            "service_type": "normal",
            "due_date": "31-10-2024",
            "status": "paid",
            "from": {
                "role": "Service Manager",
                "mail": "xxx@gmail.com",
                "mobile": "+91 97543 21898"
            },
            "to": {
                "role": "Service Manager",
                "mail": "xxx@gmail.com",
                "mobile": "+91 97543 21898"
            },
            "username": "this.name.first-this.name.last",
            "password": "Yl0Gb6usj2iltqU",
            "emails": [
                "Arne.Stamm@example.com",
                "Jules12@gmail.com"
            ],
            "repair-item": {
                "item-1": {
                    "item-name": "Spare Parts - Silencer Parts 22457863",
                    "item-price": 100
                },
                "item-2": {
                    "item-name": "Spare Parts - Silencer Parts 22457863",
                    "item-price": 100
                },
                "item-3": {
                    "item-name": "Spare Parts - Silencer Parts 22457863",
                    "item-price": 100
                }
            },
            "tax": 48,
            "domain": "yellow-factory.name",
            "job": {
                "title": "Investor Integration Orchestrator",
                "descriptor": "Customer",
                "area": "Division",
                "type": "Agent",
                "company": "Moen, Franecki and Abbott"
            },
            "creditCard": {
                "number": "3528-1326-9404-1912",
                "cvv": 316,
                "issuer": "maestro"
            },
            "uuid": "cc5e7cd8-2c89-49f8-8497-95a112b4f336",
            "objectId": "671f5d4f21822ca87ddf65d4",
            "total": "1000"
        },
       
    ]
};
