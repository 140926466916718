import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { ApiServiceContext, routes } from "../../../../utils/shared.module";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { end_points } from "../../../../services/end_point/end_points";
import PrimeDataTable from "../../../../components/data-table";
import { toast } from "react-toastify";
import { setEditData } from "../../../../core/redux/commonSlice";

const CourseDashboard = () => {
  const [metricsData, setMetricsData] = useState<any>({});
  const [chartData, setChartData] = useState<any>([]);
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const dispatch = useDispatch();
  const { getData, putData, deleteData } = useContext(ApiServiceContext);
  const [count, setCount] = useState<number>(0);
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  const [chartOptions, setChartOptions] = useState<any>({
    series: [
      { name: "Tasks", data: [] },
    ],
    chart: {
      type: "area",
      height: "auto",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      min: 0,
      max: 20000,
      labels: {
        formatter: function (val: number) {
          return (val / 1000).toFixed(0) + 'K';
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    grid: {
      padding: {
        bottom: 0,
      },
    },
    fill: {
      opacity: 0.8,
      colors: ["#DAEAFF"], // Single color for the area fill
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val + " tasks"; // Tooltip text
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: -10,
    },
  });

  useEffect(() => {
    if (chartData.length > 0) {
      // Extracting data from API response
      const dates = chartData.map((item: any) => item.date);
      const totalData = chartData.map((item: any) => item.todo + item.inprogress + item.completed);
  
      // Update chart options with the fetched data
      setChartOptions((prevOptions: any) => ({
        ...prevOptions,
        series: [{ name: "Tasks", data: totalData }],
        xaxis: { ...prevOptions.xaxis, categories: dates },
      }));
    }
  }, [chartData]);

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(listData.map((item) => item.courseadmin_id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    tutorialId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(tutorialId);
    } else {
      newSelectedRows.delete(tutorialId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const course_id = row;
    try {
      const payload = {
        id: course_id,
        isActive: isChecked,
      };
      let url = end_points.update_status_courseAdmin.url;

      const response = await putData(url, payload);
      if (response.status === 200) {
        getList();
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };

  const handleEditTripClick = (data: any) => {
    dispatch(setEditData(data));
  };

  const deleteCourseAdmin = async (id: number) => {
    const url = `${end_points.delete_courseAdmin.url}?id=${id}`;

    if (id) {
      try {
        const response = await deleteData(url);
        if (response?.status === 200) {
          toast.success(response?.data?.message);
          setVisible(false);
          getList();
        }
      } catch (error) {}
    }
  };

  const accept = () => {
    if (selectedUser) {
      deleteCourseAdmin(selectedUser.courseadmin_id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (data: any) => {
    setSelectedUser(data);
    setVisible(true);
  };

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data.courseadmin_id)}
              onChange={(e) => handleRowCheckboxChange(e, data.courseadmin_id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "ID",
      field: "courseadmin_refid",
      key: "courseadmin_refid",
      sortField: "courseadmin_refid",
    },
    {
      header: "Contact Number",
      field: "contact_personphone",
      key: "contact_personphone",
      sortField: "contact_personphone",
      body: (data: any) => (
        <p>
          {data?.contact_personphone}
        </p>
      ),
    },
    {
      header: "No of Course",
      field: "no_of_course",
      key: "no_of_course",
      sortField: "no_of_course",
      body: (data: any) => (
        <span className="text-muted">{data.no_of_course}</span>
      ),
    },
    {
      header: "No of Quiz",
      field: "no_of_quiz",
      key: "no_of_quiz",
      sortField: "no_of_quiz",
      body: (data: any) => (
        <span className="text-muted">{data.no_of_quiz}</span>
      ),
    },
    {
      header: "Fee (Revenue)",
      field: "subscription_fee",
      key: "subscription_fee",
      sortField: "subscription_fee",
      body: (data: any) => (
        <p>
          {data?.subscription_fee}%
        </p>
      ),
    },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortable:false,
      body: (data: any) => (
        <div className="toggles">
          <label className="switch">
            <input
              type="checkbox"
              id="togBtn"
              checked={data.status === 1}
              onChange={(event) => handleToggle(event, data.courseadmin_id)}
            />
            <div className="slider round">
              <span className="on">
                <i className="ti ti-check" />
              </span>
              <span className="off">
                <i className="ti ti-x" />
              </span>
            </div>
          </label>
        </div>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (data: any) => {
        return (
            <>
              {" "}
              <div className="dropdown">
                <Link
                  to="#"
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <i className="feather icon-more-vertical" />
                </Link>
  
                <div className="dropdown-menu dropdown-menu-end">
                      <Link
                        // onClick={() => {
                        //   handleEditTripClick(data);
                        //   handleOffcanvas();
                        // }}
                        to="#"
                        className="dropdown-item"
                      >
                        <i className="feather icon-edit" /> Edit
                      </Link>
                      <Link
                        to="#"
                        className="dropdown-item"
                        // onClick={() => handleDeleteClick(data)}
                      >
                        <i className="feather icon-trash-2" /> Delete
                      </Link>
                </div>
              </div>
            </>
          );
      },
    },
  ];



  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.get_courseAdmin.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.companies);
        setTotalRecords(response?.data?.totalCount);
        setCount(response?.data?.totalCount)
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Course Admin</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.courseAdminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Dashboard */}
          <div className="dashboard-info">
            <div className="row">
            <div className="col-xl-6 col-lg-12">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
                    <div className="card dashboard-info-card flex-fill">
                      <div className="card-body">
                        <div className="card-header">
                          <h5>This Month Earnings</h5>
                        </div>
                        <div className="sevice-div service-manger-dash">
                          <ul>
                            <li>
                              <div className="sevice-set">
                                <h5 className="sevice-primary-text">$0</h5>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
                    <div className="card dashboard-info-card flex-fill">
                      <div className="card-body">
                        <div className="card-header">
                          <h5>Last Month Earnings</h5>
                        </div>
                        <div className="sevice-div service-manger-dash">
                          <ul>
                            <li>
                              <div className="sevice-set">
                              <h5 className="sevice-primary-text">$0</h5>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
                    <div className="card dashboard-info-card flex-fill">
                      <div className="card-body">
                        <div className="card-header">
                          <h5>Course Admin</h5>
                        </div>
                        <div className="sevice-div service-manger-dash">
                          <ul>
                            <li>
                              <div className="sevice-set">
                                <h5 className="sevice-complete-text">{count || 0}</h5>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 d-flex">
                <div className="card chart-card dashboard-info-card flex-fill">
                  <div className="card-body">
                    <div className="card-header inspection-card-header">
                      <h5>Earning Tracking - Last One Week</h5>
                    </div>
                    <div className="admin-chart-info">
                      <div id="chart">
                        <ReactApexChart
                          style={{ height: 200 }}
                          options={chartOptions}
                          series={chartOptions.series}
                          type="area"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Sub Header */}
            <div className="sub-header">
              <div className="sub-header-content">
                <ul className="nav">
                  <li>
                    <h4>Course Admin ({count || 0})</h4>
                  </li>
                </ul>
              </div>
            </div>
            {/* /Sub Header */}
            {/* Table */}
            <div className="card table-card">
              <div className="card-body">
                <div className="table-responsive prime-table">
                  <PrimeDataTable
                    setSearchQuery={setSearchQuery}
                    column={columns}
                    data={listData}
                    rows={rows}
                    setRows={setRows}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalRecords={totalRecords}
                  />
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
          {/* /Dashboard */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default CourseDashboard;
