const Terms = () => {
  return (
    <div className="page-wrapper custom-wrapper">
      <div className="content container">
        <div className="row">
          <h4 className="mb-4">Terms of Service</h4>
          <h5 className="mb-2">1. Acceptance of Terms</h5>
          <div className="mb-3">
            By accessing or using Core Four Production's SaaS fleet management
            application, CARAi, (the “Service”), you agree to comply with and be
            bound by these Terms of Service (“Terms”). If you do not agree to
            these Terms, you may not use the Service.
          </div>
          <h5 className="mb-2">2. Description of Service</h5>
          <div className="mb-3">
            Core Four Productions, Corp. provides a fleet management application
            that allows users to manage and track their fleet of vehicles. The
            Service includes features such as vehicle tracking, maintenance
            scheduling, and reporting.
          </div>

          <h5 className="mb-2">3. User Accounts</h5>
          <ul className="mb-3 list-style-custom">
            <li>
              <b>Registration:</b> To use certain features of the Service, you
              must register for an account. You agree to provide accurate,
              current, and complete information during the registration process
              and to update such information to keep it accurate, current, and
              complete.
            </li>
            <li>
              <b>Account Security:</b> You are responsible for maintaining the
              confidentiality of your account login information and are fully
              responsible for all activities that occur under your account. You
              agree to immediately notify us of any unauthorized use or
              suspected unauthorized use of your account or any other breach of
              security.
            </li>
          </ul>

          <h5 className="mb-2">4. Use of the Service</h5>
          <ul className="mb-3 list-style-custom">
            <li>
              <b>Permitted Use:</b> You agree to use the Service only for lawful
              purposes and in accordance with these Terms.
            </li>
            <li>
              <b>Prohibited Use:</b> You agree not to use the Service:
            </li>
            <li>
              In any way that violates any applicable federal, state, local, or
              international law or regulation.
            </li>
            <li>
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content or otherwise.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material, including any “junk mail,” “chain letter,”
              “spam,” or any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate Core Four Productions,
              Corp., a Core Four Productions, Corp. employee, another user, or
              any other person or entity.
            </li>
          </ul>

          <h5 className="mb-2">5. Fees and Payment</h5>
          <ul className="mb-3 list-style-custom">
            <li>
              <b>Subscription Fees:</b> Features of the Service may require you
              to pay subscription fees. You agree to pay all applicable fees in
              connection with your use of the Service.
            </li>
            <li>
              <b>Payment Terms:</b> All fees are due in advance and are
              non-refundable. We reserve the right to change our fees at any
              time.
            </li>
          </ul>

          <h5 className="mb-2">6. Intellectual Property</h5>
          <ul className="mb-3 list-style-custom">
            <li>
              <b>Ownership:</b> The Service and its entire contents, features,
              and functionality (including but not limited to all information,
              software, text, displays, images, video, and audio, and the
              design, selection, and arrangement thereof) are owned by Core Four
              Productions, Corp., its licensors, or other providers of such
              material and are protected by United States and international
              copyright, trademark, patent, trade secret, and other intellectual
              property or proprietary rights laws.
            </li>
            <li>
              <b>License:</b> Subject to your compliance with these Terms, we
              grant you a limited, nonexclusive, non-transferable,
              non-sublicensable license to access and use the Service for your
              personal or internal business purposes.
            </li>
          </ul>

          <h5 className="mb-2">7. Termination</h5>
          <div className="mb-3">
            We may terminate or suspend your account and bar access to the
            Service immediately, without prior notice or liability, under our
            sole discretion, for any reason whatsoever and without limitation,
            including but not limited to a breach of these Terms.
          </div>

          <h5 className="mb-2">8. Limitation of Liability</h5>
          <div className="mb-3">
            In no event shall Core Four Productions, Corp., nor its directors,
            employees, partners, agents, suppliers, or affiliates, be liable for
            any indirect, incidental, special, consequential, or punitive
            damages, including without limitation, loss of profits, data, use,
            goodwill, or other intangible losses, resulting from (i) your use or
            inability to use the Service; (ii) any unauthorized access to or use
            of our servers and/or any personal information stored therein; (iii)
            any interruption or cessation of transmission to or from the
            Service; (iv) any bugs, viruses, trojan horses, or the like that may
            be transmitted to or through our Service by any third party; (v) any
            errors or omissions in any content or for any loss or damage
            incurred as a result of the use of any content posted, emailed,
            transmitted, or otherwise made available through the Service; and/or
            (vi) the defamatory, offensive, or illegal conduct of any third
            party
          </div>

          <h5 className="mb-2">9. Governing Law</h5>
          <div className="mb-2">
            These Terms shall be governed and construed in accordance with the
            laws of the United States or European Union, without regard to its
            conflict of law provisions.
          </div>
          <ul className="mb-3 list-style-custom">
            <li>
              <b>For Users in the United States:</b> These terms of service are
              governed by and construed in accordance with the laws of the state
              where our company is headquartered, without regard to its conflict
              of law principles. Any disputes arising under these terms will be
              resolved according to the laws of that state.
            </li>
            <li>
              <b>For Users in the European Union:</b> These terms of service are
              governed by and construed in accordance with the laws of the
              country in which the user resides. Users are protected under their
              local consumer protection laws and regulations. Any disputes
              arising under these terms will be subject to the jurisdiction of
              the courts in the user’s home country.
            </li>
          </ul>

          <h5 className="mb-2">10. Changes to Terms</h5>
          <div className="mb-3">
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material, we will provide
            at least 30 days’ notice prior to any new terms taking effect. What
            constitutes a material change will be determined at our sole
            discretion.
          </div>

          <h5 className="mb-2">11. Contact Us</h5>
          <div className="mb-2">
            If you have any questions about these Terms, please contact us at
            info@core4productions.com.
          </div>
          <ul className="mb-3 list-style-custom">
            <li>Email: info@core4productions.com</li>
            <li>Address: 405 TX-121 Lewisville, TX 75067</li>
          </ul>

          <h6 className="mb-2">11.1 Refund Policy</h6>
          <div className="mb-3">
            All subscription fees are charged in advance and are non-refundable
            except under specific conditions.
          </div>

          <h6 className="mb-2">11.2 Subscription Fees</h6>
          <div className="mb-3">
            All subscription fees are charged in advance and are non-refundable,
            except as provided in this Refund and Cancellation Policy. We do not
            offer refunds for partial months of service or for unused features.
          </div>

          <h6 className="mb-2">11.3 Refund Eligibility</h6>
          <div className="mb-2">
            You may be eligible for a refund under the following circumstances:
          </div>
          <ul className="mb-3 list-style-custom">
            <li>
              <b>Service Issues:</b> If you experience a technical issue that
              prevents you from using the Service and we are unable to resolve
              it within a reasonable time frame.
            </li>
            <li>
              <b>Billing Errors:</b> If you were incorrectly charged due to a
              billing error on our part.
            </li>
          </ul>

          <h6 className="mb-2">11.4 Refund Process</h6>
          <div className="mb-2">
            To request a refund, please follow these steps:
          </div>
          <ul className="mb-3 list-style-custom">
            <li>
              <b>Contact Support:</b> Email our customer support team at
              infor@core4productions.com with your account details and a
              description of the issue.
            </li>
            <li>
              <b>Review:</b> Our team will review your request and may ask for
              additional information to process your refund.
            </li>
            <li>
              <b>Decision:</b> We will notify you of our decision within 30
              business days of receiving your request.
            </li>
          </ul>

          <h6 className="mb-2">11.5 Refund Method</h6>
          <div className="mb-2">
            Approved refunds will be processed to the original payment method
            used for the purchase. Please allow 5-10 business days for the
            refund to appear on your statement.
          </div>

          <h6 className="mb-2">11.6 Cancellation and Reactivation Policy</h6>
          <ul className="mb-3 list-style-custom">
            <li>
              <b>Cancellation by You:</b> You may cancel your subscription at
              any time by logging into your account and following the
              cancellation procedures. Your cancellation will take effect at the
              end of the current billing cycle, and you will not be charged for
              the next billing cycle. No refunds will be provided for the
              current billing cycle, even if you cancel mid-month.
            </li>
            <li>
              <b>Cancellation by Us:</b> We reserve the right to suspend or
              terminate your account and access to the Service at our sole
              discretion, without notice, for conduct that we believe violates
              these Terms or is harmful to other users of the Service, us, or
              third parties, or for any other reason.
            </li>
            <li>
              <b>Reactivation:</b> If you wish to reactivate your account after
              cancellation, you can do so by logging into your account and
              following the reactivation procedures. Reactivation is subject to
              our approval and may require payment of any outstanding fees. Your
              account will be reactivated at the start of the next billing
              cycle.
            </li>
          </ul>

          <h6 className="mb-2">11.7 Data Retention and Deletion</h6>
          <ul className="mb-3 list-style-custom">
            <li>
              <b>Retention Period:</b> We will retain your personal data only
              for as long as is necessary for the purposes set out in this
              policy, and to the extent necessary to comply with our legal
              obligations, resolve disputes, and enforce our policies.
            </li>
            <li>
              <b>Account Data:</b> If you cancel your account, we will retain
              your data for a reasonable period of time to allow for
              reactivation, unless you request deletion of your data.
            </li>
            <li>
              <b>Deletion Requests:</b> You may request the deletion of your
              personal data by contacting us at info@core4productions.com. We
              will process your request in accordance with applicable laws and
              our data retention policies.
            </li>
            <li>
              <b>Permanent Deletion:</b> If you wish to permanently delete your
              account and all associated data, please contact us at
              info@core4productions.com with your
            </li>
          </ul>

          <h6 className="mb-2">
            11.8 Changes to This Refund and Cancellation Policy
          </h6>
          <div className="mb-3">
            We may update our Refund and Cancellation Policy from time to time.
            We will notify you of any changes by posting the new Refund and
            Cancellation Policy on this page. You are advised to review this
            Refund and Cancellation Policy periodically for any changes. Changes
            to this Refund and Cancellation Policy are effective when they are
            posted on this page.
          </div>

          <h6 className="mb-2">11.9 Contact Us</h6>
          <div className="mb-2">
            If you have any questions about this Refund and Cancellation Policy,
            please contact us at:
          </div>
          <ul className="mb-3 list-style-custom">
            <li>Email: info@core4productions.com</li>
            <li>Address: 405 TX-121 Lewisville, TX 75067</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Terms;
