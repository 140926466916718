import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { yup } from "../../../../utils/shared.module";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import {
  DESCRIPTION_LENGTH,
  email,
  emailMaxLength,
  nameMaxLength,
  validImageTypes,
} from "../../../../utils/patterns/regex.pattern";
import { clearEditData } from "../../../../core/redux/commonSlice";
import { Dropdown } from "primereact/dropdown";
import ViewProduct from "./view-product";
import { validatePhoneNumber } from "../../../../utils/constant";
import parsePhoneNumberFromString from "libphonenumber-js";
import PhoneInputWithRef from "../../../../utils/phoneInputWithRef";

const AddProduct = ({
  successcall = () => {},
  categoryList,
  type,
  setType,
}: any) => {
  const { postData, putData, getData } = useContext(ApiServiceContext);
  const userDetails: any = useSelector((state: any) => state.login.userDetails);
  const [selectedImages, setSelectedImages] = useState<any>([]);
  const [ImageUrls, setImageUrls] = useState<any>([]);
  const [error, setError] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [editValue, setEditValue] = useState<any>({});
  const data = useSelector((state: any) => state.common.editData);
  const quiz_id = data?.prod_id;

  const validationSchema = Yup.object().shape({
    title: Yup.string().trim().required("Project Title is required"),
    address: Yup.string().trim().required("Address is required"),
    category: Yup.number().required("Product Category is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Please enter a valid email")
      .trim()
      .matches(email, "Please enter a valid email"),
    contact_number: Yup.string()
      .trim()
      .required("Contact number is required")
      .test("is-valid-phone", "Invalid phone number", (value) =>
        validatePhoneNumber(value)
      ),
    description: Yup.string()
      .trim()
      .max(255, "Maximum 255 characters allowed")
      .required("Quiz description is required"),
    status: yup.boolean().required("Status is required"),
    product_image: Yup.array().required("Image Required"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    if (quiz_id) {
      getEditValue();
    }
  }, [data, setValue, quiz_id, categoryList]);

  const getEditValue = async () => {
    try {
      let url = end_points.get_product.url;
      if (quiz_id) {
        url += `?prod_id=${quiz_id}&courseadmin_id=${userDetails?.courseadmin_id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        const data = response?.data?.data?.product;
        setValue("title", data.prod_title || "");
        setValue("category", data.prod_categoryid || "");
        setValue("email", data.contact_email || "");
        setValue("contact_number", data.contact_number || "");
        setValue("address", data.contact_address || "");
        setValue("description", data.product_desc || "");
        setValue("status", data.status === 1 ? true : false);
        setImageUrls(JSON.parse(data?.product_image));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data: any) => {
    const product_status: any = Number(data.status === true ? 1 : 2);
    const category_id: any = Number(data.category);
    const id: any = Number(userDetails?.courseadmin_id);
    const phoneNumber = parsePhoneNumberFromString(
      data.contact_number,
      selectedCountry.toUpperCase()
    );
    const formattedPhone = phoneNumber
      ? phoneNumber.formatInternational()
      : data.contact_number;
    try {
      const payload: any = {
        prod_title: data.title,
        prod_categoryid: category_id,
        contact_email: data.email,
        contact_number: formattedPhone,
        contact_address: data.address,
        ProductDesc: data.description,
        productImages: data.product_image,
        status: product_status,
        courseadmin_id: id,
      };

      if (quiz_id) {
        payload["productId"] = quiz_id;
        payload["updated_by"] = id;
      } else {
        payload["created_by"] = id;
      }

      let urls = quiz_id
        ? end_points.update_product.url
        : end_points.create_product.url;
      const response = quiz_id
        ? await putData(urls, payload)
        : await postData(urls, payload);

      if (response.status === 200) {
        dispatch(setAddCanvasSidebar(!offcanvas));
        toast.success(response?.data?.response?.responseMessage);
        dispatch(clearEditData());
        successcall();
      }
    } catch (e) {}
  };

  const handleCustomSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const dispatch = useDispatch();

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
    dispatch(clearEditData());
    setType("");
    reset();
  };

  const handleImageChange = async (e: any) => {
    const files = e.target.files;
    const file = e.target.files?.[0];
    const formData = new FormData();
    formData.append("files", file);
    let urls = end_points.upload_product_images.url;
    const response = await postData(urls, formData);
    if (response.status === 200) {
      setImageUrls([...ImageUrls, response?.data?.images]);
    }

    setError("");
    const newImages: any = [];
    for (let i = 0; i < files.length; i++) {
      if (validImageTypes.includes(files[i].type)) {
        const imageObj = {
          id: files[i].name + i,
          url: URL.createObjectURL(files[i]),
          image: file[i],
        };
        newImages.push(imageObj);
      } else {
        setError("Only image files are accepted.");
        break;
      }
    }

    if (newImages.length > 0) {
      setSelectedImages((prevImages: any) => [...prevImages, ...newImages]);
    }
  };

  const handleRemoveImage = (id: any) => {
    setImageUrls(ImageUrls.filter((image: any, index: any) => index !== id));
  };

  useEffect(() => {
    if (ImageUrls?.length > 0) {
      setValue("product_image", ImageUrls);
      trigger("product_image");
    }
  }, [ImageUrls, setValue, trigger]);

  const getViewValue = async () => {
    try {
      let url = end_points.get_product.url;
      if (quiz_id) {
        url += `?prod_id=${quiz_id}&courseadmin_id=${userDetails?.courseadmin_id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setEditValue(response?.data?.data?.product);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    quiz_id && userDetails?.courseadmin_id && getViewValue();
  }, [quiz_id, userDetails?.courseadmin_id]);

  return (
    <>
      {type === "View" ? (
        <ViewProduct
          editValue={editValue}
          handleOffcanvas={handleOffcanvas}
          setType={setType}
          getList={successcall}
        />
      ) : (
        <div
          className={`truck-list-sidebar ${
            offcanvas ? "truck-list-sidebar-open" : ""
          }`}
        >
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {quiz_id ? "Edit" : "Add"} Product{" "}
              </h4>
              <Link
                onClick={handleOffcanvas}
                to="#"
                className="clear-truck-list"
              >
                <i className="fas fa-xmark" />
              </Link>
            </div>
            <div className="card-body">
              <div className="truck-information mb-0">
                <h4 className="mb-1">Upload Product</h4>
                <p className="text-muted">
                  Please below details to create new product
                </p>
                <div className="truck-information-grid mb-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label>
                          Product Title <span className="manitary">*</span>
                        </label>
                        <Controller
                          name="title"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Name"
                              {...field}
                              onBlur={() => trigger("title")}
                              maxLength={nameMaxLength}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("title");
                              }}
                            />
                          )}
                        />
                        {errors.title && (
                          <small className="text-danger">
                            {errors.title.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label>
                          Product Category <span className="manitary">*</span>
                        </label>
                        <Controller
                          name="category"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              filter
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              options={categoryList}
                              placeholder="Choose"
                              className="w-100"
                            />
                          )}
                        />
                        {errors.category && (
                          <small className="text-danger">
                            {errors.category.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="mb-3">Contact Details</h4>
                <div className="truck-information-grid mb-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>
                          Email <span className="manitary">*</span>
                        </label>
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Name"
                              {...field}
                              onBlur={() => trigger("email")}
                              maxLength={emailMaxLength}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("email");
                              }}
                            />
                          )}
                        />
                        {errors.email && (
                          <small className="text-danger">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label>
                          Contact Number <span className="manitary">*</span>
                        </label>
                        <Controller
                          defaultValue=""
                          name="contact_number"
                          control={control}
                          render={({ field }) => (
                            <PhoneInputWithRef
                              country={"us"}
                              placeholder="Enter Contact Number"
                              {...field}
                              onChange={(value: string, country: any) => {
                                field.onChange(value);
                                setSelectedCountry(country.countryCode);
                                trigger("contact_number");
                              }}
                              onBlur={() => trigger("contact_number")}
                            />
                          )}
                        />
                        {errors.contact_number && (
                          <small className="text-danger">
                            {errors.contact_number.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label>
                          Address <span className="manitary">*</span>
                        </label>
                        <Controller
                          name="address"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Name"
                              {...field}
                              onBlur={() => trigger("address")}
                              maxLength={nameMaxLength}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("address");
                              }}
                            />
                          )}
                        />
                        {errors.address && (
                          <small className="text-danger">
                            {errors.address.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="mb-3">Product Info</h4>
                <div className="truck-information-grid mb-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-2">
                        <label>
                          Product Description{" "}
                          <span className="manitary">*</span>
                        </label>
                        <Controller
                          name="description"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <textarea
                              maxLength={DESCRIPTION_LENGTH}
                              className="form-control"
                              placeholder="Enter Description"
                              {...field}
                              onBlur={() => trigger("description")}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("description");
                              }}
                            />
                          )}
                        />
                        {errors.description && (
                          <small className="text-danger">
                            {errors.description.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <p className="text-muted">
                          <i className="ti ti-info-circle me-2" />
                          Maximum 255 Characters
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-wrap mb-0">
                        <div className="upload-info">
                          <label className="d-block">
                            Product Image <span className="text-danger">*</span>
                          </label>
                          <label className="choose-file w-auto">
                            <input
                              type="file"
                              accept="image/jpeg, image/png, image/svg+xml, image/jpg"
                              multiple
                              onChange={(e) => {
                                handleImageChange(e);
                              }}
                            />
                            <span>
                              <i className="feather icon-upload" /> Choose to
                              File Upload
                            </span>
                          </label>
                          <p>
                            <i className="feather icon-alert-circle" />
                            Maximum File size 100MB &amp; JPEG, PNG, GIF, Webp
                          </p>
                          {errors.product_image && (
                            <small className="text-danger">
                              {errors.product_image.message}
                            </small>
                          )}
                          <div className="d-flex align-item-center mt-4">
                            {ImageUrls?.length > 0 &&
                              ImageUrls?.map((image: any, index: any) => (
                                <div className="me-3">
                                  <span>
                                    <img
                                      src={`https://api.thecarai.ai${image}`}
                                      key={index}
                                      alt=""
                                      style={{
                                        width: "180px",
                                        height: "120px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </span>
                                  <Link
                                    to="#"
                                    className="text-danger text-decoration-underline d-block"
                                    onClick={() => handleRemoveImage(index)}
                                  >
                                    Remove
                                  </Link>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 d-flex align-items-center justify-content-between mb-3">
                  <h6>Status</h6>
                  <div className="toggles">
                    <Controller
                      name="status"
                      control={control}
                      defaultValue={true}
                      render={({ field }) => (
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="togBtn"
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                          <div className="slider round">
                            <span className="on">
                              <i className="ti ti-check"></i>
                            </span>
                            <span className="off">
                              <i className="ti ti-x"></i>
                            </span>
                          </div>
                        </label>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer canvas-btn tutorial-footer">
              <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
                Cancel
              </Link>
              <Link to="#" className="btn btn-add" onClick={handleCustomSubmit}>
                {quiz_id ? "Update" : "Add"}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddProduct;
