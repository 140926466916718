import React from 'react';

const CourseAdminTerms = () => {
  return (
    <>
      <div className="terms-and-condition-content">
        <h5 className="terms-of-service">Welcome to CARAi</h5>
        <p className="terms-of-service-content">
          Welcome to the CARAi app! We’re excited to offer you a convenient platform designed
          specifically for owner-operator drivers. The Marketplace provides easy access to a variety of
          products, services, and courses that help you manage your everyday needs efficiently, allowing
          you to focus more on the road.
        </p>
        <h5 className="terms-of-service">Acceptance of Terms</h5>
        <p className="terms-of-service-content">
          By accessing or using Core Four Production's SaaS Marketplace, CARAi, (the “Service”), you agree
          to comply with and be bound by these Terms of Service (“Terms”). If you do not agree to these
          Terms, you may not use the Service.
        </p>

        <h5 className="terms-of-service">Description of Service</h5>
        <p className="terms-of-service-content">Core Four Productions, Corp. provides a Marketplace that allows easy access to a variety of products, services, and courses that help you manage your everyday needs efficiently, allowing you to focus more on the road.</p>

        <h5 className="terms-of-service">User Accounts</h5>
        <ul className="terms-of-service-list">
          <li><strong>Registration:</strong> To use certain features of the Service, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</li>
          <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account login information and are fully responsible for all activities that occur under your account. You agree to immediately notify us of any unauthorized use or suspected unauthorized use of your account or any other breach of security.</li>
        </ul>

        <h5 className="terms-of-service">Use of the Service</h5>
        <ul className="terms-of-service-list">
          <li><strong>Permitted Use:</strong> You agree to use the Service only for lawful purposes and in accordance with these Terms.</li>
          <li><strong>Prohibited Use:</strong> You agree not to use the Service:
            <ul className="terms-of-service-list">
              <li>In any way that violates any applicable federal, state, local, or international law or regulation.</li>
              <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
              <li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation.</li>
              <li>To impersonate or attempt to impersonate Core Four Productions, Corp., a Core Four Productions, Corp. employee, another user, or any other person or entity.</li>
            </ul>
          </li>
        </ul>

        <h5 className="terms-of-service">Fees and Payment</h5>
        <ul className="terms-of-service-list">
          <li><strong>Subscription Fees:</strong> Features of the Service may require you to pay subscription fees. You agree to pay all applicable fees in connection with your use of the Service.</li>
          <li><strong>Payment Terms:</strong> All fees are due in advance and are non-refundable. We reserve the right to change our fees at any time.</li>
        </ul>

        <h5 className="terms-of-service">Intellectual Property</h5>
        <ul className="terms-of-service-list">
          <li><strong>Ownership:</strong> The Service and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Core Four Productions, Corp., its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</li>
          <li><strong>License:</strong> Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable license to access and use the Service for your personal or internal business purposes.</li>
        </ul>

        <h5 className="terms-of-service">Termination</h5>
        <p className="terms-of-service-content">We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of these Terms.</p>

        <h5 className="terms-of-service">Limitation of Liability</h5>
        <p className="terms-of-service-content">In no event shall Core Four Productions, Corp., nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Service by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or (vi) the defamatory, offensive, or illegal conduct of any third party.</p>

        <h5 className="terms-of-service">Governing Law</h5>
        <p className="terms-of-service-content">These Terms shall be governed and construed in accordance with the laws of the United States or European Union, without regard to its conflict of law provisions.</p>
        <ul className="terms-of-service-list">
          <li>For Users in the United States: These terms of service are governed by and construed in accordance with the laws of the state where our company is headquartered, without regard to its conflict of law principles. Any disputes arising under these terms will be resolved according to the laws of that state.</li>
          <li>For Users in the European Union: These terms of service are governed by and construed in accordance with the laws of the country in which the user resides. Users are protected under their local consumer protection laws and regulations. Any disputes arising under these terms will be subject to the jurisdiction of the courts in the user’s home country.</li>
        </ul>

        <h5 className="terms-of-service">Changes to Terms</h5>
        <p className="terms-of-service-content">We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

        <h5 className="terms-of-service">Contact Us</h5>
        <p className="terms-of-service-content">If you have any questions about these Terms, please contact us at:</p>
        <ul className="terms-of-service-list">
          <li>Email: <a href="mailto:info@core4productions.com">info@core4productions.com</a></li>
          <li>Address: 405 TX-121 Lewisville, TX 75067</li>
          <li>Phone: <a href="tel:+1234567890">+1 (234) 567-890</a></li>
        </ul>

        <h5 className="terms-of-service">Refund Policy</h5>
        <p className="terms-of-service-content">At Core Four Productions, Corp., we strive to ensure our customers are satisfied with our Service. This Refund and Cancellation Policy outlines the conditions under which you may request a refund or cancel your subscription to our SaaS fleet management application.</p>

        <h5 className="terms-of-service">Subscription Fees</h5>
        <p className="terms-of-service-content">All subscription fees are charged in advance and are non-refundable, except as provided in this Refund and Cancellation Policy. We do not offer refunds for partial months of service or for unused features.</p>

        <h5 className="terms-of-service">Refund Eligibility</h5>
        <ul className="terms-of-service-list">
          <li><strong>Service Issues:</strong> If you experience a technical issue that prevents you from using the Service, and we are unable to resolve it within a reasonable time frame.</li>
          <li><strong>Billing Errors:</strong> If you were incorrectly charged due to a billing error on our part.</li>
        </ul>

        <h5 className="terms-of-service">Refund Process</h5>
        <ol className="terms-of-service-list">
          <li><strong>Contact Support:</strong> Email our customer support team at <a href="mailto:support@core4productions.com">support@core4productions.com</a> with your account details and a description of the issue.</li>
          <li><strong>Review:</strong> Our team will review your request and may ask for additional information to process your refund.</li>
          <li><strong>Decision:</strong> We will notify you of our decision within 30 business days of receiving your request.</li>
        </ol>

        <h5 className="terms-of-service">Refund Method</h5>
        <p className="terms-of-service-content">Approved refunds will be processed to the original payment method used for the purchase. Please allow 5-10 business days for the refund to appear on your statement.</p>

        <h5 className="terms-of-service">Cancellation and Reactivation Policy</h5>
        <ul className="terms-of-service-list">
          <li><strong>Cancellation by You:</strong> You may cancel your subscription at any time by logging into your account and following the cancellation procedures. Your cancellation will take effect at the end of the current billing cycle, and you will not be charged for the next billing cycle. No refunds will be provided for prior payments.</li>
          <li><strong>Reactivation:</strong> If you cancel your subscription and later wish to reactivate it, you will be subject to the current pricing and terms available at the time of reactivation.</li>
        </ul>

      </div>
    </>

  );
}

export default CourseAdminTerms;
