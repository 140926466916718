import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { coverImg, profile } from "../../../utils/imagepath";
import { routes } from "../../../utils/routes";
import PhoneInputWithRef from "../../../utils/phoneInputWithRef";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import store from "../../../core/redux/store";
import { toast } from "react-toastify";
import { setUserDetails } from "../../../core/redux/loginSlice";
import { end_points } from "../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../utils/shared.module";
import {
  email,
  passwordMaxLength,
  passwordMinLength,
  passwordPattern,
} from "../../../utils/patterns/regex.pattern";

import defaultimage from "../../../assets/img/profiles/no-img.png";
import { validatePhoneNumber } from "../../../utils/constant";
import { SpinnerContext } from "../../../components/spinner/spinner";
interface UserDetails {
  name?: string;
}

const Profile = () => {
  const userData = useSelector((state: any) => state.login.userDetails);
  const token = store?.getState()?.login?.token;
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [preview, setPreview] = useState<string | null>(null);
  const [file, setFile] = useState<string | null>(null);
  const [subscriptionFee, setSubscriptionFee] = useState<any>(
    userData.subscription_fee
  );
  const [courseFee, setCourseFee] = useState<any>(userData.course_fee);
  const findPath = window.location.pathname;
  const isAdmin = findPath.split("/")[1];
  const dispatch = useDispatch();
  const { postData, putData } = useContext(ApiServiceContext);

  useEffect(() => {
    // Update subscriptionFee when userData or courseFee changes
    setSubscriptionFee(userData.buyer_fee + courseFee);
  }, [userData.buyer_fee, courseFee]);
  useEffect(() => {
    // Update subscriptionFee when userData or courseFee changes
    setSubscriptionFee(userData.buyer_fee + courseFee);
  }, [userData.buyer_fee, courseFee]);

  console.log(userData);
  console.log(userData);
  const formik = useFormik({
    initialValues: {
      name: userData.name || userData.contact_personname || "",
      email: userData.email || userData.contact_personemail || "",
      phone: userData.phone_number || userData.contact_personphone || "",
      profile_image: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(40, "Name must be 40 characters or less")
        .required("Name is required"),
      email: Yup.string()
        .email("Please enter a valid email")
        .trim()
        .required("Email is required")
        .matches(email, "Please enter a valid email"),
      phone: Yup.string()
        .trim()
        .required("Contact number is required")
        .test("is-valid-phone", "Invalid phone number", (value) =>
          validatePhoneNumber(value)
        ),
      profile_image: Yup.mixed()
        .nullable()
        .test("fileType", "Only image files are allowed", (value) => {
          if (!value) return true; // Allow null or undefined
          const file = value as File; // Explicitly cast to File
          return ["image/jpeg", "image/png", "image/gif"].includes(file.type);
        }),
    }),

    onSubmit: async (values) => {
      console.log("Submit function triggered", values);
      showLoader();

      try {
        const formDataToSend = new FormData();

        const profile_Image: string | null = null; 
        // Check the user role to determine form fields and API endpoint
        const {
          name = "",
          email = "",
          phone = "",
          profile_image: profileImage="",
        } = values;

        // Check the user role to determine form fields and API endpoint
        if (isAdmin === "super-admin") {
          const id = userData?.user_id;
          console.log("Super admin " + id);

          // Always append all values for super-admin
          formDataToSend.append("name", name);
          formDataToSend.append("email", email);
          formDataToSend.append("phone", phone);
          formDataToSend.append("id", id);
        

          console.log("Super admin " + id);

          // Always append all values for super-admin
          formDataToSend.append("name", name);
          formDataToSend.append("email", email);
          formDataToSend.append("phone", phone);
          formDataToSend.append("id", id);
        } else if (isAdmin === "course-admin") {
          const id = userData?.user_id;

          // Only append fields if they differ for course-admin
          formDataToSend.append("contact_personname", name);
          formDataToSend.append("contact_personemail", email);
          formDataToSend.append("contact_personphone", phone);
          formDataToSend.append("courseadmin_id", id);
          formDataToSend.append("course_fee", courseFee.toString());
          formDataToSend.append("subscription_fee", subscriptionFee.toString());
          formDataToSend.append("buyer_fee", userData.buyer_fee);
        } else if(userData.roleid===1){
          const id = userData?.user_id;
          formDataToSend.append("roleid", userData.roleid)
          formDataToSend.append("name", name);
          formDataToSend.append("email", email);
          formDataToSend.append("phone", phone);
          formDataToSend.append("id", id);
        }else {
          const id = userData?.user_id;
          formDataToSend.append("roleid", userData.roleid)
          formDataToSend.append("firstname", name);
          formDataToSend.append("email", email);
          formDataToSend.append("phone", phone);
          formDataToSend.append("user_id", id);
        }

        if(profileImage){
          formDataToSend.append("profile_image", profileImage)
        }

       let urls;
    
          if (isAdmin === "super-admin") {
            urls = end_points.update_superAdmin.url; // super-admin
          } else if (isAdmin === "course-admin") {
            urls =  end_points.update_courseAdmin.url; // course-admin
          } else {
            if(userData.roleid===1){
              urls =  end_points.update_adminprofile.url;
            } else if(userData.roleid===3){
              urls =  end_points.update_fleetmanager.url;
            }else if(userData.roleid===4){
              urls =  end_points.update_service.url;
            }
          }

        const result = await putData(urls, formDataToSend);

        if (result?.status == 200) {
          hideLoader();
          toast.success(result?.data?.response?.responseMessage);

          const resp = result?.data?.data;
          if (isAdmin === "super-admin") {
            dispatch(
              setUserDetails({
                id: resp.id,
                name: resp.name,
                email: resp.email,
                phone_number: resp.phone_number,
                profile_url: resp.profile_url,
              })
            );
          } else if (isAdmin === "course-admin") {
            dispatch(
              setUserDetails({
                courseadmin_id: resp.courseadmin_id,
                contact_personname: resp.contact_personname,
                contact_personemail: resp.contact_personemail,
                contact_personphone: resp.contact_personphone,
                profile_url: resp.profile_url,
              })
            )
          }else{
            dispatch(
              setUserDetails({
                id: resp.id,
                name: resp.name,
                email: resp.email,
                phone_number: resp.phone_number,
                profile_url: resp.profile_url,
              })
            );
          }
        } else {
          hideLoader();
          toast.error("Failed to update user details.");
        }
      } catch (err) {
        hideLoader();
        console.error("Error during submit:", err);
      }
    },
  });
  console.log(userData)
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    formik.setFieldValue("profile_image", file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
    
  };

  const passwordFormik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Current password is required"),
      new_password: Yup.string()
        .required("New password is required")
        .matches(
          passwordPattern,
          "Password must be at least 5 to 10 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character"
        ),
      confirm_password: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("new_password")], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      console.log("Formik values on submit:", values); // Debugging line
      try {
        let url;
        if (isAdmin === "super-admin") {
          url = end_points.super_admin_reset.url; // super-admin
        } else if (isAdmin === "course-admin") {
          url = end_points.course_reset.url; // course-admin
        } else {
          url = end_points.admin_reset.url; // admin
        }

        const passwordData = {
          email: userData?.email || userData?.contact_personemail,
          old_password: values.old_password,
          new_password: values.new_password,
          confirm_password: values.confirm_password,
        };

        // Check the password data before making the API request
        console.log("Password data to send:", passwordData);

        const response = await postData(url, passwordData);

        console.log("Response:", response); // Debugging line

        if (response?.status === 200) {
          toast.success(response?.data?.message || "Password reset successfully", {
            position: "top-right", // Customize the position if needed
            autoClose: 5000, // Duration the toast is visible (in ms)
            onClose: () => passwordFormik.resetForm(), // Reset form after toast closes
          });
        }
      } catch (error) {
        console.error(error);
        toast.error("Password update failed. Please try again.");
      }
    },
  });

  const [passwordVisibility, setPasswordVisibility] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const togglePasswordVisibility = (type: "current" | "new" | "confirm") => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };
  const userDetails: UserDetails = store?.getState()?.login?.userDetails || {};

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Profile</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.dashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Profile</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="profile-cover-img">
                <img src={coverImg} alt="" />
              </div>
              <div className="profile-imgdiv">
                <div className="profile-img">
                  <img
                    src={userData.profile_url || defaultimage}
                    alt="Profile"
                  />
                </div>
                <div className="profile-img-content">
                  <div className="profile-img-content-left">
                    <h6>
                      Hello {userData.name || userData?.contact_personname}!
                    </h6>
                    <ul>
                      <li>
                        <i className="ti ti-mail me-2" />
                        {userData?.email || userData?.contact_personemail}
                      </li>
                      <li>
                        <i className="ti ti-phone me-2" />
                        {userData?.phone_number ||
                          userData?.contact_personphone}
                      </li>
                    </ul>
                    {isAdmin === "course-admin" && (
                      <div className="profile-m-place-fees-grid">
                        <h5 className="fees-grid-subhdr">Marketplace Fees</h5>
                        <div className="market-fees-bg">
                          <div className="market-fees-row">
                            <div className="fees-inp-lbl">
                              Your Marketplace Fees{" "}
                              <span className="mandatory ms-2 text-danger">
                                *
                              </span>
                              <span className="mx-4">=</span>
                            </div>
                            <div className="fees-inp-grid">
                              <label>Course Fee</label>
                              <div className="fees-inp">
                                ${" "}
                                <input
                                  type="text"
                                  value={courseFee}
                                  maxLength={6}
                                  className="form-control"
                                  onChange={(e) =>
                                    setCourseFee(Number(e.target.value))
                                  } // Convert string to number
                                />
                              </div>
                            </div>
                            <div className="fees-inp-grid">
                              <label>Buyer Fee</label>
                              <div className="fees-inp fees-inp-val">
                                $ <span>{userData.buyer_fee}</span>
                              </div>
                            </div>
                            <div className="fees-inp-grid text-muted fees-inp-griddivider">
                              =
                            </div>
                            <div className="fees-inp-grid fees-inp-grid-success">
                              <label>Total Fee</label>
                              <div className="fees-inp fees-inp-val">
                                $ <span>{subscriptionFee}</span>
                              </div>
                            </div>
                          </div>
                          <div className="market-fees-row">
                            <span className="text-muted">
                              Recommended Fees $10 - $500
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="profile-img-content-right"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="profile-head">
                    <h5>Profile Details</h5>
                  </div>
                  <div className="profile-content">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        console.log("Form submit triggered");
                        formik.handleSubmit(e);
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <div className="mb-3">
                            <label>
                              Name <span className="manitary">*</span>
                            </label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Full Name"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <small className="text-danger">
                                {String(formik.errors.phone)}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="mb-3">
                            <label>
                              Email Address <span className="manitary">*</span>
                            </label>
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="Email"
                              value={formik.values.email}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "email",
                                  e.target.value.toLowerCase()
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <small className="text-danger">
                                {String(formik.errors.email)}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label>
                              Phone Number <span className="manitary">*</span>
                            </label>
                            <PhoneInputWithRef
                              value={formik.values.phone}
                              onChange={(value) =>
                                formik.setFieldValue("phone", value)
                              }
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                              <small className="text-danger">
                                {String(formik.errors.phone)}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="mb-2">
                              Profile Image <span className="manitary">*</span>
                            </label>
                            <div className="file-upload">
                              <input
                                type="file"
                                accept="image/*"
                                name="profile_image"
                                className="form-control"
                                onChange={handleFileChange}
                              />

                              {/* Show preview if available */}
                              {preview ? (
                                <img
                                  src={preview}
                                  alt="Profile Preview"
                                  style={{ width: "100px", marginTop: "10px" }}
                                />
                              ) : userData?.profile_url ? (
                                /* Show user's existing profile image if no preview is available */
                                <img
                                  src={userData?.profile_url}
                                  alt="Profile Preview"
                                  style={{ width: "100px", marginTop: "10px" }}
                                />
                              ) : (
                                /* Show default image if neither preview nor user's profile image is available */
                                <img
                                  src={defaultimage}
                                  alt="No Image Available"
                                  style={{ width: "100px", marginTop: "10px" }}
                                />
                              )}

                              <span>
                                <i className="ti ti-upload me-2"></i>Choose to
                                File Upload
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="canvas-btn mt-4">
                        <button type="submit" className="btn btn-add btn-hvr">
                          Update
                        </button>
                        <Link
                          to="#"
                          className="btn btn-light ms-2"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            formik.resetForm(); // Reset the form to its initial values
                          }}
                        >
                          Cancel
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="profile-head">
                    <h5>Change Password</h5>
                  </div>
                  <div className="profile-content">
                    <form onSubmit={passwordFormik.handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12 col-12">
                          <div className="mb-3">
                            <label>
                              Current Password{" "}
                              <span className="manitary">*</span>
                            </label>
                            <div className="pass-group">
                              <input
                                type={
                                  passwordVisibility.current
                                    ? "text"
                                    : "password"
                                }
                                name="old_password"
                                className="form-control"
                                placeholder="Current Password"
                                value={passwordFormik.values.old_password}
                                onChange={passwordFormik.handleChange}
                                onBlur={passwordFormik.handleBlur}
                                maxLength={passwordMaxLength}
                              />
                              <span
                                className={`ti toggle-password ${passwordVisibility.current
                                    ? "ti-eye"
                                    : "ti-eye-off"
                                  }`}
                                onClick={() =>
                                  togglePasswordVisibility("current")
                                }
                              />
                            </div>
                            {passwordFormik.touched.old_password &&
                              passwordFormik.errors.old_password ? (
                              <p className="text-danger mt-1">
                                {passwordFormik.errors.old_password}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12 col-12">
                          <div className="mb-3">
                            <label>
                              New Password <span className="manitary">*</span>
                            </label>
                            <div className="pass-group">
                              <input
                                type={
                                  passwordVisibility.new ? "text" : "password"
                                }
                                name="new_password"
                                className="form-control"
                                placeholder="New Password"
                                value={passwordFormik.values.new_password}
                                onChange={passwordFormik.handleChange}
                                onBlur={passwordFormik.handleBlur}
                                maxLength={passwordMaxLength}
                              />
                              <span
                                className={`ti toggle-passwords ${passwordVisibility.new
                                    ? "ti-eye"
                                    : "ti-eye-off"
                                  }`}
                                onClick={() => togglePasswordVisibility("new")}
                              />
                            </div>
                            {passwordFormik.touched.new_password &&
                              passwordFormik.errors.new_password ? (
                              <p className="text-danger mt-1">
                                {passwordFormik.errors.new_password}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12 col-12">
                          <div className="mb-3">
                            <label>
                              Confirm Password{" "}
                              <span className="manitary">*</span>
                            </label>
                            <div className="pass-group">
                              <input
                                type={
                                  passwordVisibility.confirm
                                    ? "text"
                                    : "password"
                                }
                                name="confirm_password"
                                className="form-control"
                                placeholder="Confirm Password"
                                value={passwordFormik.values.confirm_password}
                                onChange={passwordFormik.handleChange}
                                onBlur={passwordFormik.handleBlur}
                                maxLength={passwordMaxLength}
                              />
                              <span
                                className={`ti toggle-password-new ${passwordVisibility.confirm
                                    ? "ti-eye"
                                    : "ti-eye-off"
                                  }`}
                                onClick={() =>
                                  togglePasswordVisibility("confirm")
                                }
                              />
                            </div>
                            {passwordFormik.touched.confirm_password &&
                              passwordFormik.errors.confirm_password ? (
                              <p className="text-danger mt-1">
                                {passwordFormik.errors.confirm_password}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="btn-block">
                        <button type="submit" className="btn btn-add btn-hvr">
                          Change Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;


