import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ConfirmDialog } from "primereact/confirmdialog";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { end_points } from "../../../../services/end_point/end_points";
import { routes } from "../../../../utils/routes";
import { ApiServiceContext } from "../../../../utils/shared.module";
import EditVehicleDetails from "./edit-vehicle-details";

const VehicleDetails = () => {
  const { getData, deleteData } = useContext(ApiServiceContext);

  const viewData = useSelector((state: any) => state.common.vehicleEditData);
  const companyData = useSelector((state: any) => state.login.companyDetails);

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const dispatch = useDispatch();

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };
  const [vehicleType, setVehicleType] = useState<any>([]);

  const getVehicleTypeId = async () => {
    let url = end_points.get_vehicletype.url;
    const response = await getData(url);
    const vehicleTypes = response?.data?.data?.vehicle_type?.map(
      (vehicle: any) => {
        return { name: vehicle?.type_name, code: vehicle?.id };
      }
    );
    setVehicleType(vehicleTypes);
  };

  useEffect(() => {
    getVehicleTypeId();
  }, []);
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null);
  const [visible, setVisible] = useState(false);

  const handleDeleteClick = (vehicle: any) => {
    setSelectedVehicle(vehicle);
    // dispatch(clearVehicleEditData());
    setVisible(true);
  };
  const navigate = useNavigate();

  const deleteVehicle = async (vehicleId: number) => {
    const url = `${end_points.delete_truck.url}?id=${vehicleId}`;
    if (vehicleId) {
      try {
        const response = await deleteData(url);
        if (response?.status === 200) {
          toast.success(response?.data?.response?.responseMessage);
          setVisible(false);
          navigate(routes.vehiclesList.path);
        }
      } catch (error) {}
    }
  };

  const accept = () => {
    if (selectedVehicle) {
      deleteVehicle(selectedVehicle.vc_id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">
                  Vehicle ID - {viewData?.vc_refid}
                </h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.vehiclesList.path}>Vehicle List</Link>
                  </li>
                  <li className="breadcrumb-item">VE001</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="vehicles-info">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="vehicle-grid">
                      <div className="vehicle-grid-img">
                        <img
                          src={viewData?.vehicle_image}
                          className="img-fluid"
                          alt="Truck"
                        />
                      </div>
                      <div className="vehicle-grid-content">
                        <div className="vehicle-content-top">
                          <div className="vehicle-id-info">
                            <ul className="nav vehicle-id-list">
                              <li>
                                <h4>{viewData?.license_plate}</h4>
                              </li>
                              <li>
                                <span className="vehicle-badge bg-active">
                                  {viewData?.status === 1
                                    ? "Active"
                                    : "Inactive"}
                                </span>
                              </li>
                            </ul>
                            <ul className="nav vehicle-id-edit">
                              <li>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    handleOffcanvas();
                                  }}
                                >
                                  <i className="feather icon-edit"></i> Edit
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  className="vehicle-delete"
                                  onClick={() => handleDeleteClick(viewData)}
                                >
                                  <i className="feather icon-trash-2"></i>{" "}
                                  Delete
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="vehicle-make-list">
                            <ul className="nav">
                              <li>{viewData?.vehicle_name}</li>
                              <li>{viewData?.vehical_typename}</li>
                              <li>{viewData?.driving_license || "NA"}</li>
                            </ul>
                          </div>
                        </div>
                        <div className="vehicle-content-bottom">
                          <ul className="nav">
                            <li>
                              <p>Type</p>
                              <h6>{viewData?.vehical_typename}</h6>
                            </li>
                            <li>
                              <p>Year</p>
                              <h6>{viewData?.year}</h6>
                            </li>
                            <li>
                              <p>Licence Plate</p>
                              <h6>{viewData?.license_plate}</h6>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-8 col-lg-12">
                <div className="vehicle-overview-header">
                  <h4>Overview</h4>
                </div>
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="vehicle-inner-header">
                      <h4>{viewData?.license_plate}</h4>
                      <p>{viewData?.vehicle_description}</p>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="vehicle-overview-grid">
                          <div className="vehicle-overview-icon">
                            <span>
                              <i className="ti ti-car"></i>
                            </span>
                          </div>
                          <div className="vehicle-overview-text">
                            <p>Vehicle Model</p>
                            <h6>{viewData?.model}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="vehicle-overview-grid">
                          <div className="vehicle-overview-icon">
                            <span>
                              <i className="ti ti-steering-wheel"></i>
                            </span>
                          </div>
                          <div className="vehicle-overview-text">
                            <p>Vehicle Type</p>
                            <h6>{viewData?.vehical_typename}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="vehicle-overview-grid">
                          <div className="vehicle-overview-icon">
                            <span>
                              <i className="ti ti-forms"></i>
                            </span>
                          </div>
                          <div className="vehicle-overview-text">
                            <p>VIN</p>
                            <h6>{viewData?.vin_number}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="vehicle-overview-grid">
                          <div className="vehicle-overview-icon">
                            <span>
                              <i className="ti ti-meteor"></i>
                            </span>
                          </div>
                          <div className="vehicle-overview-text">
                            <p>Odometer</p>
                            <h6>{viewData?.odoMeter || "0/km"}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="vehicle-overview-grid">
                          <div className="vehicle-overview-icon">
                            <span>
                              <i className="ti ti-calendar-up"></i>
                            </span>
                          </div>
                          <div className="vehicle-overview-text">
                            <p>Vehicle Update Date</p>
                            <h6>{viewData?.updatedAt || "NA"}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="vehicle-overview-grid">
                          <div className="vehicle-overview-icon">
                            <span>
                              <i className="ti ti-calendar-up"></i>
                            </span>
                          </div>
                          <div className="vehicle-overview-text">
                            <p>Vehicle Condition</p>
                            <h6>{viewData?.condition || "NA"}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-12">
                <div className="vehicle-overview-header">
                  <h4>Company Details</h4>
                </div>
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="vehicle-company-details">
                      <div className="vehicle-company-icon">
                        <img
                          src={companyData.company_image_url}
                          alt="Empty Logo"
                        />
                      </div>
                      <div className="vehicle-company-text">
                        <ul className="nav">
                          <li>
                            <h4>{companyData.company_name}</h4>
                          </li>
                          <li>
                            <span className="vehicle-badge bg-active">
                              Active
                            </span>
                          </li>
                        </ul>
                        <h5>
                          Cameron Jacob <span>(Owner)</span>
                        </h5>
                      </div>
                    </div>
                    <div className="vehicle-company-list">
                      <ul className="nav">
                        <li>
                          <p>Contact Person Name</p>
                          <h6>{companyData.contact_person_name}</h6>
                        </li>
                        <li>
                          <p>Status</p>
                          <h6>{companyData.status === 1 ? "Active" : "In active"}</h6>
                        </li>
                        <li>
                          <p>Contact Number</p>
                          <h6>{companyData.contact_person_phone}</h6>
                        </li>
                        <li>
                          <p>Contact Email</p>
                          <h6>{companyData.contact_person_email}</h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      {offcanvas && <EditVehicleDetails vehicleType={vehicleType} />}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this vehicle?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
        draggable={false}
      />
    </>
  );
};

export default VehicleDetails;
