import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { avatar02 } from "../../../../utils/imagepath";
import { ApiServiceContext } from "../../../../utils/shared.module";
import PrimeDataTable from "../../../../components/data-table";
import { end_points } from "../../../../services/end_point/end_points";
import { toast } from "react-toastify";
import { subscribersListData } from "../../../../components/json/subscriber-list";
import ViewSubscriber from "./view-subscriber";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { setEditData } from "../../../../core/redux/commonSlice";

const SubscribersList = () => {
  const { getData } = useContext(ApiServiceContext);
  const userDetails = useSelector((state: any) => state.login.userDetails);
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<any>();
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [count, setCount] = useState<number>(0);

  const columns = [
    {
      header: "ID",
      field: "sub_Id",
      key: "sub_Id",
      sortField: "sub_Id",
    },
    {
      header: "Subscriber",
      field: "subscriber",
      key: "subscriber",
      sortField: "subscriber",
      body: (col: any) => (
        <>
          <h2 className="table-avatar">
            <Link to="#" className="avatar avatar-sm me-2">
              <img
                className="avatar-img rounded-circle"
                src={`https://api.thecarai.ai${col?.profile_image}`}
                alt="Profile"
              />
            </Link>
            <Link to="#">
              {col?.firstName} {col?.lastName}
            </Link>
          </h2>
        </>
      ),
    },
    {
      header: "Email",
      field: "email",
      key: "email",
      sortField: "email",
    },
    {
      header: "Contact Number",
      field: "phone",
      key: "phone",
      sortField: "phone",
    },
    {
      header: "Date",
      field: "createddate",
      key: "createddate",
      sortField: "createddate",
      body: (data: any) => (
        <span className="text-muted">{data.createddate}</span>
      ),
    },
    {
      header: "Subscription (US $)",
      field: "fees",
      key: "fees",
      sortField: "fees",
    },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortField: "status",
      body: (data: any) => (
        <span>{data.status === 1 ? "Active" : "Inactive"}</span>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (data: any) => {
        return (
          <>
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => {
                    handleOffcanvas();
                    dispatch(setEditData(data));
                  }}
                >
                  <i className="feather icon-eye" /> View
                </Link>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.subscriber_list.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}&courseadminid=${userDetails?.courseadmin_id}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.subscribers);
        setCount(response?.data?.totalCount);
        setTotalRecords(response?.data?.totalCount);
        setCount(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);
  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Subscribers List</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to="index.html">Home</Link>
                  </li>
                  <li className="breadcrumb-item">Subscribers List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Product */}
          <div className="dashboard-info">
            {/* Sub Header */}
            <div className="sub-header">
              <div className="sub-header-content">
                <ul className="nav">
                  <li>
                    <h4>Subscribers List ({count})</h4>
                  </li>
                </ul>
              </div>
            </div>
            {/* /Sub Header */}
            {/* Table */}
            <div className="card table-card">
              <div className="card-body">
                <div className="table-responsive">
                  <PrimeDataTable
                    column={columns}
                    setSearchQuery={setSearchQuery}
                    data={listData}
                    rows={rows}
                    setRows={setRows}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalRecords={totalRecords}
                  />
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
          {/* /Product */}
        </div>
      </div>
      {/* /Page Wrapper */}
      <ViewSubscriber />
    </>
  );
};

export default SubscribersList;
