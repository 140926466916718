import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { yup } from "../../../../utils/shared.module";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import {
  DESCRIPTION_LENGTH,
  nameMaxLength,
} from "../../../../utils/patterns/regex.pattern";
import { clearEditData } from "../../../../core/redux/commonSlice";
import { Dropdown } from "primereact/dropdown";

const AddQuiz = ({ successcall = () => {}, courseList }: any) => {
  const { postData, putData, getData } = useContext(ApiServiceContext);
  const userDetails = useSelector((state: any) => state.login.userDetails);
  const [questionId, setQuestionId] = useState<number>();
  const [editQuestions, setEditQuestions] = useState<any>([]);
  const [editCourseList, setEditCourseList] = useState<any>([]);
  const [isErrorShow, setIsErrorShow] = useState<any>(false);
  const [isErrorShow1, setIsErrorShow1] = useState<any>(false);

  const questionRef = useRef();
  const answerRef = useRef();
  const data = useSelector((state: any) => state.common.editData);
  const quiz_id = data?.quiz_id;
  const validationSchema = Yup.object().shape({
    course: Yup.number().required("Course is required"),
    title: Yup.string().trim().required("Quiz title is required"),
    description: Yup.string()
      .trim()
      .max(255, "Maximum 255 characters allowed")
      .required("Quiz description is required"),
    status: yup.boolean().required("Status is required"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (quiz_id) {
      getEditValue();
    }
  }, [quiz_id]);

  const getEditValue = async () => {
    try {
      let url = end_points.get_quiz.url;
      if (quiz_id) {
        url += `?quiz_id=${quiz_id}&courseadmin_id=${userDetails?.courseadmin_id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        const data = response?.data?.data?.quiz;
        setValue("title", data.quiz_title || "");
        setValue("description", data.quiz_desc || "");
        setValue("status", data.status);
        setValue("course", data?.quiz_categoryid);
        setValue("status", data.status === 1 ? true : false);
        setEditQuestions(JSON.parse(data?.quiz_questions));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data: any) => {
    if (
      (questionRef?.current === undefined || questionRef?.current === null) &&
      (answerRef?.current === undefined || answerRef?.current === null)
    ) {
      try {
        const quiz_status: any = Number(data.status === true ? 1 : 2);
        const id: any = Number(userDetails?.courseadmin_id);
        const quiz_categoryid: any = Number(data.course);
        const course_id: any = quiz_id
          ? editCourseList?.find((item: any) => item?.value === quiz_categoryid)
              ?.course_id
          : courseList?.find((item: any) => item?.value === quiz_categoryid)
              ?.course_id;
        const payload: any = {
          courseadmin_id: id,
          course_id: course_id,
          quiz_categoryid: quiz_categoryid,
          quiz_title: data.title,
          quiz_desc: data.description,
          quiz_questions: questions?.map((item: any) => ({
            question: item?.questionText,
            answers: item?.answers?.map((ans: any) => ans?.text),
            correctAnswer: item?.answers?.find((crct: any) => crct?.selected)
              ?.text,
          })),
          status: quiz_status,
        };

        if (quiz_id) {
          payload["quiz_id"] = quiz_id;
          payload["updated_by"] = id;
        } else {
          payload["created_by"] = id;
        }

        let urls = quiz_id
          ? end_points.edit_quiz.url
          : end_points.create_quiz.url;
        dispatch(setAddCanvasSidebar(!offcanvas));
        const response = quiz_id
          ? await putData(urls, payload)
          : await postData(urls, payload);

        if (response.status === 200) {
          toast.success(response?.data?.response?.responseMessage);
          dispatch(clearEditData());
          successcall();
        }
      } catch (e) {}
    }
  };

  const handleCustomSubmit = () => {
    setIsErrorShow(true);
    setIsErrorShow1(true);
    handleSubmit(onSubmit)();
  };

  const dispatch = useDispatch();

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
    dispatch(clearEditData());
    reset();
  };

  const [questions, setQuestions] = useState([
    {
      id: 1,
      questionText: "",
      answers: [],
      newOption: "",
      newAnswer: false,
    },
  ]);
  const addQuestion = () => {
    setQuestions((prevQuestions) => {
      if (prevQuestions.length >= 4) {
        // If questions already reached the limit, return the same array
        return prevQuestions;
      }
  
      // Otherwise, add a new question
      return [
        ...prevQuestions,
        {
          id: prevQuestions.length + 1,
          questionText: "",
          answers: [],
          newOption: "",
          newAnswer: false,
        },
      ];
    });
  };

  const handleQuestionTextChange = (questionId: number, text: string) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.id === questionId
          ? { ...question, questionText: text }
          : question
      )
    );
    setIsErrorShow(true);
  };

  const handleNewOptionChange = (
    questionId: number,
    value: any,
    type: string
  ) => {
    type === "text"
      ? setQuestions((prevQuestions) =>
          prevQuestions.map((question) =>
            question.id === questionId
              ? { ...question, newOption: value }
              : question
          )
        )
      : setQuestions((prevQuestions) =>
          prevQuestions.map((question) =>
            question.id === questionId
              ? { ...question, newAnswer: value }
              : { ...question, newAnswer: false }
          )
        );
    setQuestionId(questionId);
    setIsErrorShow1(true);
  };
  const handleNewOptionChange1 = (
    questionId: number,
    answerId: any,
    value: any
  ) => {
    setQuestions((prevQuestions: any) =>
      prevQuestions.map((question: any) => {
        if (question.id === questionId) {
          return {
            ...question,
            answers: question.answers?.map((ans: any) =>
              ans?.id === answerId
                ? { ...ans, selected: value }
                : { ...ans, selected: false }
            ),
            newOption: "",
            newAnswer: false,
          };
        }
        return question;
      })
    );
    setQuestionId(questionId);
  };

  const handleKeyDown = () => {
    if (questionId) {
      setQuestions((prevQuestions: any) =>
        prevQuestions.map((question: any) => {
          if (question.id === questionId && question.newOption.trim() !== "") {
            return {
              ...question,
              answers: [
                ...question.answers,
                {
                  id: question.answers.length + 1,
                  text: question.newOption,
                  selected: question.newAnswer,
                },
              ],
              newOption: "",
              newAnswer: false,
            };
          }
          return question;
        })
      );
    }
  };

  const handleEditOptions = (value: any, ansID: any, queID: any) => {
    setQuestions((prevQuestions: any) =>
      prevQuestions.map((question: any) => {
        if (question.id === queID) {
          return {
            ...question,
            answers: question.answers?.map((ans: any) =>
              ans?.id === ansID
                ? { ...ans, selected: value }
                : { ...ans, selected: false }
            ),
            newOption: "",
            newAnswer: false,
          };
        }
        return question;
      })
    );
  };

  const isDisableCheckbox = (id: any) => {
    const isCheck = questions
      ?.find((item: any) => item.id === id)
      ?.answers.some((item: any) => item.selected);
    return isCheck;
  };
  useEffect(() => {
    if (editQuestions?.length > 0) {
      const updatedQuestion = editQuestions?.map((item: any, index: any) => {
        return {
          id: index + 1,
          questionText: item?.Question,
          answers: item.Answers.map((answer: any, aIndex: any) => ({
            text: answer,
            id: aIndex + 1,
            selected: answer === item.CorrectAnswer,
          })),
          newOption: "",
          newAnswer: false,
        };
      });
      setQuestions(updatedQuestion);
    }
  }, [editQuestions]);

  const getCourseList = async () => {
    try {
      let url = end_points.get_quiz_for_edit.url;
      url += `?quiz_id=${quiz_id}&courseAdminId=${userDetails?.courseadmin_id}`;
      const response = await getData(url);
      if (response.status === 200) {
        const data = response?.data?.data?.quiz?.data?.quiz?.map(
          (item: any) => {
            return {
              label: item?.course_name,
              value: item?.quiz_typeid,
              course_id: item?.course_id,
            };
          }
        );
        setEditCourseList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    quiz_id && getCourseList();
  }, [quiz_id]);

  return (
    <>
      <div
        className={`truck-list-sidebar ${
          offcanvas ? "truck-list-sidebar-open" : ""
        }`}
      >
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">{quiz_id ? "Edit" : "Add"} Quiz </h4>
            <Link onClick={handleOffcanvas} to="#" className="clear-truck-list">
              <i className="fas fa-xmark" />
            </Link>
          </div>
          <div className="card-body">
            <div className="truck-information mb-0">
              <h4 className="mb-1">Create New Quiz</h4>
              <p className="text-muted">
                Please below details to create new quiz
              </p>
              <div className="truck-information-grid mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label>Choose Course</label>
                      <Controller
                        name="course"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            filter
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={quiz_id ? editCourseList : courseList}
                            placeholder="Select Course"
                            className="w-100"
                          />
                        )}
                      />
                      {errors.course && (
                        <small className="text-danger">
                          {errors.course.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="truck-information-grid mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label>
                        Quiz Title <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="title"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            {...field}
                            onBlur={() => trigger("title")}
                            maxLength={nameMaxLength}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("title");
                            }}
                          />
                        )}
                      />
                      {errors.title && (
                        <small className="text-danger">
                          {errors.title.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-2">
                      <label>
                        Quiz Description <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <textarea
                            maxLength={DESCRIPTION_LENGTH}
                            className="form-control"
                            placeholder="Enter Description"
                            {...field}
                            onBlur={() => trigger("description")}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("description");
                            }}
                          />
                        )}
                      />
                      {errors.description && (
                        <small className="text-danger">
                          {errors.description.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <p className="text-muted">
                        <i className="ti ti-info-circle me-2" />
                        Maximum 255 Characters
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {questions.map((question, qIndex) => (
                <div className="truck-information-grid mb-3" key={question.id}>
                  <div className="row pb-3">
                    <div className="form-wrap">
                      <label>
                        Question {qIndex + 1}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Question"
                        value={question.questionText}
                        onChange={(e) =>
                          handleQuestionTextChange(question.id, e.target.value)
                        }
                      />
                      {question.questionText === "" && isErrorShow && (
                        <small className="text-danger" ref={questionRef}>
                          Question is required
                        </small>
                      )}
                    </div>
                    <div className="created-qts-answer">
                      <h6>Answer</h6>
                      <div className="row">
                        {question.answers.map((answer: any) => (
                          <div key={answer.id} className="col-md-6">
                            <div className="quizAnswer-list-content">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`answerID${qIndex}`}
                                defaultChecked={answer.selected}
                                // {...(quiz_id
                                //   ? { defaultChecked: answer.selected }
                                //   : { checked: answer.selected })}
                                onChange={async (e) => {
                                  quiz_id
                                    ? handleEditOptions(
                                        e.target.checked,
                                        answer?.id,
                                        question.id
                                      )
                                    : handleNewOptionChange1(
                                        question.id,
                                        answer?.id,
                                        e.target.checked
                                      );
                                  setIsErrorShow1(false);
                                }}
                              />
                              <div className="anwser-input">
                                <input
                                  className="form-control anwser-input-control"
                                  type="text"
                                  value={answer.text}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                        {/* New Option Input */}
                        <div className="col-md-12">
                          <div className="newQuizAnswer-list-content">
                            <input
                              className="form-check-input"
                              type="radio"
                              checked={question.newAnswer}
                              onChange={(e) =>
                                handleNewOptionChange(
                                  question.id,
                                  e.target.checked,
                                  "radio"
                                )
                              }
                              // disabled={isDisableCheckbox(question.id)}
                            />
                            <div className="anwser-input">
                              <input
                                className="form-control anwser-input-control"
                                type="text"
                                placeholder="Type an Option"
                                value={question.newOption}
                                onChange={(e) =>
                                  handleNewOptionChange(
                                    question.id,
                                    e.target.value,
                                    "text"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {!isDisableCheckbox(question.id) && isErrorShow1 && (
                          <small className="text-danger" ref={answerRef}>
                            Please choose the anyone correct answer
                          </small>
                        )}
                        <div className="col-md-12">
                          <Link
                            to="#"
                            className="add-options"
                            onClick={handleKeyDown}
                          >
                            + Add Options
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <Link to="#" className="add-questions" onClick={addQuestion}>
                + Add Question
              </Link>
            </div>
            <div className="col-md-12 d-flex align-items-center justify-content-between mb-3 mt-3">
              <h6>Status</h6>
              <div className="toggles">
                <Controller
                  name="status"
                  control={control}
                  defaultValue={true}
                  render={({ field }) => (
                    <label className="switch">
                      <input
                        type="checkbox"
                        id="togBtn"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                      <div className="slider round">
                        <span className="on">
                          <i className="ti ti-check"></i>
                        </span>
                        <span className="off">
                          <i className="ti ti-x"></i>
                        </span>
                      </div>
                    </label>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="card-footer canvas-btn tutorial-footer">
            <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
              Cancel
            </Link>
            <Link to="#" className="btn btn-add" onClick={handleCustomSubmit}>
              {quiz_id ? "Update" : "Add"}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddQuiz;
