import { useState, useEffect, useContext } from "react";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { useDispatch, useSelector } from "react-redux";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext, routes } from "../../../../utils/shared.module";
import PrimeDataTable from "../../../../components/data-table";
import { toast } from "react-toastify";
import { setEditData } from "../../../../core/redux/commonSlice";
import { ConfirmDialog } from "primereact/confirmdialog";
import { setSelectedData } from "../../../../core/redux/commonSlice";
import AssignNewVehicle from "./assign-vehicle";
import AddService from "./add-service";
import { Link } from "react-router-dom";
import { noImg } from "../../../../utils/imagepath";
import { formatDate } from "../../../../utils/patterns/regex.pattern";
import { capitalizeFirstLetter } from "../../../../utils/constant";
import { Tooltip } from "antd";

const TripList: React.FC<any> = (props: any) => {
  const { deleteData, getData } = useContext(ApiServiceContext);
  const [selectedRows, setSelectedRows] = useState<any>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [visible, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isServiceModal, setIsServiceModal] = useState(false);
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openServiceModal = () => setIsServiceModal(true);
  const closeServiceModal = () => setIsServiceModal(false);
  const [managerListData, setManagerListData] = useState<any[]>([]);
  const dispatch = useDispatch();

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(
        props.listData.map((item: any) => item.trip_id)
      );
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    tripId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(tripId);
    } else {
      newSelectedRows.delete(tripId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleEditTripClick = (data: any) => {
    dispatch(setEditData(data));
  };

  const deletePlan = async (planId: number) => {
    const url = `${end_points.delete_trip.url}?id=${planId}`;

    if (planId) {
      try {
        const response = await deleteData(url);
        if (response?.status === 200) {
          toast.success(response?.data?.response?.responseMessage);
          setVisible(false);
          props.successcall();
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An unexpected error occurred");
      }
    }
  };

  const getManagerList = async () => {
    try {
      let url = end_points.get_allservicemanager.url;

      const response = await getData(url);
      if (response.status === 200) {
        const serviceManagerList = response?.data?.data?.user?.map(
          (manager: any) => {
            return { name: manager?.first_name, code: manager?.user_id };
          }
        );
        setManagerListData(serviceManagerList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const accept = () => {
    if (selectedUser) {
      deletePlan(selectedUser.trip_id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (data: any) => {
    setSelectedUser(data);
    setVisible(true);
  };

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data.trip_id)}
              onChange={(e) => handleRowCheckboxChange(e, data.trip_id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "ID",
      field: "trip_refid",
      key: "trip_refid",
      sortField: "trip_refid",
    },
    {
      header: "Trip Name",
      field: "trip_name",
      key: "trip_name",
      sortField: "trip_name",
      body: (data: any) => (
        <div className="trip-data">
          {data.trip_name}
          {(data.vehicle_status === "major" ||
            data.trip_status === "Breakdown") && (
            <div className="tooltips primary-tooltip">
              <i className="ti ti-info-circle ms-1 text-danger" />
              <span className="tooltiptexts triptooltip">
                Issue has occurred for assigned truck. Please add another
                vehicle
              </span>
            </div>
          )}
          <p>{data.trip_description}</p>
        </div>
      ),
    },
    {
      header: "Trip Date",
      field: "trip_duration",
      key: "trip_duration",
      sortField: "trip_duration",
      body: (data: any) => (
        <div className="trip-data-date">
          <p>
            <i className="ti ti-calendar-up"></i> {formatDate(data.trip_start)}
          </p>
          <p>
            <i className="ti ti-calendar-down"></i> {formatDate(data.trip_end)}
          </p>
        </div>
      ),
    },
    {
      header: "Vehicle",
      field: "vehicle_name",
      key: "vehicle_name",
      sortField: "vehicle_name",
      body: (data: any) => (
        <h2 className="table-avatar">
          <Link to="#" className="avatar avatar-sm me-2">
            <img
              className="avatar-img rounded-circle"
              src={data.vehicle.vehicle_image || noImg}
              alt="Profiles"
            />
          </Link>
          <Link to="#">{data.vehicle.vehical_number}</Link>
        </h2>
      ),
    },
    {
      header: "Assigned to",
      field: "assignto",
      key: "assignto",
      sortField: "assignto",
      body: (data: any) => (
        <h2 className="table-avatar">
          <Link to="#" className="unlink-item avatar avatar-sm me-2">
            <img
              className="avatar-img rounded-circle"
              src={data.assignimage || noImg}
              alt="Profiles"
            />
          </Link>
          <Link to="#" className="unlink-item">{data.assignto}</Link>
        </h2>
      ),
    },
    {
      header: "Inspection Status",
      field: "inspection_status",
      key: "inspection_status",
      sortField: "inspection_status",
      body: (data: any) => (
        <span
          className={`badge ${
            data.trip_inspectionstatus === "To Do"
              ? "status-todo"
              : data.trip_inspectionstatus === "Completed"
              ? "status-completed"
              : data.trip_inspectionstatus === "In Progress"
              ? "status-inprogress"
              : ""
          }`}
        >
          {data.trip_inspectionstatus}
        </span>
      ),
    },
    {
      header: "Trip Status",
      field: "trip_status",
      key: "trip_status",
      sortField: "trip_status",
      body: (data: any) => (
        <span
          className={`badge ${
            data.trip_status === "Initiated"
              ? "status-initiated"
              : data.trip_status === "Completed"
              ? "status-completed"
              : data.trip_status === "In Progress"
              ? "status-inprogress"
              : data.trip_status === "Breakdown"
              ? "status-danger"
              : ""
          }`}
        >
          {capitalizeFirstLetter(data?.trip_status)}
        </span>
      ),
    },

    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (data: any) => {
        return (
          <>
            {" "}
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>

              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  to={routes.tripDetails.path}
                  onClick={() => dispatch(setSelectedData(data))}
                  className="dropdown-item"
                >
                  <i className="feather icon-eye" /> View
                </Link>
                {data.vehicle_status === "major" && (
                  <>
                    {/* <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => {
                        dispatch(setSelectedData(data));
                        openModal();
                      }}
                    >
                      <i className="ti ti-car" /> Assign New Vehicle
                    </Link> */}
                    {/* <Link
                      to="#"
                      className="dropdown-item"
                      onClick={openServiceModal}
                    >
                      <i className="ti ti-tool" /> Add to Service
                    </Link> */}
                  </>
                )}
                {data.trip_inspectionstatus === "To Do" && (
                  <>
                    <Link
                      onClick={() => {
                        handleEditTripClick(data);
                        handleOffcanvas();
                      }}
                      to="#"
                      className="dropdown-item"
                    >
                      <i className="feather icon-edit" /> Edit
                    </Link>
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => handleDeleteClick(data)}
                    >
                      <i className="feather icon-trash-2" /> Delete
                    </Link>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  useEffect(() => {
    getManagerList();
  }, []);

  return (
    <>
      <div className="table-responsive prime-table">
        <PrimeDataTable
          column={columns}
          setSearchQuery={props.searchQuery}
          data={props.listData}
          rows={props.rows}
          setRows={props.setRows}
          currentPage={props.currentPage}
          setCurrentPage={props.setCurrentPage}
          totalRecords={props.totalRecords}
        />
      </div>
      {isModalOpen && (
        <AssignNewVehicle
          vehiclesList={props.availableVehiclesList}
          driversList={props.availableDriversList}
          successcall={props.successcall}
          closeModal={closeModal}
        />
      )}
      {isServiceModal && (
        <AddService
          closeServiceModal={closeServiceModal}
          successcall={props.successcall}
          managerListData={managerListData}
        />
      )}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this trip?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
    </>
  );
};
export default TripList;
