import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { noImg } from "../../../../utils/imagepath";
import { routes } from "../../../../utils/routes";
import AddEditServiceManagerList from "./add-edit-service-manager";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { end_points } from "../../../../services/end_point/end_points";
import { toast } from "react-toastify";
import { ApiServiceContext } from "../../../../utils/shared.module";
import PrimeDataTable from "../../../../components/data-table";
import { ConfirmDialog } from "primereact/confirmdialog";
import { setEditData } from "../../../../core/redux/commonSlice";

const ServiceMangerList = () => {
  const { putData, getData, deleteData } = useContext(ApiServiceContext);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [count, setCount] = useState<number>(0);
  const planData = useSelector((state: any) => state.login.planDetails);
  
  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(listData.map((item) => item.user_id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };
  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    serviceManagerId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(serviceManagerId);
    } else {
      newSelectedRows.delete(serviceManagerId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const user_id = row;
    try {
      const payload = {
        id: user_id,
        isActive: isChecked,
      };
      let url = end_points.update_service_status.url;

      const response = await putData(url, payload);
      if (response.status === 200) {
        getList();
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data.user_id)}
              onChange={(e) => handleRowCheckboxChange(e, data.user_id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "ID",
      field: "user_refid",
      key: "user_refid",
      sortField: "user_refid",
    },
    {
      header: "Name",
      field: "firstname",
      key: "firstname",
      sortField: "firstname",
      body: (data: any) => (
        <h2 className="table-avatar">
          <a href="javascript:void(0);" className="avatar avatar-sm me-2">
            <img
              className="avatar-img rounded-circle"
              src={data?.profile_Image || noImg}
              alt="Truck"
            />
          </a>
          <p>{data.firstname}</p>
        </h2>
      ),
    },
    {
      header: "Email",
      field: "email",
      key: "email",
      sortField: "email",
    },
    {
      header: "Contact Number",
      field: "phone",
      key: "phone",
      sortField: "phone",
      body: (data: any) => <span>{data.phone}</span>,
    },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortField: "status",
      body: (data: any) => (
        <div className="toggles">
          <label className="switch">
            <input
              type="checkbox"
              id="togBtn"
              checked={data.status === 1}
              onChange={(event) => handleToggle(event, data.user_id)}
            />
            <div className="slider round">
              <span className="on">
                <i className="ti ti-check" />
              </span>
              <span className="off">
                <i className="ti ti-x" />
              </span>
            </div>
          </label>
        </div>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (item: any) => {
        return (
          <>
            {" "}
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => {
                    handleOffcanvas();
                    handleEdit(item);
                  }}
                >
                  <i className="feather icon-edit" /> Edit
                </Link>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => handleDeleteClick(item)}
                >
                  <i className="feather icon-trash-2" /> Delete
                </Link>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.get_userservicemanager.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.users);
        setCount(response?.data?.totalCount);
        setTotalRecords(response?.data?.totalCount);
        setCount(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  const [visible, setVisible] = useState(false);
  const [selectedServiceManager, setSelectedVehicle] = useState<any>(null);

  const deleteServiceManager = async (serviceManagerId: number) => {
    const url = `${end_points.delete_service.url}?id=${serviceManagerId}`;

    if (serviceManagerId) {
      try {
        const response = await deleteData(url);
        if (response?.status === 200) {
          toast.success(response?.data?.response?.responseMessage);
          setVisible(false);
          getList();
        }
      } catch (error) {}
    }
  };

  const accept = () => {
    if (selectedServiceManager) {
      deleteServiceManager(selectedServiceManager.user_id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (serviceManager: any) => {
    setSelectedVehicle(serviceManager);
    setVisible(true);
  };

  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  const handleEdit = (data: any) => {
    dispatch(setEditData(data));
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Service Manager List</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Service Manager List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Fleet Manager */}
          <div className="dashboard-info">
            {/* Sub Header */}
            <div className="sub-header">
              <div className="sub-header-content">
                <ul className="nav">
                  <li>
                    <h4>Service Manager ({count || 0})</h4>
                  </li>
                </ul>
              </div>
              <div className="sub-header-list">
                <ul className="nav">
                  <li>
                    <Link
                      to="#"
                      className="btn btn-add btn-hvr truck-item"
                      onClick={() => {
                        if (planData?.servicemanager_companyplan == totalRecords) {
                          toast.warning("Kindly upgrade plan to add more");
                        } else {
                          handleOffcanvas();
                        }
                      }}
                    >
                      <i className="ti ti-circle-plus" /> Add Service Manager
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* /Sub Header */}
            {/* Table */}
            <div className="card table-card">
              <div className="card-body">
                <div className="table-responsive prime-table">
                  <PrimeDataTable
                    setSearchQuery={setSearchQuery}
                    column={columns}
                    data={listData}
                    rows={rows}
                    setRows={setRows}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalRecords={totalRecords}
                  />
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
          {/* /Fleet Manager */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {offcanvas && <AddEditServiceManagerList successcall={getList} />}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this service manager?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
        draggable={false}
      />
    </>
  );
};

export default ServiceMangerList;
