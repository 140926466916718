import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import CourseTable from "./course-table";
import { routes } from "../../../../utils/routes";
import ProductTable from "./product-table";
import { useSelector } from "react-redux";

const CourseDashboard = () => {
  const userDetails = useSelector((state: any) => state.login.userDetails);
  const [listCount, setListCount] = useState(0);
  const [chartData, setChartData] = useState<any[]>([
    { date: "09-01-2024", earnings: 5000 },
    { date: "09-02-2024", earnings: 7000 },
    { date: "09-03-2024", earnings: 8000 },
    { date: "09-04-2024", earnings: 6000 },
    { date: "09-05-2024", earnings: 9000 },
    { date: "09-06-2024", earnings: 10000 },
    { date: "09-07-2024", earnings: 11000 },
  ]);

  const [chartOptions, setChartOptions] = useState<any>({
    series: [{ name: "Earnings", data: [] }],
    chart: {
      type: "area",
      height: "auto",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      min: 0,
      max: 20000,
      labels: {
        formatter: function (val: number) {
          return (val / 1000).toFixed(0) + "K";
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    grid: {
      padding: {
        bottom: 0,
      },
    },
    fill: {
      opacity: 0.8,
      colors: ["#DAEAFF"],
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return `$${val.toFixed(2)} USD`;
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: -10,
    },
  });

  useEffect(() => {
    if (chartData.length > 0) {
      const dates = chartData.map((item: any) => item.date);
      const earningsData = chartData.map((item: any) => item.earnings);

      setChartOptions((prevOptions: any) => ({
        ...prevOptions,
        series: [{ name: "Earnings", data: earningsData }],
        xaxis: { ...prevOptions.xaxis, categories: dates },
      }));
    }
  }, [chartData]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Dashboard</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.courseAdminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Dashboard */}
          <div className="dashboard-info">
            <div className="row">
              <div className="col-xl-4 col-lg-12">
                <div className="row dashboard-info-ca h-100 ">
                  <div className="col-xl-12 col-lg-12 col-md-12 d-flex">
                    <div className="card card-active dashboard-info-card flex-fill">
                      <div className="card-body">
                        <div className="card-header">
                          <h5>This Month Earnings</h5>
                        </div>
                        <div className="service-div service-manger-dash">
                          <h6>$0</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 d-flex">
                    <div className="card dashboard-info-card flex-fill">
                      <div className="card-body">
                        <div className="card-header">
                          <h5>
                            Total{" "}
                            {userDetails?.cour_type === "Course"
                              ? "Course"
                              : "Product"}
                          </h5>
                        </div>
                        <div className="service-div service-manger-dash">
                          <h6>{listCount}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 d-flex">
                    <div className="card dashboard-info-card flex-fill">
                      <div className="card-body">
                        <div className="card-header">
                          <h5>Total Subscribers</h5>
                        </div>
                        <div className="service-div service-manger-dash">
                          <h6>{0}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 d-flex">
                <div className="card chart-card dashboard-info-card flex-fill">
                  <div className="card-body">
                    <div className="card-header inspection-card-header">
                      <h5>Earning Tracking - Last One Week</h5>
                    </div>
                    <div className="admin-chart-info">
                      <div id="chart">
                        <ReactApexChart
                          height="300"
                          options={chartOptions}
                          series={chartOptions.series}
                          type="area"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {userDetails?.cour_type === "Course" ? (
              <CourseTable setListCount={setListCount} />
            ) : (
              <ProductTable setListCount={setListCount} />
            )}
            {/* /Table */}
          </div>
          {/* /Dashboard */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default CourseDashboard;
