import React, { useState } from "react";
import "./Tabs.css";
import PricingPlan from "./pricing-plan";
import OwnerOperator from "./owner-operator";

const PlanTabs = () => {
  const [activeTab, setActiveTab] = useState("company");

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  return (
    <div className="page-wrapper">
      <div className="tabs-container">
        <div className="tabs">
          <button
            className={`tab ${activeTab === "company" ? "active" : ""}`}
            onClick={() => handleTabClick("company")}
          >
            Company Plan
          </button>
          <button
            className={`tab ${activeTab === "owner" ? "active" : ""}`}
            onClick={() => handleTabClick("owner")}
          >
            Owner Operator Plan
          </button>
        </div>
        {activeTab === "company" && <PricingPlan />}
        {activeTab === "owner" && <OwnerOperator />}
      </div>
    </div>
  );
};

export default PlanTabs;
