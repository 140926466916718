import React, { useContext, useState } from "react";
import { ApiServiceContext, routes } from "../../../utils/shared.module";
import { end_points } from "../../../services/end_point/end_points";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const FleetHowToUse = () => {
    const { putData } = useContext(ApiServiceContext);
    const userDetails = useSelector((state: any) => state.login.userDetails);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            const payload = {
                fleet_id: userDetails?.user_id,
                role_id: userDetails?.roleid,
                term_condition: true
            };
            const response = await putData(
                end_points?.update_fleet_termcondition?.url,
                payload
            );

            if (response) {
                navigate(routes.fleetManagerDashboard.path);
            }
        } catch (error) {
            console.error(error);
        }
    };



    return (
        <div>
            <div className="forgot-page-wrapper">
                <div className="forgot-page-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="static-page">
                                    <div className="row">
                                        <div className="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3">
                                            <div className="forgot-pwd-topper">
                                                <div className="forgot-pwd text-center">
                                                    <div className="forgot-password-inner">
                                                        <div className="forgot-password-title">


                                                            <h5 className="terms-of-service">How To Use</h5>

                                                            <div className="terms-and-condition-content">
                                                                <h5 className="terms-of-service">Welcome to CARAi</h5>
                                                                <p className="terms-of-service-content">Welcome to the CARAi app! We’re thrilled to have you join our community of fleet managers. CARAi is designed to simplify your trucking experience, allowing you to handle truck assignments, track inspections, and schedule repairs effortlessly.</p>

                                                                <h5 className="terms-of-service">Terms and Conditions</h5>
                                                                <p className="terms-of-service-content">By using CARAi, you agree to our Terms and Conditions. These terms outline your rights and responsibilities while using the app, ensuring a safe and efficient experience for all users. Here’s a brief overview tailored for you:</p>

                                                                <h5 className="terms-of-service">1. Acceptance of Terms</h5>
                                                                <p className="terms-of-service-content">By accessing or using CARAi, you agree to comply with these Terms. If you do not agree, you may not use the app.</p>

                                                                <h5 className="terms-of-service">2. User Accounts</h5>
                                                                <p className="terms-of-service-content"><strong>Registration:</strong> To access all features, you must register your organization with an account. Please provide accurate and complete information during registration.</p>
                                                                <p className="terms-of-service-content"><strong>Account Security:</strong> Keep your login information confidential. You are responsible for all activities under your account.</p>

                                                                <h5 className="terms-of-service">3. Use of the Service</h5>
                                                                <p className="terms-of-service-content">Use CARAi for lawful purposes only. Avoid any actions that could harm the app or its users.</p>

                                                                <h5 className="terms-of-service">4. Fees and Payment</h5>
                                                                <p className="terms-of-service-content">Some features may require subscription fees, which are non-refundable.</p>

                                                                <h5 className="terms-of-service">5. Termination</h5>
                                                                <p className="terms-of-service-content">We reserve the right to suspend or terminate your account for any violations of these Terms.</p>

                                                                <p className="terms-of-service-content">For the full Terms and Conditions, please refer to the document provided in the app.</p>

                                                                <h5 className="terms-of-service">Getting Started</h5>
                                                                <p className="terms-of-service-content"><strong>1. Download and Install:</strong> Find CARAi on the App Store or Google Play Store and download it to your mobile device.</p>
                                                                <p className="terms-of-service-content"><strong>2. Sign Up/Log In:</strong> Open the app and create a new account using your email. If you already have an account, simply log in.</p>
                                                                <p className="terms-of-service-content"><strong>3. Profile Setup:</strong> Complete your profile by adding your fleet manager details, company details, vehicle(s) and drivers’ information, and your favorite or chosen service repair shop, as applicable or if internal please provide. This helps us tailor the app to your needs.</p>
                                                                <p className="terms-of-service-content"><strong>4. Payment Subscription:</strong> Please make your payment subscription choice and fill in the appropriate information.</p>
                                                                <p className="terms-of-service-content"><strong>5. Terms and Conditions:</strong> Please complete the agreement.</p>

                                                                <h5 className="terms-of-service">Navigating the Web Application</h5>
                                                                <p className="terms-of-service-content"><strong>• Home Screen:</strong> Your dashboard for quick access to your company data.</p>
                                                                <p className="terms-of-service-content"><strong>• Menu:</strong> Access all application features from the menu, including Dashboard, Trip, Fleet Manager, Drivers, Vehicles, Service Manager, Inspection Tracking, Repair System Tracking.</p>

                                                                <h5 className="terms-of-service">Key Features for Fleet Managers</h5>
                                                                <p className="terms-of-service-content"><strong>1. Assign of Pre and Post Inspection Trips:</strong> Streamline your inspection checks with easy-to-follow steps, saving you time and ensuring compliance.</p>
                                                                <p className="terms-of-service-content"><strong>2. Vehicle Assignments:</strong> Simplify logging hours, managing documents, and tracking expenses with assigned vehicles.</p>
                                                                <p className="terms-of-service-content"><strong>3. Driver Assignments:</strong> Keep track of your truck drivers and the vehicles they’re assigned to.</p>
                                                                <p className="terms-of-service-content"><strong>4. Repair System Tracking:</strong> Get notified about upcoming maintenance tasks to keep your trucks in top condition and manage fleet operability.</p>
                                                                <p className="terms-of-service-content"><strong>5. Service Booking:</strong> Easily book service appointments with your preferred mechanics directly through the app.</p>
                                                                <p className="terms-of-service-content"><strong>6. Marketplace:</strong> Convenient access to goods and services that can help with health, wellness, skilling, and organization, all in real-time.</p>

                                                                <h5 className="terms-of-service">Privacy and Data Security</h5>
                                                                <p className="terms-of-service-content"><strong>1. Data Encryption:</strong> All your data is encrypted both in transit and at rest, ensuring that your information is secure from unauthorized access.</p>
                                                                <p className="terms-of-service-content"><strong>2. User Consent:</strong> We only collect data that is necessary for the app’s functionality, and we always ask for your consent before accessing any personal information.</p>
                                                                <p className="terms-of-service-content"><strong>3. Anonymized Data:</strong> Any data used for analytics or improvements is anonymized to protect your identity.</p>
                                                                <p className="terms-of-service-content"><strong>4. Secure Authentication:</strong> We use secure authentication methods, such as two-factor authentication (2FA), to protect your account from unauthorized access.</p>

                                                                <h5 className="terms-of-service">Your Rights</h5>
                                                                <p className="terms-of-service-content"><strong>• Access and Control:</strong> You can access, update, or delete your personal information at any time through the app’s settings.</p>
                                                                <p className="terms-of-service-content"><strong>• Transparency:</strong> We are transparent about how your data is used and provide clear information in our privacy policy.</p>

                                                                <p className="terms-of-service-content">For more detailed information, please read our full Privacy Policy.</p>

                                                                <h5 className="terms-of-service">Support</h5>
                                                                <p className="terms-of-service-content">If you need any help or have questions, visit our support section in the app or contact our customer service team. We’re here to assist you!</p>

                                                                <p className="terms-of-service-content">Enjoy using CARAi and drive with confidence!</p>
                                                            </div>
                                                            <button
                                                                className="btn submit-btn btn-hvr w-50"
                                                                onClick={handleSubmit}
                                                            >
                                                                Proceed
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FleetHowToUse;
