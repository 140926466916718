import React from "react";
import { Link } from "react-router-dom";
// import { emptyLogo, free } from "../../../../utils/imagepath";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { truck, truck01 } from "../../../../utils/imagepath";
import { formatDate } from "../../../../utils/patterns/regex.pattern";
// import { formatDate } from "../../../../utils/patterns/regex.pattern";
// import { getExpiryMessage } from "../../../../utils/constant";

const OrderDetails = () => {
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const dispatch = useDispatch();

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };
  const data = useSelector((state: any) => state.common.selectedData);

  const calculateDaysDifference = (date: any) => {
    const currentDate: any = new Date();
    const futureDate: any = new Date(date);

    const timeDifference = futureDate - currentDate;

    const daysDiff = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDiff;
  };
  return (
    <>
      <div
        className={`truck-list-sidebar owner-operator-details ${
          offcanvas ? "truck-list-sidebar-open" : ""
        }`}
      >
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Owner Operator Details</h4>
            <Link to="#" className="clear-truck-list" onClick={handleOffcanvas}>
              <i className="fas fa-xmark" />
            </Link>
          </div>
          <div className="card-body">
            <h6 className="mb-1">ID - {data.user_id}</h6>
            <p className="text-muted">Owner Operator Details</p>
            <div className="container p-0">
              {/* Owner Operator Basic Profile */}
              <div className="popup-driver-profile">
                <div className="cc-driver-content">
                  <div className="card mb-3">
                    <div className="card-body ownerDetails-card">
                      <div className="row driver-widget">
                        <div className="col-sm-3 col-6">
                          <div className="driver-imgs w-100">
                            <img
                              className="avatar-img"
                              src={data.profile_Image || truck01}
                              alt="Profile"
                              onError={(e) => {
                                e.currentTarget.src = truck01;
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-9 driver-list-info">
                          <div className="driver-details">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="driver-base-profile mb-3">
                                  <div className="d-flex align-items-center justify-content-between flex-wrap operator-name">
                                    <h5>
                                      {data.firstname}
                                      <span className="badge bg-light-success">
                                        Active
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="driver-infos mt-2">
                                    <ul>
                                      <li>
                                        <h6>
                                          {data.firstname}{" "}
                                          {data.lastname ? data.lastname : ""}
                                          <span>(Owner)</span>
                                        </h6>
                                      </li>
                                      <li>
                                        <h5>
                                          <span>
                                            <i className="ti ti-mail-check me-1" />{" "}
                                            <span>{data.email}</span>
                                          </span>
                                        </h5>
                                        <h5>
                                          <span>
                                            <i className="ti ti-phone-check me-1" />{" "}
                                            <span>{data.phone}</span>
                                          </span>
                                        </h5>
                                      </li>
                                      <li>
                                        <h5>
                                          <span>
                                            <i className="ti ti-map-pin-check me-1" />{" "}
                                            <span>{data.address}</span>
                                          </span>
                                        </h5>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="details-heading">
                    <h5>Truck Details</h5>
                  </div>
                  <div className="card">
                    <div className="card-body ownerDetails-card">
                      <div className="row truck-widget">
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Truck Name</p>
                            <h6>{data.vehicle_name}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>License Plate</p>
                            <h6>{data.license_plate}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>VIN Number</p>
                            <h6>{data.vin_number}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Year</p>
                            <h6>{data.year}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="details-heading mt-3">
                    <h5>Plan Details</h5>
                  </div>
                  <div className="card">
                    <div className="card-body ownerDetails-card">
                      <div className="row truck-widget">
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Plan Name</p>
                            <h6>{data.plan_name}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Plan Type</p>
                            <h6>{data.plan_type}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Monthly subscription</p>
                            <h6>{data.amount_month}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Yearly subscription</p>
                            <h6>{data.amount_year}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Last Paid</p>
                            <h6>{formatDate(data.subscribed_date)}</h6>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="truck-list-text">
                            <p>Plan Expire</p>
                            <h6>
                              {formatDate(data.expired_date)}{" "}
                              <span className="text-danger">
                                ({calculateDaysDifference(data.expired_date)}{" "}
                                Days Left)
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Owner Operator Basic Profile */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
