import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  avatar30,
  begginer,
  expert,
  intermediate,
} from "../../../../utils/imagepath";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ApiServiceContext } from "../../../../utils/shared.module";
import { end_points } from "../../../../services/end_point/end_points";
import { formatDate } from "../../../../utils/patterns/regex.pattern";

const ViewSubscriber = () => {
  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };
  const data = useSelector((state: any) => state.common.editData);
  const { getData } = useContext(ApiServiceContext);
  const [listData, setListData] = useState<any>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const userDetails = useSelector((state: any) => state.login.userDetails);
  const getList = async () => {
    try {
      let url = end_points.subscriber_view.url;
      url += `?courseAdminId=${userDetails?.courseadmin_id}&userId=${data?.userId}`;
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data?.subscriptions);
      }
    } catch (error) {
      console.error(error);
    }
    try {
      let url = end_points.subscriber_courseview.url;
      url += `?courseAdminId=${userDetails?.courseadmin_id}&userId=${data?.userId}`;
      const response = await getData(url);
      if (response.status === 200) {
        const Data = response?.data?.data?.subscriptions?.map(
          (item: any, index: any) => ({ ...item, id: index + 1 })
        );
        setTableData(Data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getList();
  }, [data, userDetails]);

  const columns = [
    {
      header: "SN",
      field: "id",
      key: "id",
      sortField: "id",
    },
    {
      header: "Course Name",
      field: "courseName",
      key: "courseName",
      sortField: "courseName",
    },
    {
      header: "Score",
      field: "scorePercentage",
      key: "scorePercentage",
      sortField: "scorePercentage",
      body: (col: any) => (
        <div className="d-flex" style={{ gap: "10px" }}>
          <p
            className={`fw-bold ${
              Number(col?.scorePercentage) > 40 ? "text-primary" : "text-danger"
            }`}
          >
            {col?.scorePercentage}%
          </p>
          <div
            className="score-badge"
            style={{
              background: col?.scoreStatus === "Pass" ? "#71DE71" : "#FF0000",
            }}
          >
            <p>{col?.scoreStatus}</p>
          </div>
        </div>
      ),
    },
    {
      header: "Badge",
      field: "badge",
      key: "badge",
      sortField: "badge",
      body: (col: any) => (
        <div style={{ height: "30px", width: "30px" }}>
          {col?.badge === "Expert" ? (
            <img src={expert} alt="bagdge-img" />
          ) : col?.badge === "Intermediate" ? (
            <img src={intermediate} alt="bagdge-img" />
          ) : col?.badge === "Beginner" ? (
            <img src={begginer} alt="bagdge-img" />
          ) : (
            "-"
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {" "}
      {/* Product View Sidebar */}
      <div
        className={`truck-list-sidebar view-subscriber-cls ${
          offcanvas ? "truck-list-sidebar-open" : ""
        }`}
      >
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Id - {data?.subscriptionRefId}</h4>
            <Link to="#" className="clear-truck-list" onClick={handleOffcanvas}>
              <i className="fas fa-xmark"></i>
            </Link>
          </div>
          <div className="card-body">
            <div className="sidebar-sub-header">
              <h4>Subscriber Details</h4>
              <p>Please check subscribers informations</p>
            </div>
            <div className="truck-information">
              <div className="course-subscriber-info-grid flex-column">
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "20px" }}
                >
                  <div className="course-subscriber-info-left course-subscriber-info-sl">
                    <img
                      src={`https://api.thecarai.ai${data?.profile_image}`}
                      alt=""
                      style={{ width: "80px", height: "60px" }}
                    />
                  </div>
                  <div className="course-subscriber-info-right course-subscriber-info-sl">
                    <div className="course-subs-hdr">
                      <div className="course-hdr">
                        <h6>
                          {data?.firstName} {data?.lastName}
                        </h6>
                      </div>
                      <div className="course-info-list">
                        <ul className="text-muted">
                          <li>
                            <span>{data?.email}</span>
                          </li>
                          <li>
                            <span>{data?.phone}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="seperate-course"></div>
                <div className="course-subscriber-info-right course-subscriber-info-sl">
                  <div className="course-subs-hdr">
                    <div className="course-hdr">
                      <h6>Badges</h6>
                    </div>
                    <div
                      className="d-flex justify-content-start"
                      style={{ gap: "20px" }}
                    >
                      {listData?.badgeCount?.map((item: any, index: any) => (
                        <div
                          className="course-subs-stage-right flex-column"
                          style={{ gap: "0px" }}
                          key={index}
                        >
                          {item?.badge === "Expert" ? (
                            <img src={expert} alt="bagdge-img" />
                          ) : item?.badge === "Intermediate" ? (
                            <img src={intermediate} alt="bagdge-img" />
                          ) : item?.badge === "Beginner" ? (
                            <img src={begginer} alt="bagdge-img" />
                          ) : (
                            "-"
                          )}
                          <p className="mb-0">{item?.badge}</p>
                          <span className="text-muted">({item?.count})</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card view-subscriber-card mb-3">
                <div className="card-body view-subscriber-card-body">
                  <div className="sub-info-list">
                    <h6>Plan Details</h6>
                    <ul>
                      <li>
                        <span className="sub-info-lbl">Course Name</span>
                        <span className="text-muted">
                          {listData?.subscriptions?.[0]?.courseName}
                        </span>
                      </li>
                      <li>
                        <span className="sub-info-lbl">
                          Subscription Amount
                        </span>
                        <span className="text-muted">
                          ${listData?.subscriptions?.[0]?.fees}
                        </span>
                      </li>
                      <li>
                        <span className="sub-info-lbl">Payment Date</span>
                        <span className="text-muted">
                          {listData?.subscriptions?.[0]?.subscriptionDate
                            ? formatDate(
                                listData?.subscriptions?.[0]?.subscriptionDate
                              )
                            : "-"}
                        </span>
                      </li>
                      <li>
                        <span className="sub-info-lbl">Expiry Date</span>
                        <span className="text-muted">
                          {listData?.subscriptions?.[0]?.expiryDate
                            ? formatDate(
                                listData?.subscriptions?.[0]?.expiryDate
                              )
                            : "-"}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <h4>Course Details</h4>
              <div className="card view-subscriber-card mb-3">
                <div className="card-body view-subscriber-card-body">
                  <div className="table-responsive">
                    <DataTable
                      value={tableData}
                      className="table datatable"
                      paginator={false}
                    >
                      {columns?.map((col: any, index: number) => (
                        <Column
                          key={col.field || index}
                          field={col.field}
                          header={col.header}
                          body={col.body}
                          sortable={col.sortable === false ? false : true}
                          sortField={col.sortField ? col.sortField : col.field}
                          className={col.className ? col.className : ""}
                        />
                      ))}
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer sidebar-btn custom-footer-card">
            <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
              Cancel
            </Link>
          </div>
        </div>
      </div>
      {/* /Product View Sidebar */}
    </>
  );
};

export default ViewSubscriber;
