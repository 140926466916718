import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { noImg } from "../../../../utils/imagepath";
import { routes } from "../../../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import PrimeDataTable from "../../../../components/data-table";
import AssignNewVehicle from "./assign-vehicle";
import AddService from "./add-service";
import {
  setSelectedData,
  setServiceVehicleData,
} from "../../../../core/redux/commonSlice";
import { formatDate } from "../../../../utils/patterns/regex.pattern";
import { capitalizeFirstLetter } from "../../../../utils/constant";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../utils/shared.module";

const TripDetails = (props: any) => {
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [listData, setListData] = useState<any[]>([]);
  const [vehicleData, setVehicleData] = useState<any>({});
  const [tripTrackingData, setTripTrackingData] = useState<any[]>([]);
  const [rows, setRows] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<any>(0);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const { getData } = useContext(ApiServiceContext);
  const [count, setCount] = useState<number>(0);

  const dispatch = useDispatch();

  const data = useSelector((state: any) => state.common.selectedData);

  const [vehiclesList, setVehiclesList] = useState([]);
  const [driversList, setDriversList] = useState([]);
  const [managerListData, setManagerListData] = useState<any[]>([]);
  const [annoucementData, setAnnoucementData] = useState<any>({});

  const getVehicles = async () => {
    try {
      const url = end_points.get_all_vehicle.url;

      const response = await getData(url);
      if (response.status === 200) {
        const list = response?.data?.data?.vehicle;
        const extractedList = list.map((vehicle: any) => ({
          code: vehicle.vc_id,
          name: vehicle.vehicle_name,
        }));
        setVehiclesList(extractedList);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getDrivers = async () => {
    try {
      let url = end_points.assign_drivers.url;
      const response = await getData(url);
      if (response.status === 200) {
        const list = response?.data?.data?.user;

        const extractedList = list.map((driver: any) => ({
          code: driver.user_id,
          name: driver.first_name,
        }));
        setDriversList(extractedList);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getManagerList = async () => {
    try {
      let url = end_points.get_allservicemanager.url;

      const response = await getData(url);
      if (response.status === 200) {
        const serviceManagerList = response?.data?.data?.user?.map(
          (manager: any) => {
            return { name: manager?.first_name, code: manager?.user_id };
          }
        );
        setManagerListData(serviceManagerList);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAnnoucementList = async () => {
    try {
      let url = end_points.get_Announcement.url;
      url += `?tripid=${data.trip_id}`;
      const response = await getData(url);
      if (response.status === 200) {
        setAnnoucementData(response.data.data.repair);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getVehicles();
    getDrivers();
    getManagerList();
    getAnnoucementList();
  }, []);

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(listData.map((item) => item.inspec_Id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };
  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    inspecId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(inspecId);
    } else {
      newSelectedRows.delete(inspecId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data?.insp_Id)}
              onChange={(e) => handleRowCheckboxChange(e, data?.insp_Id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "Inspection ID",
      field: "inspection_refid",
      key: "inspection_refid",
      sortField: "inspection_refid",
      body: (data: any) => (
        <div className="d-flex align-items-center">
          {data?.inspection_refid}
          {data?.status === "major" &&
            data.repairDetails.service_managername === null && (
              <i className="ti ti-info-circle ms-1 text-danger" />
            )}
          {data.repairDetails.service_managername != null && (
            <i className="ti ti-settings" />
          )}
        </div>
      ),
    },
    {
      header: "Vehicle ID",
      field: "vc_refid",
      key: "vc_refid",
      sortField: "vc_refid",
      body: (data: any) => (
        <div className="text-link">{data?.vehicle?.vc_refid}</div>
      ),
    },

    {
      header: "Vehicle Name",
      field: "vehicle_name",
      key: "vehicle_name",
      sortField: "vehicle_name",
      body: (data: any) => (
        <h2 className="table-avatar">
          <Link to="#" className="avatar avatar-sm me-2">
            <img
              className="avatar-img rounded-circle"
              src={data?.vehicle?.vehicle_image}
              alt="Truck"
            />
          </Link>
          <Link to="#">{data?.vehicle?.vehicle_name}</Link>
        </h2>
      ),
    },
    {
      header: "Inspection Status",
      field: "inspectionStatus",
      key: "inspectionStatus",
      sortField: "inspectionStatus",
      body: (data: any) => (
        <span
          className={`badge ${
            data.inspection_Status === "To Do"
              ? "status-todo"
              : data.inspection_Status === "Completed"
              ? "status-completed"
              : data.inspection_Status === "In Progress"
              ? "status-inprogress"
              : ""
          }`}
        >
          {data.inspection_Status || "-"}
        </span>
      ),
    },
    {
      header: "Service Manager",
      field: "service_managername",
      key: "service_managername",
      sortField: "service_managername",
      body: (data: any) => (
        <h2 className="table-avatar">
          {data.repairDetails.service_managerimage != null && (
            <Link to="#" className="avatar avatar-sm me-2">
              <img
                className="avatar-img rounded-circle"
                src={data.repairDetails.service_managerimage || noImg}
                alt="Profile"
              />
            </Link>
          )}
          <Link to="#">
            {capitalizeFirstLetter(data.repairDetails.service_managername) ||
              "-"}
          </Link>
        </h2>
      ),
    },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortField: "status",
      body: (data: any) => (
        <span
          className={`badge ${
            data.status === "no issues"
              ? "status-completed"
              : data.status === "major"
              ? "status-danger"
              : data.status === "minor"
              ? "status-inprogress"
              : ""
          }`}
        >
          {capitalizeFirstLetter(data.status) || "-"}
        </span>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (data: any) => {
        if (
          (data?.status === "major" &&
            data.inspection_Status === "Completed" &&
            data.repairDetails.service_managername === null) ||
          (data.trip_Status === "Breakdown" &&
            data.repairDetails.service_managername === null)
        ) {
          return (
            <>
              {" "}
              <div className="dropdown">
                <Link
                  to="#"
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <i className="feather icon-more-vertical" />
                </Link>

                <div className="dropdown-menu dropdown-menu-end">
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => {
                      openServiceModal(data);
                      dispatch(setServiceVehicleData(data));
                    }}
                  >
                    <i className="ti ti-tool" /> Add to Service
                  </Link>
                </div>
              </div>
            </>
          );
        }
        return null;
      },
    },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isServiceModal, setIsServiceModal] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openServiceModal = (item: any) => {
    setIsServiceModal(true);
    setVehicleData(item);
  };
  const closeServiceModal = () => setIsServiceModal(false);

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.get_InspectionStatus.url;
      url += `?tripid=${data.trip_id}`;
      // if (page) {
      //   url += `?PageNumber=${page}&PageSize=${limit}`;
      // }
      // if (searchQuery) {
      //   url += `&SearchTerm=${searchQuery}`;
      // }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.inspectionandservice);
        // setCount(response?.data?.totalCount);
        // setTotalRecords(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getTripTracking = async () => {
    try {
      let url = end_points.get_trip_tracking.url;
      url += `?tripid=${data.trip_id}`;
      const response = await getData(url);
      if (response.status === 200) {
        setTripTrackingData(response?.data?.data?.trip);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
    getTripTracking();
  }, [currentPage, rows, searchQuery]);

  const hasVehicleBreakdown = tripTrackingData?.some(
    (item: any) => item?.label === "Breakdown"
  );

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Trip ID - {data.trip_refid}</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.trip.path} className="text-black">
                      Trip
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{data.trip_id}</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-lg-8 col-12">
              {/* Sub Header */}
              <div className="sub-header">
                <div className="sub-header-content">
                  <ul className="nav">
                    <li>
                      <h4>Overview</h4>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /Sub Header */}
              {/* Table */}
              <div className="card">
                <div className="card-body">
                  <div className="trip-head">
                    <div>
                      <h5>{data.trip_name}</h5>
                      <p className="trip-des">{data.trip_description}</p>
                    </div>
                    <span
                      className={`badge ${
                        data.trip_status === "Initiated"
                          ? "status-initiated"
                          : data.trip_status === "Completed"
                          ? "status-completed"
                          : data.trip_status === "In Progress"
                          ? "status-inprogress"
                          : ""
                      }`}
                    >
                      {data.trip_status}
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <div className="trip-details">
                        <div className="trip-details-img">
                          <i className="ti ti-car" />
                        </div>
                        <div className="trip-details-content">
                          <h6>Assigned Vehicle</h6>
                          <h2 className="table-avatar">
                            <Link to="#" className="avatar avatar-sm me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={data.vehicle.vehicle_image || noImg}
                                alt="Truck"
                              />
                            </Link>
                            <p>{data.vehicle.vehical_number}</p>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="trip-details">
                        <div className="trip-details-img">
                          <i className="ti ti-steering-wheel" />
                        </div>
                        <div className="trip-details-content">
                          <h6>Assigned Driver</h6>
                          <h2 className="table-avatar">
                            <Link to="#" className="avatar avatar-sm me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={data.assignimage || noImg}
                                alt="Truck"
                              />
                            </Link>
                            <p>{data.assignto}</p>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="trip-details">
                        <div className="trip-details-img">
                          <i className="ti ti-calendar-up" />
                        </div>
                        <div className="trip-details-content">
                          <h6>Trip Start Date</h6>
                          <h5>{formatDate(data.trip_start)}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="trip-details">
                        <div className="trip-details-img">
                          <i className="ti ti-calendar-down" />
                        </div>
                        <div className="trip-details-content">
                          <h6>Trip End Date</h6>
                          <h5>{formatDate(data.trip_end)} </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="trip-status">
                        <h6>Trip Inspection Status</h6>
                        <span
                          className={`badge ${
                            data.trip_inspectionstatus === "To Do"
                              ? "status-todo"
                              : data.trip_inspectionstatus === "Completed"
                              ? "status-completed"
                              : data.trip_inspectionstatus === "In Progress"
                              ? "status-inprogress"
                              : ""
                          }`}
                        >
                          {data.trip_inspectionstatus}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Table */}
            </div>
            <div className="col-lg-4 col-12">
              {/* Sub Header */}
              <div className="sub-header">
                <div className="sub-header-content">
                  <ul className="nav">
                    <li>
                      <h4>Trip Tracking</h4>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /Sub Header */}
              {/* Table */}
              <div className="card">
                <div className="tracking-list">
                  <div className="card-body">
                    <div className="trip-tracking">
                      <ul>
                        {tripTrackingData?.map((item: any, index: any) => (
                          <li
                            className={
                              item?.is_Completed
                                ? item?.label === "Checklist Issues" ||
                                  item?.label === "Breakdown"
                                  ? "issues"
                                  : "checked"
                                : ""
                            }
                            key={index}
                          >
                            <h5>{item?.label}</h5>
                            <h6>{item?.description}</h6>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="details-btn">
                <button
                  onClick={() => {
                    dispatch(setSelectedData(data));
                    openModal();
                  }}
                  className="btn btn-add w-100"
                  disabled={
                    data.vehicle_status !== "major" &&
                    data.trip_status !== "Breakdown"
                  }
                >
                  Assign New Vehicle
                </button>
              </div>
              {/* /Table */}
            </div>
          </div>
          {/* Sub Header */}
          {listData.length > 0 && (
            <>
              <div className="sub-header">
                <div className="sub-header-content">
                  <ul className="nav">
                    <li>
                      <h4>Inspection &amp; Service Status</h4>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /Sub Header */}

              {/* Table */}
              <div className="card table-card">
                <div className="card-body">
                  <div className="table-responsive prime-table">
                    <PrimeDataTable
                      column={columns}
                      setSearchQuery={setSearchQuery}
                      data={listData}
                      rows={rows}
                      setRows={setRows}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalRecords={totalRecords}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {/* Table */}
          {hasVehicleBreakdown && (
            <>
              {/* Sub Header */}
              <div className="sub-header">
                <div className="sub-header-content">
                  <ul className="nav">
                    <li>
                      <h4>Vehicle Breakdown Reason</h4>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /Sub Header */}

              {/* Table */}
              <div className="card table-card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Latitude</th>
                          <th>Longitude</th>
                          <th>Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{annoucementData?.latitude}</td>
                          <td>{annoucementData?.longitude}</td>
                          <td className="text-break">
                            {annoucementData?.reason_Comments}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* /Table */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {isModalOpen && (
        <AssignNewVehicle
          data={data}
          vehiclesList={vehiclesList}
          driversList={driversList}
          closeModal={closeModal}
        />
      )}
      {isServiceModal && (
        <AddService
          closeServiceModal={closeServiceModal}
          managerListData={managerListData}
          vehicleData={vehicleData}
          getList={getList}
        />
      )}
    </>
  );
};

export default TripDetails;
