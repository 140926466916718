import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import AddCourse from "./add-course";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import PrimeDataTable from "../../../../components/data-table";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar,setAddCanvasSidebarCourseView } from "../../../../core/redux/sidebarSlice";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { ConfirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { setEditData } from "../../../../core/redux/commonSlice";
import { formatDate } from "../../../../utils/patterns/regex.pattern";
import ViewCourse from "./view-course";
import { videotag2,noImg,expert, signatureImg } from "../../../../utils/imagepath";

const CourseSubscribers = () => {
  const { getData, putData } = useContext(ApiServiceContext);
  const userDetails = useSelector((state: any) => state.login.userDetails);
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [courseList, setCourseList] = useState([]);

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const offcanvascourseview = useSelector((state: any) => state.sidebar.addCanvasSidebarCourseView)
  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.list_quiz.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.quiz);
        setCount(response?.data?.totalCount);
        setTotalRecords(response?.data?.totalCount);
        setCount(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState<any>(null);

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(listData.map((item) => item.quiz_id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleEditTutorial = (data: any) => {
    dispatch(setEditData(data));
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    tutorialId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(tutorialId);
    } else {
      newSelectedRows.delete(tutorialId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const [visible, setVisible] = useState(false);

  const deletePlan = async (planId: number) => {
    try {
      const payload = {
        id: planId,
        deleted_by: userDetails?.courseadmin_id,
      };
      let url = end_points.delete_quiz.url;
      const response = await putData(url, payload);
      if (response.status === 200) {
        toast.success(response?.data?.response?.responseMessage);
        getList();
      }
    } catch (error) {}
  };

  const accept = () => {
    if (selectedTutorial) {
      deletePlan(selectedTutorial.quiz_id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (plan: any) => {
    setSelectedTutorial(plan);
    setVisible(true);
  };

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data.quiz_id)}
              onChange={(e) => handleRowCheckboxChange(e, data.quiz_id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "ID",
      field: "course_id",
      key: "course_id",
      sortField: "course_id",
    },
    {
      header: "Subscriber",
      field: "course_title",
      key: "course_title",
      sortField: "course_title",
      body: (data: any) => (
        <h2 className="table-avatar">
          <Link to="#" className="avatar avatar-sm me-2">
            <img
              className="avatar-img rounded-circle"
              src={data.profile_Image || noImg}
              alt="Profiles"
            />
          </Link>
          <p>{data.firstname}</p>
        </h2>
      ),
    },
    {
      header: "Quiz / Exam",
      field: "categoryName",
      key: "categoryName",
      sortField: "categoryName",
    },
    {
      header: "Score",
      field: "course_description ",
      key: "course_description ",
      sortField: "course_description ",
    },
    {
      header: "Level",
      field: "courseCount",
      key: "courseCount",
      sortField: "courseCount",
    },
    {
      header: "Batch",
      field: "created_at",
      key: "created_at",
      sortField: "created_at",
      body: (data: any) => <span>{formatDate(data.created_at)}</span>,
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (item: any) => {
        return (
          <>
            {" "}
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link to="#" className="dropdown-item" onClick={() => {
                    handleOffcanvasView();
                  }}>
                  <i className="feather icon-eye" /> View Certificate
                </Link>            
                <Link
                  onClick={() => {
                    handleEditTutorial(item);
                    handleOffcanvas();
                  }}
                  to="#"
                  className="dropdown-item"
                >
                  <i className="feather icon-edit" /> Edit
                </Link>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => handleDeleteClick(item)}
                >
                  <i className="feather icon-trash-2" /> Delete
                </Link>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const quiz_id = row;
    try {
      const payload = {
        id: quiz_id,
        isActive: isChecked,
      };
      let url = end_points.update_status_quiz.url;
      const response = await putData(url, payload);
      if (response.status === 200) {
        setListData((prevData: any) =>
          prevData.map((row: any) =>
            row.quiz_id === quiz_id ? { ...row, status: isChecked } : row
          )
        );
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };
  const dispatch = useDispatch();

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  const handleOffcanvasView = () => {
    dispatch(setAddCanvasSidebarCourseView(!offcanvascourseview));
  }

  const getCourseList = async () => {
    try {
      let url = end_points.quiz_category.url;
      const response = await getData(url);
      if (response.status === 200) {
        const data = response?.data?.data?.quiz?.map((item: any) => {
          return { label: item?.quiz_type, value: item?.quiz_typeid };
        });
        setCourseList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCourseList();
  }, []);

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
            {/* Certificate */}

            <table style={{width:"100%", position: "relative", background: "#fff"}}>
                <tbody>
                    <tr>
                        <td>
                            <span style={{width: "300px", height: "300px", display:"flex", position: "absolute", left: "0", top: "0", zIndex:"1",borderStyle:"solid", borderWidth:"150px", borderColor:"#194185 transparent transparent #194185", pointerEvents:"none"}}></span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{padding:"30px", position: "relative", zIndex:"2"}}>
                            <table style={{width:"100%"}}>
                                <tr>
                                    <td style={{border: "10px solid #FFA500"}}>
                                        <table style={{width:"100%"}}>
                                            <tr>
                                                <td style={{color: "#FFA500",fontSize:"75px",textTransform: "uppercase",padding: "50px 0 25px", textAlign: "center",fontFamily: "Noto Sans"}}>Certificate</td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign:"center"}}>
                                                    <span style={{background:"#194185", color:"#fff",fontWeight: "600",fontSize:"25px",padding: "5px 20px", textTransform: "uppercase", fontFamily: "Noto Sans"}}>Of Achievement</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: "100px 0 75px", textAlign:"center"}}>
                                                    <span style={{color: "#194185", fontSize: "20px", fontWeight: "500", fontFamily: "Noto Sans"}}>This certificate is proudly presented to</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: "center"}}>
                                                    <span style={{fontSize: "75px", fontFamily: "Beau Rivage", color: "#000", borderBottom: "1px solid #194185", padding: "0 30px",lineHeight: "60px", display:"inline-block"}}>Mr. Christopher Jonathan</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: "center",padding: "75px 0"}}>
                                                    <p style={{fontSize: "15px",lineHeight: "25px",color: "#000", maxWidth: "980px", margin: "0 auto"}}>
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign:"center",padding: "0 0 75px"}}>
                                                    <table style={{maxWidth: "850px",width: "100%", margin: "0 auto"}}>
                                                        <tr>
                                                            <td>
                                                                <table style={{width: "100%"}}>
                                                                    <tr>
                                                                        <td>
                                                                            <span style={{color: "#000",fontSize:"15px",lineHeight:"25px",fontWeight: "600"}}>01-10-2024</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><hr/></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span style={{textTransform: "uppercase", color:"#99A2A8", fontSize:"15px",lineHeight:"25px"}}>Date of Exam</span></td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                            <td style={{width: "60%"}}>
                                                                <table style={{width: "100%"}}>
                                                                    <tr>
                                                                        <td style={{textAlign: "center"}}>
                                                                            <div><img src={expert} /></div>
                                                                            <div style={{color: "#000",fontSize:"15px",lineHeight:"25px",fontWeight: "600", textTransform: "uppercase"}}>expert</div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                            <td>
                                                                <table style={{width: "100%"}}>
                                                                    <tr>
                                                                        <td>
                                                                            <img src={signatureImg} alt="" />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><hr/></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span style={{textTransform: "uppercase", color:"#99A2A8", fontSize:"15px",lineHeight:"25px"}}>Signature</span></td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span style={{width: "300px", height: "300px", display:"flex", position: "absolute", right: "0", bottom: "0", zIndex:"1",borderStyle:"solid", borderWidth:"150px", borderColor:"transparent #194185 #194185 transparent", pointerEvents:"none"}}></span>
                        </td>
                    </tr>
                </tbody>
            </table>

          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">#CR001</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.dashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to={routes.course.path}>Course</Link>
                  </li>
                  <li className="breadcrumb-item">CR001</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Dashboard */}
          <div className="dashboard-info">
            
            <div className="row">
                <div className="col-lg-9">
                    <div className="course-subscriber-info-grid">
                        <div className="course-subscriber-info-left">
                            <img src={videotag2} alt="" />
                        </div>
                        <div className="course-subscriber-info-right">
                            <div className="course-subs-hdr">
                                <div className="course-hdr">
                                    <h6>Food and Preparation</h6>
                                    <span className="badge status-food">Food</span>
                                </div>
                                <div className="course-info-list">
                                    <ul className="text-muted">
                                        <li><span>5 Videos</span></li>
                                        <li><span>06-07-2024</span></li>
                                        <li><span>No of Quiz - 10</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-subs-body">
                                <div className="course-subs-body-left">
                                    Status : Active
                                </div>
                                <div className="course-subs-body-right">
                                    Last Update : 06-07-2024
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="subsc-count-grid">
                                <h5>Subscriber</h5>
                                <div className="subsc-count">
                                    <ul>
                                        <li>
                                            <div className="subsc-success">20</div>
                                            <div>Subscribers</div>
                                        </li>
                                        <li>
                                            <div className="subsc-attempts">05</div>
                                            <div>Attempts</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div className="row">
            <div className="col-xl-12 mt-3">
                <div className="dashboard-header">
                <div className="row align-items-center">
                    <div className="col-md-6">
                    <h4>Subscribers List ({count})</h4>
                    </div>
                    {/* <div className="col-md-6">
                    <div className="dashboard-nav-list d-flex flex-wrap justify-content-end align-items-center">
                        <Link
                        onClick={handleOffcanvas}
                        to="#"
                        className="btn btn-add btn-hvr truck-item"
                        >
                        <i className="ti ti-circle-plus" /> Add Course
                        </Link>
                        <Link
                        onClick={handleOffcanvasView}
                        to="#"
                        className="btn btn-add btn-hvr truck-item"
                        >
                        <i className="ti ti-circle-plus" /> View Course
                        </Link>
                    </div>
                    </div> */}
                </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-xl-12">
                <div className="card table-card">
                  <div className="card-body">
                    <div className="table-responsive prime-table">
                      <PrimeDataTable
                        setSearchQuery={setSearchQuery}
                        column={columns}
                        data={listData}
                        rows={rows}
                        setRows={setRows}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalRecords={totalRecords}
                      />
                    </div>
                  </div>
                </div>
            </div>
        </div>
          </div>
          {/* /Dashboard */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {offcanvas && <AddCourse successcall={getList} courseList={courseList} />}
      {offcanvascourseview && <ViewCourse successcall={getList} courseList={courseList} />}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this Course?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
        draggable={false}
      />
    </>
  );
};

export default CourseSubscribers; 
