import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiServiceContext, routes } from "../../../../utils/shared.module";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { end_points } from "../../../../services/end_point/end_points";
import PrimeDataTable from "../../../../components/data-table";
import { toast } from "react-toastify";
import { setEditData } from "../../../../core/redux/commonSlice";
import { formatDate } from "../../../../utils/patterns/regex.pattern";
import AddCourse from "../courses/add-course";
import { ConfirmDialog } from "primereact/confirmdialog";

const CourseTable = ({ setListCount }: any) => {
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const dispatch = useDispatch();
  const { getData, putData } = useContext(ApiServiceContext);
  const [count, setCount] = useState<number>(0);
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState("");
  const [courseList, setCourseList] = useState([]);
  const userDetails = useSelector((state: any) => state.login.userDetails);

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };
  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.course_list.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}&courseadminid=${userDetails?.courseadmin_id}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.courses);
        setCount(response?.data?.totalCount);
        setTotalRecords(response?.data?.totalCount);
        setCount(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);
  const [selectedTutorial, setSelectedTutorial] = useState<any>(null);

  const handleEditTutorial = (data: any) => {
    dispatch(setEditData(data));
  };

  const deletePlan = async (planId: number) => {
    try {
      let url = end_points.delete_course.url;
      url += `?id=${planId}&deleted_by=${userDetails?.courseadmin_id}`;
      const response = await putData(url);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        getList();
      }
    } catch (error) {}
  };

  const accept = () => {
    if (selectedTutorial) {
      deletePlan(selectedTutorial.course_id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (plan: any) => {
    setSelectedTutorial(plan);
    setVisible(true);
  };

  const columns = [
    {
      header: "ID",
      field: "course_refid",
      key: "course_refid",
      sortField: "course_refid",
    },
    {
      header: "Course Title",
      field: "course_name",
      key: "course_name",
      sortField: "course_name",
    },
    {
      header: "No of Videos",
      field: "videoCount",
      key: "videoCount",
      sortField: "videoCount",
    },
    {
      header: "Category",
      field: "course_categoryname",
      key: "course_categoryname",
      sortField: "course_categoryname",
    },
    {
      header: "Quiz / Exam",
      field: "quizCount",
      key: "quizCount",
      sortField: "quizCount",
    },
    {
      header: "Date",
      field: "ceateddate",
      key: "ceateddate",
      sortField: "ceateddate",
      body: (data: any) => <span>{formatDate(data.ceateddate)}</span>,
    },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortField: "status",
      body: (data: any) => (
        <div className="toggles">
          <label className="switch">
            <input
              type="checkbox"
              id="togBtn"
              checked={data.status === 1 ? true : false}
              onChange={(event) => handleToggle(event, data.course_id)}
            />
            <div className="slider round">
              <span className="on">
                <i className="ti ti-check" />
              </span>
              <span className="off">
                <i className="ti ti-x" />
              </span>
            </div>
          </label>
        </div>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (item: any) => {
        return (
          <>
            {" "}
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => {
                    handleEditTutorial(item);
                    handleOffcanvas();
                    setType("View");
                  }}
                >
                  <i className="feather icon-eye" /> View
                </Link>
                <Link
                  to={routes.subscribersList.path}
                  className="dropdown-item"
                >
                  <i className="feather icon-eye" /> View Subscribers List
                </Link>
                <Link
                  onClick={() => {
                    handleEditTutorial(item);
                    handleOffcanvas();
                    setType("Edit");
                  }}
                  to="#"
                  className="dropdown-item"
                >
                  <i className="feather icon-edit" /> Edit
                </Link>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => handleDeleteClick(item)}
                >
                  <i className="feather icon-trash-2" /> Delete
                </Link>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const quiz_id = row;
    try {
      const payload = {
        id: quiz_id,
        isActive: isChecked,
      };
      let url = end_points.update_course_status.url;
      const response = await putData(url, payload);
      if (response.status === 200) {
        setListData((prevData: any) =>
          prevData.map((row: any) =>
            row.course_id === quiz_id ? { ...row, status: isChecked } : row
          )
        );
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (offcanvas) {
    } else {
      setType("");
    }
  }, [offcanvas]);
  const getCourseList = async () => {
    try {
      let url = end_points.course_category_list.url;
      const response = await getData(url);
      if (response.status === 200) {
        const data = response?.data?.data?.course_category?.map((item: any) => {
          return { label: item?.category_name, value: item?.cour_categoryid };
        });
        setCourseList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCourseList();
  }, []);

  useEffect(() => {
    setListCount(listData?.length);
  }, [listData]);
  return (
    <>
      <div className="sub-header">
        <div className="sub-header-content">
          <ul className="nav">
            <li>
              <h4>Course({count || 0})</h4>
            </li>
          </ul>
        </div>
      </div>
      <div className="card table-card">
        <div className="card-body">
          <div className="table-responsive prime-table">
            <PrimeDataTable
              setSearchQuery={setSearchQuery}
              column={columns}
              data={listData}
              rows={rows}
              setRows={setRows}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
      {offcanvas && (
        <AddCourse
          successcall={getList}
          courseList={courseList}
          setType={setType}
          type={type}
          deletePlan={deletePlan}
        />
      )}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this Course?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
        draggable={false}
      />
    </>
  );
};

export default CourseTable;
