import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiServiceContext } from "../../services/api/api.service";
import { end_points } from "../../services/end_point/end_points";

import { setPlanData, setPlanType } from "../../core/redux/planSlice";
import { useDispatch, useSelector } from "react-redux";

interface PlansProps {
  setPaymentVisible: (visible: boolean) => void; // Add prop for setting payment visibility
  setLoginVisible: (visible: boolean) => void; // Add prop for setting payment visibility
}

const Plans: React.FC<PlansProps> = ({
  setPaymentVisible,
  setLoginVisible,
}) => {
  const { getData } = useContext(ApiServiceContext);
  const [plansList, setPlansList] = useState<any>([]);
  console.log("plansList", plansList);

  const [showAlert, setShowAlert] = useState(false);
  const [hoverPlan, setHoverPlan] = useState<any>({});

  //   const plansdata = useSelector((state: any) => state.plan.selectedPlan);

  const [planType, setPlan] = useState<boolean>(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setLoginVisible(false);
    setPlan(checked);
    dispatch(setPlanType(checked));
  };

  useEffect(() => {
    dispatch(setPlanType(false));
  }, [setPlanType]);

  const PlansList = async (search?: any) => {
    let urls = end_points.price_list.url;
    const response = await getData(urls);
    if (response?.status === 200) {
      setPlansList(response?.data?.data?.prices);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    PlansList();
  }, []);

  const handleMouseEnter = (data: any) => {
    setHoverPlan(data);
    setShowAlert(true);
  };

  const handleMouseLeave = () => {
    setHoverPlan({});
    setShowAlert(false);
  };
  const getoffer = (planType: boolean, plan: any) => {
    if (planType) {
      if (plan.discount_for == 2) {
        return `${plan.currency}${plan.amount_year}`;
      } else {
        if (plan.discount_for !== 2) {
          return null;
        }
        return `${plan.currency}${plan.amount}`;
      }
    } else {
      if (plan.discount_for == 1) {
        return `${plan.currency}${plan.amount_month}`;
      } else {
        if (plan.discount_for !== 1) {
          return null;
        } else return `${plan.currency}${plan.amount}`;
      }
    }
  };

  // const getAmount = (planType: boolean, plan: any) => {
  //   console.log("planType",planType)
  //   console.log("plan",plan)

  //   let amount;

  //   if (planType === false) {
  //     // Case when planType is false
  //     if (plan.discount_for == 1) {
  //       // Discount is for 1
  //       amount = plan.amount;
  //     } else {
  //       // Discount is not for 1
  //       amount = plan.amount_month;
  //     }
  //   } else {
  //     // Case when planType is true
  //     if (plan.discount_for == 2) {
  //       // Discount is for 1
  //       amount = plan.amount;
  //     } else {
  //       // Discount is not for 1
  //       amount = plan.amount_year;
  //     }
  //   }

  //   return amount;
  // };

  const getAmount = (planType: boolean, plan: any) => {
    let amount;

    if (planType === false) {
      amount = plan.amount_month;
    } else {
      amount = plan.amount_year;
    }

    if (plan.discount_type === 2) {
      if (
        (planType === false && plan.discount_for === 1) ||
        (planType === true && plan.discount_for === 2)
      ) {
        const discountAmount = (amount * plan.discount_amount) / 100;
        amount = amount - discountAmount;
      }
    } else if (plan.discount_type === 1) {
      if (
        (planType === false && plan.discount_for === 1) ||
        (planType === true && plan.discount_for === 2)
      ) {
        amount = amount - plan.discount_amount;
      }
    }

    return amount.toFixed(2);
  };

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-12">
            <div className="price-toggle aos" data-aos="fade-up">
              <ul className="nav">
                <li className="active">
                  Pay Monthly
                  {hoverPlan?.discount_amount == 0
                    ? null
                    : showAlert &&
                      planType === false &&
                      hoverPlan.discount_for == 1 && (
                        // <span>Save {hoverPlan.discount_amount}%</span>
                        <span>
                          Save{" "}
                          {hoverPlan.discount_type === 2
                            ? `${hoverPlan.discount_amount}%` // Percentage discount
                            : `${hoverPlan.currency}${hoverPlan.discount_amount}`}{" "}
                          {/* Flat value discount */}
                        </span>
                      )}
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="monthly"
                    className="check"
                    checked={planType}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="monthly" className="checktoggle">
                    checkbox
                  </label>
                </li>
                <li>
                  {/* <span className="yearlysave">Save 20%</span> */}
                  Pay Yearly
                  {hoverPlan?.discount_amount == 0
                    ? null
                    : showAlert &&
                      planType === true &&
                      hoverPlan.discount_for == 2 && (
                        <span>Save {hoverPlan.discount_amount}%</span>
                      )}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {plansList.map((plan: any) => {
            const mainAmount: any = getAmount(planType, plan);
            return (
              <div
                key={plan.plan_id}
                className="col-lg-4 col-md-6 d-flex aos"
                data-aos="fade-up"
              >
                <div
                  className={`pricing-card w-100 ${
                    plan.best_priceplan ? "best-plan-header" : ""
                  }`}
                  onMouseEnter={() => {
                    handleMouseEnter(plan);
                  }}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="pricing-plan-header">
                    {plan.best_priceplan && <h6>Best Plan</h6>}
                    <h5>{plan.plan_name}</h5>
                    <h4>
                      {plan.discount_amount != 0 && (
                        <span>{getoffer(planType, plan)}</span>
                      )}
                      {plan.currency}
                      {mainAmount}
                    </h4>
                    <span className="month-bill annually-bill">
                      {planType === true ? (
                        <span>/ Yearly</span>
                      ) : (
                        <span>/ Monthly</span>
                      )}
                    </span>
                    <Link
                      to=""
                      onClick={() => {
                        const data = { ...plan, mainAmount: mainAmount };
                        dispatch(setPlanData(data));
                        setLoginVisible(false);
                        setPaymentVisible(true);
                        localStorage.setItem(
                          "plandetailsdata",
                          JSON.stringify(data)
                        );
                      }}
                      className="btn btn-white"
                    >
                      Choose Plan <i className="feather icon-chevron-right" />
                    </Link>
                  </div>
                  <div className="pricing-content">
                    {/* <ul>
                      {plan.available_PlanFeatures.map((feature: any) => (
                        <li key={feature}>
                          <i className="fas fa-check" /> {feature}
                        </li>
                      ))}
                      {plan.notAvailable_PlanFeatures.map((feature: any) => (
                        <li key={feature}>
                          <i className="fas fa-xmark" /> {feature}
                        </li>
                      ))}
                    </ul> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Plans;
