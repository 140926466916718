import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginLogo } from "../../utils/imagepath";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ApiServiceContext } from "../../utils/shared.module";
import { end_points } from "../../services/end_point/end_points";
import {
  email,
  emailMaxLength,
  userNameRegex,
} from "../../utils/patterns/regex.pattern";
import { SpinnerContext } from "../../components/spinner/spinner";
import { toast } from "react-toastify";
import { routes } from "../../utils/routes";

const ResetPassword = () => {
  const { postData } = useContext(ApiServiceContext);

  const navigate = useNavigate();
  const findPath = window.location.pathname;
  const isAdmin = findPath.split("/")[1];

  const [passwordVisibility, setPasswordVisibility] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const passwordValidation = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .trim()
      .matches(email, "Please enter a valid email")
      .required("Email is required"),
    new_password: yup.string().required("Password is required"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("new_password")], "Passwords must match")
      .required("Confirm password is required"),
  });

  const {
    handleSubmit: changePasswordForm,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordValidation),
    defaultValues: {
      email: "",
      new_password: "",
      confirm_password: "",
    },
  });

  const togglePasswordVisibility = (type: "current" | "new" | "confirm") => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const submitchangePswdForm = async (data: any) => {
    try {
      let urls;
        if (isAdmin === 'super-admin') {
          urls = end_points.super_admin_forgotpwd.url;
        }else if (isAdmin === 'course-admin') {
          urls = end_points.course_forgotpwd.url;
        }else{
          urls = end_points.admin_forgotpwd.url;
        }
      const payload = {
        email: data?.email,
        new_password: data?.new_password,
        confirm_password: data?.confirm_password,
      };
      const response = await postData(urls, payload);
      if (response?.status === 200) {
        toast.success("Password reset successfully");
        if (isAdmin === 'super-admin') {
          navigate(routes.login.path);
        }else if (isAdmin === 'course-admin') {
          navigate(routes.courseLogin.path);
        }else{
          navigate(routes.AdminLogin.path);
        }
      }
    } catch (error) {
    }
  };

  return (
    <>
      {" "}
      <div className="forgot-page-wrapper">
        <div className="forgot-page-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="static-page">
                  <div className="row">
                    <div className="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3">
                      <div className="forgot-pwd-topper">
                        <div className="forgot-pwd text-center">
                          <img src={loginLogo} alt="img" />
                          <div className="forgot-password-inner">
                            <div className="forgot-password-title text-center">
                              <h5>Reset Password</h5>
                              <p>
                                Enter your email and we'll send you a link to
                                reset your password.
                              </p>
                            </div>
                            <div className="forgot-password-form mt-3">
                              <form
                                onSubmit={changePasswordForm(
                                  submitchangePswdForm
                                )}
                              >
                                <div className="row text-start">
                                  <div className="col-lg-12">
                                    <div className="mb-3">
                                      <label>
                                        Email{" "}
                                        <span className="manitary">*</span>
                                      </label>
                                      <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                          <input
                                            {...field}
                                            type="text"
                                            placeholder="Enter Email"
                                            className={`form-control ${
                                              errors.email ? "is-invalid" : ""
                                            }`}
                                            onBlur={() => trigger("email")}
                                            onChange={(event: any) => {
                                              const lowerCaseValue = event.target.value.toLowerCase();
                                              field.onChange(lowerCaseValue);
                                              trigger("email");
                                            }}
                                            maxLength={emailMaxLength}
                                          />
                                        )}
                                      />
                                      {errors.email && (
                                        <div className="invalid-feedback">
                                          {errors.email.message}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="mb-3">
                                      <label>
                                        New Password{" "}
                                        <span className="manitary">*</span>
                                      </label>
                                      <div
                                        className={`custom-reset ${
                                          errors.new_password
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                      >
                                        <div
                                          className={`pass-group ${
                                            errors.new_password
                                              ? "is-invalid is-invalid-pass-group"
                                              : ""
                                          }`}
                                        >
                                          <Controller
                                            name="new_password"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                              <>
                                                <input
                                                  type={
                                                    passwordVisibility.new
                                                      ? "text"
                                                      : "password"
                                                  }
                                                  className={`form-control ${
                                                    errors.new_password
                                                      ? "is-invalid"
                                                      : ""
                                                  }`}
                                                  placeholder="Enter New Password"
                                                  {...field}
                                                  onBlur={() =>
                                                    trigger("new_password")
                                                  }
                                                />
                                                <span
                                                  className={`ti toggle-passwords ${
                                                    passwordVisibility.new
                                                      ? "ti-eye"
                                                      : "ti-eye-off"
                                                  }`}
                                                  onClick={() =>
                                                    togglePasswordVisibility(
                                                      "new"
                                                    )
                                                  }
                                                />
                                              </>
                                            )}
                                          />
                                        </div>
                                        {errors.new_password && (
                                          <div className="invalid-feedback reset-custom">
                                            {errors.new_password.message}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="mb-3">
                                      <label>
                                        Confirm Password{" "}
                                        <span className="manitary">*</span>
                                      </label>
                                      <div
                                        className={`custom-reset ${
                                          errors.confirm_password
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                      >
                                        <div
                                          className={`pass-group ${
                                            errors.confirm_password
                                              ? "is-invalid is-invalid-pass-group"
                                              : ""
                                          }`}
                                        >
                                          <Controller
                                            name="confirm_password"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                              <>
                                                <input
                                                  type={
                                                    passwordVisibility.confirm
                                                      ? "text"
                                                      : "password"
                                                  }
                                                  className={`form-control ${
                                                    errors.confirm_password
                                                      ? "is-invalid"
                                                      : ""
                                                  }`}
                                                  placeholder="Enter Confirm Password"
                                                  {...field}
                                                  onBlur={() =>
                                                    trigger("confirm_password")
                                                  }
                                                />
                                                <span
                                                  className={`ti toggle-password-new ${
                                                    passwordVisibility.confirm
                                                      ? "ti-eye"
                                                      : "ti-eye-off"
                                                  }`}
                                                  onClick={() =>
                                                    togglePasswordVisibility(
                                                      "confirm"
                                                    )
                                                  }
                                                />
                                              </>
                                            )}
                                          />
                                        </div>
                                        {errors.confirm_password && (
                                          <div className="invalid-feedback reset-custom">
                                            {errors.confirm_password.message}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn submit-btn btn-hvr w-100"
                                >
                                  Submit
                                </button>
                                {isAdmin === "super-admin" ? (
                                  <Link to={routes.login.path}>
                                    <i className="fa-solid fa-chevron-left" />{" "}
                                    Back to Login
                                  </Link>
                                ) : isAdmin === "course-admin" ? (
                                  <Link to={routes.courseLogin.path}>
                                    <i className="fa-solid fa-chevron-left" />{" "}
                                    Back to Login
                                  </Link>
                                ) : (
                                  <Link to={routes.adminLogin.path}>
                                    <i className="fa-solid fa-chevron-left" />{" "}
                                    Back to Login
                                  </Link>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
