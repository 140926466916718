import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import AddQuiz from "./add-quiz";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import PrimeDataTable from "../../../../components/data-table";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import "yet-another-react-lightbox/styles.css";
import { ConfirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { setEditData } from "../../../../core/redux/commonSlice";
import { formatDate } from "../../../../utils/patterns/regex.pattern";

const Quiz = () => {
  const { getData, putData } = useContext(ApiServiceContext);
  const userDetails = useSelector((state: any) => state.login.userDetails);
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [courseList, setCourseList] = useState([]);
  const location = useLocation();
  const { id, isEditOpen } = location.state || {};

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.list_quiz.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}&courseadminid=${userDetails?.courseadmin_id}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.quiz);
        setCount(response?.data?.totalCount);
        setTotalRecords(response?.data?.totalCount);
        setCount(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);
  const [selectedTutorial, setSelectedTutorial] = useState<any>(null);

  const handleEditTutorial = (data: any) => {
    dispatch(setEditData(data));
  };

  const [visible, setVisible] = useState(false);

  const deletePlan = async (planId: number) => {
    try {
      let url = end_points.delete_quiz.url;
      url += `?id=${planId}&deleted_by=${userDetails?.courseadmin_id}`;
      const response = await putData(url);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        getList();
      }
    } catch (error) {}
  };

  const accept = () => {
    if (selectedTutorial) {
      deletePlan(selectedTutorial.quiz_id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (plan: any) => {
    setSelectedTutorial(plan);
    setVisible(true);
  };

  const columns = [
    {
      header: "ID",
      field: "quiz_id",
      key: "quiz_id",
      sortField: "quiz_id",
    },
    {
      header: "Quiz Title",
      field: "quiz_title",
      key: "quiz_title",
      sortField: "quiz_title",
    },
    {
      header: "Course Name",
      field: "categoryName",
      key: "categoryName",
      sortField: "categoryName",
    },
    {
      header: "Descriptions",
      field: "quiz_desc",
      key: "quiz_desc",
      sortField: "quiz_desc",
      body: (data: any) => (
        <span>
          {data.quiz_desc?.length > 50
            ? `${data.quiz_desc?.slice(0, 50)}...`
            : data.quiz_desc}
        </span>
      ),
    },
    {
      header: "Quiz",
      field: "questionCount",
      key: "questionCount",
      sortField: "questionCount",
      body: (data: any) => (
        <span className="badge status-todo">{data.questionCount}</span>
      ),
    },
    {
      header: "Created Date",
      field: "created_at",
      key: "created_at",
      sortField: "created_at",
      body: (data: any) => <span>{formatDate(data.created_at)}</span>,
    },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortField: "status",
      body: (data: any) => (
        <div className="toggles">
          <label className="switch">
            <input
              type="checkbox"
              id="togBtn"
              checked={data.status === 1 ? true : false}
              onChange={(event) => handleToggle(event, data.quiz_id)}
            />
            <div className="slider round">
              <span className="on">
                <i className="ti ti-check" />
              </span>
              <span className="off">
                <i className="ti ti-x" />
              </span>
            </div>
          </label>
        </div>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (item: any) => {
        return (
          <>
            {" "}
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  to={routes.quizDetails.path}
                  state={{ id: item?.quiz_id }}
                  className="dropdown-item"
                >
                  <i className="feather icon-eye" /> View
                </Link>
                <Link
                  onClick={() => {
                    handleEditTutorial(item);
                    handleOffcanvas();
                  }}
                  to="#"
                  className="dropdown-item"
                >
                  <i className="feather icon-edit" /> Edit
                </Link>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => handleDeleteClick(item)}
                >
                  <i className="feather icon-trash-2" /> Delete
                </Link>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const quiz_id = row;
    try {
      const payload = {
        id: quiz_id,
        isActive: isChecked,
      };
      let url = end_points.update_status_quiz.url;
      const response = await putData(url, payload);
      if (response.status === 200) {
        setListData((prevData: any) =>
          prevData.map((row: any) =>
            row.quiz_id === quiz_id ? { ...row, status: isChecked } : row
          )
        );
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };
  const dispatch = useDispatch();

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  const getCourseList = async () => {
    try {
      let url = end_points.quiz_category.url;
      url += `?courseAdminId=${userDetails?.courseadmin_id}`;
      const response = await getData(url);
      if (response.status === 200) {
        const data = response?.data?.data?.quiz?.map((item: any) => {
          return {
            label: item?.course_name,
            value: item?.quiz_typeid,
            course_id: item?.course_id,
          };
        });
        setCourseList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCourseList();
  }, [offcanvas]);

  useEffect(() => {
    if (id && isEditOpen) {
      const item = listData?.find((list: any) => list?.quiz_id === id);
      handleEditTutorial(item);
      handleOffcanvas();
    }
  }, [id, isEditOpen, listData]);

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Quiz</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.courseDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Quiz</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Dashboard */}
          <div className="dashboard-info">
            <div className="row">
              <div className="col-xl-12">
                <div className="dashboard-header">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h4>Total Quiz ({count})</h4>
                    </div>
                    <div className="col-md-6">
                      <div className="dashboard-nav-list d-flex flex-wrap justify-content-end align-items-center">
                        <Link
                          onClick={handleOffcanvas}
                          to="#"
                          className="btn btn-add btn-hvr truck-item"
                        >
                          <i className="ti ti-circle-plus" /> Add Quiz
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card table-card">
                  <div className="card-body">
                    <div className="table-responsive prime-table">
                      <PrimeDataTable
                        setSearchQuery={setSearchQuery}
                        column={columns}
                        data={listData}
                        rows={rows}
                        setRows={setRows}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalRecords={totalRecords}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Dashboard */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {offcanvas && <AddQuiz successcall={getList} courseList={courseList} />}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this quiz?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
        draggable={false}
      />
    </>
  );
};

export default Quiz;
