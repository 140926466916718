import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { end_points } from "../../../../services/end_point/end_points";
import { useSelector } from "react-redux";
import { ApiServiceContext, routes } from "../../../../utils/shared.module";
import { toast } from "react-toastify";

const QuizDetails = () => {
  const userDetails: any = useSelector((state: any) => state.login.userDetails);
  const { getData, putData } = useContext(ApiServiceContext);
  const location = useLocation();
  const [editValue, setEditValue] = useState<any>({});
  const [questions, setQuestions] = useState<any>([]);
  const { id } = location.state || {};
  const [quizId, setQuizId] = useState<any>();
  const navigate = useNavigate();

  const getEditValue = async () => {
    try {
      let url = end_points.get_quiz.url;
      if (id) {
        url += `?quiz_id=${id}&courseadmin_id=${userDetails?.courseadmin_id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        const data = response?.data?.data?.quiz;
        setEditValue(data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const deletePlan = async () => {
    try {
      let url = end_points.delete_quiz.url;
      url += `?id=${quizId}&deleted_by=${userDetails?.courseadmin_id}`;
      const response = await putData(url);
      if (response.status === 200) {
        toast.success(response?.data?.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      getEditValue();
      setQuizId(id);
    } else {
      navigate(routes.quiz.path);
    }
  }, [id]);

  useEffect(() => {
    if (Object.keys(editValue)?.length > 0) {
      const data = JSON.parse(editValue?.quiz_questions);
      const updatedData = data.map((item: any) => {
        const newAnswers = item.Answers.map((answer: any) => ({
          answer,
          is_correct: answer === item.CorrectAnswer,
        }));
        return { ...item, Answers: newAnswers };
      });
      setQuestions(updatedData);
    }
  }, [editValue]);

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Quiz</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.courseDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.quiz.path} className="text-black">
                      Quiz
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{editValue?.quiz_refid}</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-lg-12 col-12">
              {/* Quiz Preview */}
              <div className="bg-white quiz-list">
                {/* Title */}
                <div className="quiz-list-title">
                  <div className="quiz-title-info">
                    <h4>
                      {editValue?.quiz_title} ({questions?.length})
                    </h4>
                    <p style={{ wordBreak: "break-all" }}>
                      {editValue?.quiz_desc}
                    </p>
                  </div>
                  <div className="editable-quiz">
                    <Link
                      to={routes.quiz.path}
                      state={{ id: quizId, isEditOpen: true }}
                      className="edit-quiz"
                    >
                      <i className="ti ti-edit" />
                      Edit{" "}
                    </Link>
                    <Link to="#" className="delete-quiz" onClick={deletePlan}>
                      <i className="ti ti-trash" />
                      Delete
                    </Link>
                  </div>
                </div>
                {/* Title */}
                {/* Quiz List */}
                <div className="added-quiz-list">
                  {/* Quiz List Items */}
                  <div className="accordion" id="accordionquiz">
                    {/* Quiz One */}
                    {questions?.map((ques: any, index: any) => {
                      return (
                        <div className="accordion-item quiz-item" key={index}>
                          <div className="accordion-header quiz-item-header">
                            <div
                              className="accordion-button collapsed"
                              // type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#quiz-${index}`}
                              aria-expanded="false"
                              aria-controls={`quiz-${index}`}
                            >
                              <div>
                                <p>Q{index + 1}</p>
                              </div>
                              <h5>{ques?.Question}</h5>
                            </div>
                          </div>
                          <div
                            id={`quiz-${index}`}
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionquiz"
                          >
                            <div className="accordion-body quiz-item-body">
                              {/* Quiz Option list */}
                              <div className="quiz-answer-list">
                                {ques?.Answers?.map((ans: any, aIndex: any) => (
                                  <div
                                    className="quizAnswer-list-content"
                                    key={aIndex}
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id={`quizAnswerList${aIndex}`}
                                      name={`quizAnswerList${aIndex}`}
                                      checked={ans?.is_correct}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="quizAnswerList1"
                                    >
                                      {ans?.answer}
                                    </label>
                                  </div>
                                ))}
                              </div>
                              {/* Quiz Option list */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* Quiz One */}
                  </div>
                  {/* Quiz List Items */}
                </div>
                {/* Quiz List */}
              </div>
              {/* Quiz Preview */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizDetails;
