import React from "react";

const FleetManagerTerms: React.FC<any> = (props) => <>
    <div className="terms-and-condition-content">
        <h5 className="terms-of-service">Terms of Service (Fleet)</h5>
        <p className="terms-of-service-content">Effective Date: September 20, 2024</p>
        <p className="terms-of-service-content">Welcome to CARAi</p>
        <p className="terms-of-service-content">Welcome to the CARAi app! We’re thrilled to have you join our community of fleet managers. CARAi is designed to simplify your trucking experience, allowing you to handle truck assignments, track inspections, and schedule repairs effortlessly.</p>

        <h5 className="terms-of-service">1. Acceptance of Terms</h5>
        <p className="terms-of-service-content">By accessing or using Core Four Production's SaaS fleet management application, CARAi, (the “Service”), you agree to comply with and be bound by these Terms of Service (“Terms”). If you do not agree to these Terms, you may not use the Service.</p>

        <h5 className="terms-of-service">2. Description of Service</h5>
        <p className="terms-of-service-content">Core Four Productions, Corp. provides a fleet management application that allows users to manage and track their fleet of vehicles. The Service includes features such as vehicle tracking, maintenance scheduling, and reporting.</p>

        <h5 className="terms-of-service">3. User Accounts</h5>
        <p className="terms-of-service-content"><strong>Registration:</strong> To use certain features of the Service, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
        <p className="terms-of-service-content"><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account login information and are fully responsible for all activities that occur under your account. You agree to immediately notify us of any unauthorized use, or suspected unauthorized use of your account or any other breach of security.</p>

        <h5 className="terms-of-service">4. Use of the Service</h5>
        <p className="terms-of-service-content"><strong>Permitted Use:</strong> You agree to use the Service only for lawful purposes and in accordance with these Terms.</p>
        <p className="terms-of-service-content"><strong>Prohibited Use:</strong> You agree not to use the Service:</p>
        <ul className="terms-of-service-list">
            <li>In any way that violates any applicable federal, state, local, or international law or regulation.</li>
            <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
            <li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation.</li>
            <li>To impersonate or attempt to impersonate Core Four Productions, Corp., a Core Four Productions, Corp. employee, another user, or any other person or entity.</li>
        </ul>

        <h5 className="terms-of-service">5. Fees and Payment</h5>
        <p className="terms-of-service-content"><strong>Subscription Fees:</strong> Features of the Service may require you to pay subscription fees. You agree to pay all applicable fees in connection with your use of the Service.</p>
        <p className="terms-of-service-content"><strong>Payment Terms:</strong> All fees are due in advance and are non-refundable. We reserve the right to change our fees at any time.</p>

        <h5 className="terms-of-service">6. Intellectual Property</h5>
        <p className="terms-of-service-content"><strong>Ownership:</strong> The Service and its entire contents, features, and functionality are owned by Core Four Productions, Corp., its licensors, or other providers of such material and are protected by copyright, trademark, patent, trade secret, and other intellectual property laws.</p>
        <p className="terms-of-service-content"><strong>License:</strong> Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable license to access and use the Service for your personal or internal business purposes.</p>

        <h5 className="terms-of-service">7. Termination</h5>
        <p className="terms-of-service-content">We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of these Terms.</p>

        <h5 className="terms-of-service">8. Limitation of Liability</h5>
        <p className="terms-of-service-content">In no event shall Core Four Productions, Corp., nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses, or the like transmitted to or through our Service by any third party; (v) any errors or omissions in any content posted, emailed, transmitted, or otherwise made available through the Service; and/or (vi) the defamatory, offensive, or illegal conduct of any third party.</p>

        <h5 className="terms-of-service">9. Governing Law</h5>
        <p className="terms-of-service-content">These Terms shall be governed and construed in accordance with the laws of the United States or European Union, without regard to conflict of law provisions.</p>

        <h5 className="terms-of-service">10. Changes to Terms</h5>
        <p className="terms-of-service-content">We reserve the right to modify or replace these Terms at our sole discretion. If a revision is material, we will provide at least 30 days’ notice prior to any new terms taking effect.</p>

        <h5 className="terms-of-service">11. Contact Us</h5>
        <p className="terms-of-service-content">If you have any questions about these Terms, please contact us at:</p>
        <ul className="terms-of-service-list">
            <li>Email: info@core4productions.com</li>
            <li>Address: 405 TX-121 Lewisville, TX 75067</li>
        </ul></div>
</>
export default FleetManagerTerms;