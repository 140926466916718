import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import AddProduct from "./add-product";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import PrimeDataTable from "../../../../components/data-table";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { ConfirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { setEditData } from "../../../../core/redux/commonSlice";
import { formatDate } from "../../../../utils/patterns/regex.pattern";

const Product = () => {
  const { getData, putData } = useContext(ApiServiceContext);
  const userDetails = useSelector((state: any) => state.login.userDetails);
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [categoryList, setCategoryList] = useState([]);
  const [type, setType] = useState("");

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.product_list.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}&courseadminid=${userDetails?.courseadmin_id}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.product);
        setCount(response?.data?.totalCount);
        setTotalRecords(response?.data?.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState<any>(null);

  const handleEditTutorial = (data: any) => {
    dispatch(setEditData(data));
  };

  const [visible, setVisible] = useState(false);

  const deletePlan = async (planId: number) => {
    try {
      let url = end_points.delete_product.url;
      url += `?id=${planId}&deleted_by=${userDetails?.courseadmin_id}`;
      const response = await putData(url);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        getList();
      }
    } catch (error) {}
  };

  const accept = () => {
    if (selectedTutorial) {
      deletePlan(selectedTutorial.prod_id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (plan: any) => {
    setSelectedTutorial(plan);
    setVisible(true);
  };

  const columns = [
    {
      header: "ID",
      field: "prod_id",
      key: "prod_id",
      sortField: "prod_id",
    },
    {
      header: "Product Title",
      field: "prod_title",
      key: "prod_title",
      sortField: "prod_title",
    },
    {
      header: "Category",
      field: "prod_typename",
      key: "prod_typename",
      sortField: "prod_typename",
    },
    {
      header: "Email",
      field: "contact_email",
      key: "contact_email",
      sortField: "contact_email",
    },
    {
      header: "Contact No",
      field: "contact_number",
      key: "contact_number",
      sortField: "contact_number",
    },
    {
      header: "Date",
      field: "created_at",
      key: "created_at",
      sortField: "created_at",
      body: (data: any) => <span>{formatDate(data.created_at)}</span>,
    },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortField: "status",
      body: (data: any) => (
        <div className="toggles">
          <label className="switch">
            <input
              type="checkbox"
              id="togBtn"
              checked={data.status === 1 ? true : false}
              onChange={(event) => handleToggle(event, data.prod_id)}
            />
            <div className="slider round">
              <span className="on">
                <i className="ti ti-check" />
              </span>
              <span className="off">
                <i className="ti ti-x" />
              </span>
            </div>
          </label>
        </div>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (item: any) => {
        return (
          <>
            {" "}
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => {
                    handleOffcanvas();
                    handleEditTutorial(item);
                    setType("View");
                  }}
                >
                  <i className="feather icon-eye" /> View
                </Link>
                <Link
                  onClick={() => {
                    handleEditTutorial(item);
                    handleOffcanvas();
                    setType("Edit");
                  }}
                  to="#"
                  className="dropdown-item"
                >
                  <i className="feather icon-edit" /> Edit
                </Link>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => handleDeleteClick(item)}
                >
                  <i className="feather icon-trash-2" /> Delete
                </Link>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const quiz_id = row;
    try {
      const payload = {
        id: quiz_id,
        isActive: isChecked,
      };
      let url = end_points.update_product_status.url;
      const response = await putData(url, payload);
      if (response.status === 200) {
        setListData((prevData: any) =>
          prevData.map((row: any) =>
            row.prod_id === quiz_id ? { ...row, status: isChecked } : row
          )
        );
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };
  const dispatch = useDispatch();

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  const getCourseList = async () => {
    try {
      let url = end_points.category_list.url;
      const response = await getData(url);
      if (response.status === 200) {
        const data = response?.data?.data?.product_category?.map(
          (item: any) => {
            return { label: item?.prod_type, value: item?.prod_typeid };
          }
        );
        setCategoryList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCourseList();
  }, []);

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Product</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.courseDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Product</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Dashboard */}
          <div className="dashboard-info">
            <div className="row">
              <div className="col-xl-12">
                <div className="dashboard-header">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h4>Product ({count})</h4>
                    </div>
                    <div className="col-md-6">
                      <div className="dashboard-nav-list d-flex flex-wrap justify-content-end align-items-center">
                        <Link
                          onClick={handleOffcanvas}
                          to="#"
                          className="btn btn-add btn-hvr truck-item"
                        >
                          <i className="ti ti-circle-plus" /> Add Product
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card table-card">
                  <div className="card-body">
                    <div className="table-responsive prime-table">
                      <PrimeDataTable
                        setSearchQuery={setSearchQuery}
                        column={columns}
                        data={listData}
                        rows={rows}
                        setRows={setRows}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalRecords={totalRecords}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Dashboard */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {offcanvas && (
        <AddProduct
          successcall={getList}
          categoryList={categoryList}
          type={type}
          setType={setType}
        />
      )}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this product?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
        draggable={false}
      />
    </>
  );
};

export default Product;
