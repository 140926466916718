import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../utils/routes";
import { end_points } from "../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../services/api/api.service";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { avatar02 } from "../../../utils/imagepath";
import { formatDateTime } from "../../../utils/patterns/regex.pattern";

const Notifications = () => {
  const { getData, putData } = useContext(ApiServiceContext);
  const userDetails: any = useSelector((state: any) => state.login.userDetails);
  const [listData, setListData] = useState<any[]>([]);
  const getNotifications = async () => {
    try {
      let url =
        userDetails?.cour_type === "super_admin"
          ? end_points.get_super_notification.url
          : userDetails?.cour_type === "Course" || "Product"
          ? end_points.get_course_notification.url
          : end_points.get_notification.url;
      if (userDetails?.user_id) {
        url += `/${userDetails?.user_id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        const data = response?.data?.data?.notification;
        setListData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const readNotifications = async () => {
    try {
      let url =
        userDetails?.cour_type === "super_admin"
          ? end_points.read_super_notification.url
          : userDetails?.cour_type === "Course" || "Product"
          ? end_points.read_course_notification.url
          : end_points.read_notification.url;
      if (userDetails?.id) {
        url += `?id=${userDetails?.id}`;
      }
      const response = await putData(url);
      toast.success(response?.data?.response?.responseMessage);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNotifications();
  }, [userDetails]);

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Notification</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.dashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Notification</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Dashboard */}
          <div className="dashboard-info">
            <div className="row">
              <div className="col-lg-12">
                <div className="notification-grid notification-grid-pg">
                  <div className="notification-head">
                    <div className="notification-head-left">
                      <h5>
                        You have{" "}
                        {listData?.filter((item: any) => item?.is_read)?.length}{" "}
                        Unread Notifications{" "}
                        <Link
                          to="#"
                          onClick={readNotifications}
                          className="fclrblue ms-2"
                        >
                          Mark all as read
                        </Link>
                      </h5>
                    </div>
                    {/* <div className="notification-head-right">
                      <input
                        type="text"
                        placeholder="Search Notifications..."
                        className="no-border"
                      />
                    </div> */}
                  </div>
                  <div className="notification-body-pg">
                    {listData?.length > 0 ? (
                      listData?.map((item: any, index: any) => (
                        <div
                          className={`notification-list ${
                            item?.is_read ? "active" : ""
                          }`}
                          key={index}
                        >
                          <div className="notification-left">
                            <div className="notification-avatar">
                              <img
                                src={
                                  item?.profileimage
                                    ? item?.profileimage
                                    : avatar02
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="notification-right notification-right-pg">
                            <div className="notification-pg-info">
                              <div className="notification-grid-row-hdr">
                                <h5>{item?.firstName}</h5>
                              </div>
                              <div className="notification-grid-row-content">
                                <p>{item?.notif_message}</p>
                              </div>
                            </div>
                            <div className="notification-pg-date">
                              <div className="notification-grid-row-footer">
                                <span className="text-muted">
                                  {formatDateTime(item?.notif_date)}
                                </span>
                              </div>
                              {item?.is_read && (
                                <span className="noti-count">1</span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Dashboard */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Notifications;
