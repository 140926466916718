import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../utils/shared.module";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

const Settings = () => {
  const { getData, putData } = useContext(ApiServiceContext);
  const [paymentList, setPaymentList] = useState<any>([]);
  const validationSchema = Yup.object().shape({
    sandbox_client: Yup.string().trim().optional(),
    sandbox_secret: Yup.string().trim().optional(),
    live_client: Yup.string().trim().optional(),
    live_secret: Yup.string().trim().optional(),
  });
  const { control, handleSubmit, setValue, trigger } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      sandbox_client: "",
      sandbox_secret: "",
      live_client: "",
      live_secret: "",
    },
  });

  const getPayment = async () => {
    try {
      let urls = end_points.get_payment.url;

      const response = await getData(urls);
      if (response?.status === 200) {
        setPaymentList(response?.data?.data?.[0]);
      }
    } catch (error: any) {}
  };

  const onSubmit = async (data: any) => {
    try {
      const payload = {
        id: paymentList?.id,
        stripe_keylive: data?.live_secret,
        client_keylive: data?.live_client,
        stripe_keytest: data?.sandbox_secret,
        client_keytest: data?.sandbox_client,
      };
      const response = await putData(end_points?.update_payment?.url, payload);
      if (response) {
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getPayment();
  }, []);
  useEffect(() => {
    setValue("sandbox_client", paymentList?.client_keytest);
    setValue("sandbox_secret", paymentList?.stripe_keytest);
    setValue("live_client", paymentList?.client_keylive);
    setValue("live_secret", paymentList?.stripe_keylive);
  }, [paymentList, setValue]);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <h3 className="page-title">API Integrations</h3>
            </div>
          </div>
        </div>
        <div className="truck-information">
          <div className="truck-information-grid mb-3">
            {/* <div className="page-header">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h6 className="page-title" style={{ fontSize: "20px" }}>
                    Sandbox key
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label>Client ID</label>
                  <Controller
                    name="sandbox_client"
                    control={control}
                    render={({ field }: any) => (
                      <input
                        {...field}
                        type="text"
                        className="form-control"
                        placeholder="Enter Client ID"
                        onChange={(e) => {
                          field.onChange(e);
                          trigger("sandbox_client");
                        }}
                        onBlur={() => trigger("sandbox_client")}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label>Secret key</label>
                  <Controller
                    name="sandbox_secret"
                    control={control}
                    render={({ field }: any) => (
                      <input
                        {...field}
                        type="text"
                        className="form-control"
                        placeholder="Enter Secret key"
                        onChange={(e) => {
                          field.onChange(e);
                          trigger("sandbox_secret");
                        }}
                        onBlur={() => trigger("sandbox_secret")}
                      />
                    )}
                  />
                </div>
              </div>
            </div> */}
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h6 className="page-title" style={{ fontSize: "20px" }}>
                    Live key
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label>Client ID</label>
                  <Controller
                    name="live_client"
                    control={control}
                    render={({ field }: any) => (
                      <input
                        {...field}
                        type="text"
                        className="form-control"
                        placeholder="Enter Client ID"
                        onChange={(e) => {
                          field.onChange(e);
                          trigger("live_client");
                        }}
                        onBlur={() => trigger("live_client")}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label>Secret key</label>
                  <Controller
                    name="live_secret"
                    control={control}
                    render={({ field }: any) => (
                      <input
                        {...field}
                        type="text"
                        className="form-control"
                        placeholder="Enter Secret key"
                        onChange={(e) => {
                          field.onChange(e);
                          trigger("live_secret");
                        }}
                        onBlur={() => trigger("live_secret")}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div
              className="card-footer canvas-btn tutorial-footer mt-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Link
                to="#"
                className="btn btn-add"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
