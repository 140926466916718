import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import PrimeDataTable from "../../../../components/data-table";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import OwnerDetails from './order-details'
import { setSelectedData } from "../../../../core/redux/commonSlice";
import { truck01 } from '../../../../utils/imagepath';

const OperatorList = ({
    setStatusCount = (data: any) => { },
    setCountData = (data: any) => { },
}) => {
    const { getData, putData } = useContext(ApiServiceContext);
    const [rows, setRows] = useState<number>(10);
    const [listData, setListData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(20);
    const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
    const [selectAll, setSelectAll] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const handleSelectAllChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const checked = event.target.checked;
        setSelectAll(checked);

        if (checked) {
            const allRowIds = new Set(listData.map((item) => item.id));
            setSelectedRows(allRowIds);
        } else {
            setSelectedRows(new Set());
        }
    };

    const handleRowCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        tutorialId: number
    ) => {
        const newSelectedRows = new Set(selectedRows);
        if (event.target.checked) {
            newSelectedRows.add(tutorialId);
        } else {
            newSelectedRows.delete(tutorialId);
            setSelectAll(false);
        }
        setSelectedRows(newSelectedRows);
    };

    const handleToggle = async (
        event: React.ChangeEvent<HTMLInputElement>,
        row: number
    ) => {
        const isChecked = event.target.checked ? 1 : 2;
        const company_id = row;
        try {
            const payload = {
                id: company_id,
                isActive: isChecked,
            };
            let url = end_points.update_companystatus.url;

            const response = await putData(url, payload);
            if (response.status === 200) {
                setStatusCount((prev: any) => !prev);
                setListData((prevData: any) =>
                    prevData.map((row: any) =>
                        row.id === company_id ? { ...row, status: isChecked } : row
                    )
                );
                toast.success(response?.data?.response?.responseMessage);
            }
        } catch (error) { }
    };

    const columns = [
        {
            header: "ID",
            field: "user_id",
            key: "user_id",
            sortField: "user_id",
        },  
        {
            header: "Username",
            body: (data: any) => (
                <div>
                    {data.firstname} {data.lastname || ''}
                </div>
            ),
        },
        {
            header: "Email",
            field: "email",
            key: "email",
            sortField: "email",
        },
        {
            header: "Phone Number",
            field: "phone",
            key: "phone",
            sortField: "phone",
        },
        {
            header: "Truck Details",
            body: (data: any) => (
                <>
                    <h2 className="table-avatar">
                        <Link to="#" className="avatar avatar-sm me-2">
                            <img
                                className="avatar-img rounded-circle"
                                src={data.profile_Image || truck01}
                                alt="Profile"
                                onError={(e) => {
                                    e.currentTarget.src = truck01; 
                                }}
                            />
                        </Link>
                        <h2 className="table-avatar1">
                            <span className="vehicle_name"><Link to="#">{data.vehicle_name}</Link></span>
                            <span className="license_plate"><Link to="#">{data.license_plate}</Link></span>
                        </h2>
                    </h2>
                </>
            )
        },
        {
            header: "Subscription Value",
            body: (data: any) => (
                <span className="text-color">{data.make || 'N/A'}</span>
            ),
        },
        {
            header: "",
            key: "action",
            field: "action",
            sortable: false,
            body: (data: any) => {
                return (
                    <div className="dropdown">
                        <Link
                            to="#"
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                        >
                            <i className="feather icon-more-vertical" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end">
                            <Link
                                to="#"
                                className="dropdown-item"
                                onClick={() => {
                                    handleOffcanvas();
                                    dispatch(setSelectedData(data));
                                }}
                            >
                                <i className="feather icon-eye" /> View
                            </Link>
                        </div>
                    </div>
                );
            },
        },
    ];

    const [count, setCount] = useState<number>(0);

    const getList = async (page: number = 1, limit: number = 10) => {
        try {
            let url = end_points.get_owneroperatorDetails.url;
            if (page) {
                url += `?PageNumber=${page}&PageSize=${limit}`;
            }
            if (searchQuery) {
                url += `&SearchTerm=${searchQuery}`;
            }

            const response = await getData(url);

            console.log(response);


            if (response.status === 200) {
                const ownerOperators = response?.data?.owneroperator;
                setListData(ownerOperators);
                setCount(ownerOperators.length);
                setTotalRecords(ownerOperators.length);
                setCount(1);
                setCountData(ownerOperators.length);
                console.log(response);

            }
        } catch (error) {
            console.error('Error fetching owner operator details:', error);
        }
    };

    useEffect(() => {
        getList(currentPage, rows);
    }, [currentPage, rows, searchQuery]);

    const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
    const dispatch = useDispatch();

    const handleOffcanvas = () => {
        dispatch(setAddCanvasSidebar(!offcanvas));
    };

    return (
        <>
            <div className="card table-card">
                <div className="card-body">
                    <div className="table-responsive prime-table">
                        <PrimeDataTable
                            setSearchQuery={setSearchQuery}
                            column={columns}
                            data={listData}
                            rows={rows}
                            setRows={setRows}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalRecords={totalRecords}
                        />
                    </div>
                </div>
            </div>
            <OwnerDetails />
        </>
    );
};

export default OperatorList;
