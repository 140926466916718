// RejectionCommentDialog.js

import React, { useState } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import TextArea from "antd/es/input/TextArea";

interface RejectionCommentDialogProps {
    visible: boolean;
    onHide: () => void;
    onSave: (comment: string) => void;
}

const RejectionCommentDialog: React.FC<RejectionCommentDialogProps> = ({ visible, onHide, onSave }) => {
    const [comment, setComment] = useState("");

    const handleCommentChange = (e: any) => {
        setComment(e.target.value);
    };

    const handleSave = () => {
        onSave(comment); // Call the onSave prop with the comment
        setComment(""); // Reset the comment input
    };

    return (
        <Dialog 
            header="Add Rejection Comment" 
            visible={visible} 
            style={{ width: '50vw' }} 
            onHide={onHide}
        >
            <div className="p-fluid">
                <div className="field">
                    <label htmlFor="comment">Description *</label>
                    <TextArea 
                        id="comment" 
                        value={comment} 
                        onChange={handleCommentChange} 
                        placeholder="Enter your rejection comment" 
                    />
                </div>
            </div>
            <div className="dialog-footer rejection-dialog-btns">
                <Button label="Submit" className="rejection-save" icon="pi pi-check" onClick={handleSave} />
                <Button label="Cancel" className="rejection-cancel" icon="pi pi-times" onClick={onHide} />
            </div>
        </Dialog>
    );
};

export default RejectionCommentDialog;
