import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import AddEditPricingPlan from "./add-edit-pricing-plan";

import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddCanvasSidebar,
  setEditCanvasSidebar,
} from "../../../../core/redux/sidebarSlice";
import { ConfirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { setEditData } from "../../../../core/redux/commonSlice";

const PricingPlan = () => {
  const { getData, putData, postData, deleteData } =
    useContext(ApiServiceContext);
  const [listData, setListData] = useState<any>([]);
  const [count, setCount] = useState<number>(0);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [visible, setVisible] = useState(false);

  const deletePlan = async (planId: number) => {
    const url = `${end_points.delete_plan.url}?id=${planId}`;

    if (planId) {
      try {
        const response = await putData(url);
        if (response?.status === 200) {
          toast.success(response?.data?.message);
          setVisible(false);
          getList();
        } else {
          toast.error("Failed to delete pricing plan");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An unexpected error occurred");
      }
    }
  };

  const accept = () => {
    if (selectedPlan) {
      deletePlan(selectedPlan);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.get_pricelist.url;

      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data?.prices);
        setCount(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditPlanClick = (data: any) => {
    dispatch(setEditData(data));
  };

  //toggle status
  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const Plan_id = row;
    try {
      const payload = {
        id: Plan_id,
        isActive: isChecked,
      };
      let url = end_points.update_planstatus.url;

      const response = await putData(url, payload);
      if (response.status === 200) {
        setListData((prevData: any) =>
          prevData.map((row: any) =>
            row.plan_id === Plan_id ? { ...row, status: isChecked } : row
          )
        );
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };

  const handleToggleBestPlan = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const plan_Id = row;
    try {
      const payload = {
        plan_Id,
      };
      let url = end_points.best_priceplan.url;

      const response = await postData(url, payload);
      if (response.status === 200) {
        getList();
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const editOffcanvas = useSelector(
    (state: any) => state.sidebar.editCanvasSidebar
  );
  useEffect(() => {
    getList();
  }, []);

  const dispatch = useDispatch();

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  const handleDeleteClick = (plan: any) => {
    setSelectedPlan(plan.plan_id);
    setVisible(true);
  };

  return (
    <>
      {/* Page Wrapper */}

      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <h3 className="page-title">Pricing Plans</h3>
            </div>
            <div className="col-sm-6">
              <ul className="breadcrumb">
                <li className="breadcrumb-item active">
                  <Link to={routes.dashboard.path}>Home</Link>
                </li>
                <li className="breadcrumb-item">Pricing Plans</li>
              </ul>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        {/* Dashboard */}
        <div className="dashboard-info">
          <div className="dashboard-header">
            <div className="row align-items-center">
              <div className="col-md-6 mb-3">
                <h4 className="mb-0 text-primarys">All Plans</h4>
                <p className="text-primarys">
                  Currently plans are active and if you want click to edit
                </p>
              </div>
              <div className="col-md-6">
                <div className="dashboard-nav-list">
                  <Link
                    to="#"
                    className="btn btn-add btn-hvr truck-item"
                    onClick={handleOffcanvas}
                  >
                    <i className="ti ti-circle-plus" /> Add New Plan
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {listData?.map((item: any, index: number) => (
              <div key={index} className="col-xl-12">
                <div className="pricing-plan">
                  <div className="pricing-plan-monthly">
                    <div className="d-flex align-items-center flex-wrap">
                      <h6>{item.plan_name} </h6>
                      <div className="toggles ms-2">
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="togBtn"
                            checked={item.status === 1}
                            onChange={(event) =>
                              handleToggle(event, item.plan_id)
                            }
                          />
                          <div className="slider round">
                            <span className="on">
                              <i className="ti ti-check" />
                            </span>
                            <span className="off">
                              <i className="ti ti-x" />
                            </span>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="pricing-plan-amount">
                      <h5>
                        <span>Month</span>
                        {item.currency}
                        {item.amount_month}
                      </h5>
                      <h5>
                        <span>Year</span>
                        {item.currency}
                        {item.amount_year}
                      </h5>
                    </div>
                  </div>
                  <div className="pricing-plan-para">
                    <p>{item.plan_description}</p>
                  </div>
                  <div className="pricing-plan-features">
                    <h6>Features:</h6>
                    <ul>
                      {item.available_PlanFeatures.map(
                        (feature: any, index: any) => (
                          <li key={index}>
                            <i className="ti ti-check me-1" />
                            {feature}
                          </li>
                        )
                      )}
                      {item.notAvailable_PlanFeatures.map(
                        (feature: any, index: any) => (
                          <li key={index}>
                            <i className="ti ti-x text-danger me-1" />
                            {feature}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div className="pricing-plan-btn pricing-plan-flex">
                    <div className="truck-sidebar-btns">
                      <Link
                        onClick={() => {
                          handleEditPlanClick(item);
                          handleOffcanvas();
                        }}
                        to="#"
                        className="btn btn-add btn-hvr"
                      >
                        Edit Plan
                      </Link>
                      <Link
                        to="#"
                        onClick={() => handleDeleteClick(item)}
                        className="btn btn-light btn-hvr"
                      >
                        Delete Plan
                      </Link>
                    </div>
                    <div className="pricing-plan-checkbox table-checkbox">
                      <label className="check-input">
                        <input
                          type="checkbox"
                          checked={item.best_priceplan || false}
                          onChange={(event) =>
                            handleToggleBestPlan(event, item.plan_id)
                          }
                        />
                        <span className="box" /> Mark as best plan
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* /Dashboard */}
      </div>
      {/* /Page Wrapper */}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this plan?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
        draggable={false}
      />
      {offcanvas && <AddEditPricingPlan successcall={getList} />}
    </>
  );
};

export default PricingPlan;
