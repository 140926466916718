// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-container {
    width: 100%;
    margin: 0 auto;
}

.invoice-tabs{
    margin: 60px 0 0 130px !important;
}

.tabs {
    display: flex;
    justify-content: flex-start;
    border-top: 2px solid #ccc;
    background: #FFF;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: #FFF;
    border-bottom: 2px solid transparent;
    font-size: 16px;
    color: #333;
}

.tab.active {
    border-bottom: 2px solid #194185;
    font-weight: bold;
    color: #194185;
}

.tab-content {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-top: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/feature-module/admin/invoice/Tabs.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,oCAAoC;IACpC,eAAe;IACf,WAAW;AACf;;AAEA;IACI,gCAAgC;IAChC,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".tabs-container {\n    width: 100%;\n    margin: 0 auto;\n}\n\n.invoice-tabs{\n    margin: 60px 0 0 130px !important;\n}\n\n.tabs {\n    display: flex;\n    justify-content: flex-start;\n    border-top: 2px solid #ccc;\n    background: #FFF;\n}\n\n.tab {\n    padding: 10px 20px;\n    cursor: pointer;\n    border: none;\n    background: #FFF;\n    border-bottom: 2px solid transparent;\n    font-size: 16px;\n    color: #333;\n}\n\n.tab.active {\n    border-bottom: 2px solid #194185;\n    font-weight: bold;\n    color: #194185;\n}\n\n.tab-content {\n    padding: 20px;\n    background-color: #f9f9f9;\n    border: 1px solid #ccc;\n    border-top: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
