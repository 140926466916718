import React, { useContext, useState, useEffect, useRef } from "react";
import { Calendar } from "primereact/calendar";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import PhoneInputWithRef from "../../../../utils/phoneInputWithRef";
import { clearEditData } from "../../../../core/redux/commonSlice";
import {
  email,
  nameMaxLength,
  emailMaxLength,
  validImageTypes,
  addressMaxLength,
  onlyNumber,
  DESCRIPTION_LENGTH,
} from "../../../../utils/patterns/regex.pattern";
import { Link } from "react-router-dom";
import parsePhoneNumberFromString from "libphonenumber-js";
import { validatePhoneNumber } from "../../../../utils/constant";
import { Dropdown } from "primereact/dropdown";

const validationSchema = Yup.object().shape({
  companyname: Yup.string().trim().required("Company Name is required"),
  fullname: Yup.string().trim().required("Full name is required"),
  cour_type: Yup.string().required("Course type is required").nullable(),
  email: Yup.string()
    .email("Please enter a valid email")
    .trim()
    .required("Email is required")
    .matches(email, "Please enter a valid email"),

  contact_number: Yup.string()
    .trim()
    .required("Contact number is required")
    .test("is-valid-phone", "Invalid phone number", (value) =>
      validatePhoneNumber(value)
    ),
  // dob: Yup.string().required("Date of birth is required"),
  address: Yup.string().trim().required("Address is required"),
  profile_image: Yup.mixed()
    .test("image.value", "Please upload an image", (value: any) => {
      if (value == "") {
        return false;
      } else return true;
    })
    .test(
      "image.type",
      "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
      (value: any) => {
        if (value == "") {
          return false;
        }
        if (!value || !value.length || typeof value === "string") {
          // Skip validation if the field is empty
          return true;
        }
        return value && validImageTypes?.includes(value[0]?.type);
      }
    )
    .test("fileSize", "File size is too large", (value: any) => {
      if (value == "") {
        return false;
      }
      if (!value || !value.length || typeof value === "string") {
        // Skip validation if the field is empty
        return true;
      }
      return value && value[0].size <= 4194304;
    })
    .required("Please upload an image!"),

  e_signature_image: Yup.mixed()
    .test("image.value", "Please upload an image", (value: any) => {
      if (value == "") {
        return false;
      } else return true;
    })
    .test(
      "image.type",
      "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
      (value: any) => {
        if (value == "") {
          return false;
        }
        if (!value || !value.length || typeof value === "string") {
          // Skip validation if the field is empty
          return true;
        }
        return value && validImageTypes?.includes(value[0]?.type);
      }
    )
    .test("fileSize", "File size is too large", (value: any) => {
      if (value == "") {
        return false;
      }
      if (!value || !value.length || typeof value === "string") {
        // Skip validation if the field is empty
        return true;
      }
      return value && value[0].size <= 4194304;
    })
    .required("E-signature is required"),
  logo: Yup.mixed()
    .test("image.value", "Please upload an image", (value: any) => {
      if (value == "") {
        return false;
      } else return true;
    })
    .test(
      "image.type",
      "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
      (value: any) => {
        if (value == "") {
          return false;
        }
        if (!value || !value.length || typeof value === "string") {
          // Skip validation if the field is empty
          return true;
        }
        return value && validImageTypes?.includes(value[0]?.type);
      }
    )
    .test("fileSize", "File size is too large", (value: any) => {
      if (value == "") {
        return false;
      }
      if (!value || !value.length || typeof value === "string") {
        // Skip validation if the field is empty
        return true;
      }
      return value && value[0].size <= 4194304;
    })
    .required("logo is required"),
  fees: Yup.string().required("Fees Deduction is required"),
  status: Yup.boolean(),
  course_description: Yup.string()
    .trim()
    .max(255, "Maximum 255 characters allowed")
    .required("About is required"),
});

const marketplaceOptions = [
  { label: "Course Admin", value: "1" },
  { label: "Product Admin", value: "2" },
];

const AddEditCourseAdmin = (props: any) => {
  const [date1, setDate1] = useState<Date | null>(null);
  const dropdownRef = useRef<any>(null);
  const login_user = useSelector((state: any) => state.login.userDetails.id);
  const data = useSelector((state: any) => state.common.editData);
  const courseAdmin_id = data?.courseadmin_id;
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [courseOptions, setCourseOptions] = useState([]); // State to store dropdown options
  const CourseType =
    data?.cour_type === 1
      ? "Course Admin"
      : data?.cour_type === 2
      ? "Product Admin"
      : "Select";
  type PreviewState = {
    image: string | null;
    fileName: string | null;
    fileSize: string | null;
  };

  type Esign = {
    image: string | null;
    fileName: string | null;
    fileSize: string | null;
  };

  const [preview, setPreview] = useState<PreviewState>({
    image: null,
    fileName: "",
    fileSize: "",
  });
  const [previewEsign, setPreviewEsign] = useState<Esign>({
    image: null,
    fileName: "",
    fileSize: "",
  });
  const [previewLogo, setPreviewLogo] = useState<Esign>({
    image: null,
    fileName: "",
    fileSize: "",
  });
  useEffect(() => {
    const handleScroll = () => {
      if (dropdownRef.current && "blur" in dropdownRef.current) {
        dropdownRef.current.blur();
      }
    };

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { postData, putData, getData } = useContext(ApiServiceContext);

  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      companyname: "",
      fullname: "",
      email: "",
      contact_number: "",
      address: "",
      profile_image: null,
      e_signature_image: null,
      fees: "",
      status: true,
      cour_type: "",
    },
  });

  console.log("data", data);

  useEffect(() => {
    if (courseAdmin_id) {
      // Set form values
      setValue("companyname", data.cour_companyname || "");
      setValue("cour_type", data?.cour_type || "");
      setValue("fullname", data.contact_personname || "");
      setValue("email", data.contact_personemail || "");
      setValue("contact_number", data.contact_personphone || "");
      // setValue("dob", new Date(data.dateofbirth) || null);
      setDate1(new Date(data.dateofbirth));
      setValue("address", data.address || "");
      setValue("status", data.status === 1 ? true : false);
      setValue("profile_image", data.profileImage || "");
      setValue("e_signature_image", data.profileSignature || "");
      setValue("logo", data.company_logo || "");
      setValue("fees", data.subscription_fee);
      setValue("course_description", data.course_description || "");

      // Handle profile image
      if (data.profileImage) {
        setPreview({
          image: data.profileImage,
          fileName: "",
          fileSize: "",
        });
      }

      // Handle e-signature image
      if (data.profileSignature) {
        setPreviewEsign({
          image: data.profileSignature,
          fileName: "",
          fileSize: "",
        });
      }
      if (data.company_logo) {
        setPreviewLogo({
          image: data.company_logo,
          fileName: "",
          fileSize: "",
        });
      }
    }
  }, [data, setValue]);

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
    dispatch(clearEditData());
    setPreviewEsign({});
    setPreview({});
    setPreviewLogo({});
  };

  const formatDate = (date: any) => {
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0"); // Get the day of the month
    const year = date.getFullYear(); // Get the full year

    return `${month}/${day}/${year}`;
  };

  const getCourseType = async () => {
    try {
      let url = end_points.get_TaxType.url; // Ensure correct URL
      const response = await getData(url);
      console.log("CourserDetailsResponse", response.data.data.course_type);

      if (response.status === 200 && response.data.data.course_type) {
        // Dynamically map all returned amount types to Dropdown options
        const fetchedCourseOptions = response.data.data.course_type.map(
          (type: any) => ({
            label: type.category_name, // Use amounttype_name for label
            value: type.category_name, // Use the name or id as the value, here converted to lowercase
          })
        );
        setCourseOptions(fetchedCourseOptions); // Set dropdown options
        console.log("taxTypeDetails", fetchedCourseOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmit = async (data: any) => {
    // const date = new Date(data.dob);
    // const formated_date = formatDate(date);
    const phoneNumber = parsePhoneNumberFromString(
      data.contact_number,
      selectedCountry.toUpperCase()
    );
    const courCompanyname = marketplaceOptions?.find(
      (item: any) => item?.value === data.cour_type
    )?.label;
    const formattedPhone = phoneNumber
      ? phoneNumber.formatInternational()
      : data.contact_number;
    try {
      const formData = new FormData();
      formData.append("company_name", data.companyname);
      formData.append("contact_personname", data.fullname);
      formData.append("contact_personemail", data.email);
      formData.append("contact_personphone", formattedPhone);
      // formData.append("dateofbirth", formated_date);
      formData.append("profile_Image", data.profile_image);
      formData.append("profile_signature", data.e_signature_image);
      formData.append("company_logo", data.logo);
      const statusValue = data.status ? "1" : "2";
      formData.append("status", statusValue);
      formData.append("address", data.address);
      formData.append("buyer_fee", data.fees);
      formData.append("created_by", login_user);
      formData.append("cour_type", data.cour_type);
      formData.append("cour_companyname", courCompanyname);
      formData.append("course_description", data.course_description);
      if (courseAdmin_id) {
        formData.append("courseadmin_id", courseAdmin_id);
      }
      const urls = courseAdmin_id
        ? end_points.update_courseAdmin.url
        : end_points.create_courseAdmin.url;
      const response = courseAdmin_id
        ? await putData(urls, formData)
        : await postData(urls, formData);
      if (response.status === 200) {
        toast.success(response?.data?.response?.responseMessage);
        reset();
        dispatch(setAddCanvasSidebar(!offcanvas));
        props.getList();
        dispatch(clearEditData());
      }
    } catch (e) {}
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setValue("profile_image", file, { shouldValidate: true });
      console.log("file", file);

      setPreview({
        image: URL.createObjectURL(file),
        fileName: file.name,
        fileSize: (file.size / 1024).toFixed(2), // File size in KB
      });
    }
  };

  const handleFileChangeEsign = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue("e_signature_image", file, { shouldValidate: true });
      setPreviewEsign({
        image: URL.createObjectURL(file),
        fileName: file.name,
        fileSize: (file.size / 1024).toFixed(2), // File size in KB
      });
    }
  };
  const handleFileChangeELogo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue("logo", file, { shouldValidate: true });
      setPreviewLogo({
        image: URL.createObjectURL(file),
        fileName: file.name,
        fileSize: (file.size / 1024).toFixed(2), // File size in KB
      });
    }
  };

  useEffect(() => {
    getCourseType();
  }, []);

  return (
    <div
      className={`truck-list-sidebar ${
        offcanvas ? "truck-list-sidebar-open" : ""
      }`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {courseAdmin_id ? "Edit" : "Add New"} Market place
            </h4>
            <Link to="#" className="clear-truck-list" onClick={handleOffcanvas}>
              <i className="fas fa-xmark"></i>
            </Link>
          </div>
          <div className="card-body">
            <div className="sidebar-sub-header">
              <h4>Market place</h4>
              <p>
                {!courseAdmin_id
                  ? "Please below details to create new Market place"
                  : ""}
              </p>
            </div>
            <div className="truck-information">
              <div className="truck-information-grid">
                <div className="row">
                  <div className="col-md-12">
                    <label>
                      Course Type <span className="text-danger">*</span>
                    </label>
                    {/* <Dropdown
                          name="cour_type"
                          options={[
                            { label: "Course Admin", value: "0" },
                            { label: "Product Admin", value: "1" },
                          ]}
                        /> */}
                    <Controller
                      name="cour_type"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          // Attach the ref to the Dropdown component
                          filter
                          value={courseAdmin_id ? data?.cour_type : field.value} // Ensure it's an object or null
                          onChange={(e) => {
                            field.onChange(e.value); // Pass the full object as value
                          }}
                          onBlur={() => trigger("cour_type")}
                          options={marketplaceOptions}
                          optionLabel="label" // Set option label correctly
                          placeholder={courseAdmin_id ? CourseType : "Select"}
                          className="w-100"
                          ref={dropdownRef}
                          disabled={courseAdmin_id ? true : false}
                        />
                      )}
                    />
                    {errors.cour_type && (
                      <small className="text-danger">
                        {errors.cour_type.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label>
                        Company Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="companyname"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className="form-control"
                            maxLength={nameMaxLength}
                            placeholder="Enter Company Name"
                            onKeyDown={(e) => {
                              if (
                                !/[a-zA-Z\s]/.test(e.key) &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("companyname");
                            }}
                            onBlur={() => trigger("companyname")}
                          />
                        )}
                      />
                      {errors.companyname && (
                        <small className="text-danger">
                          {errors.companyname.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label>
                        Full Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="fullname"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className="form-control"
                            maxLength={nameMaxLength}
                            placeholder="Enter Full Name"
                            onKeyDown={(e) => {
                              if (
                                !/[a-zA-Z\s]/.test(e.key) &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("fullname");
                            }}
                            onBlur={() => trigger("fullname")}
                          />
                        )}
                      />
                      {errors.fullname && (
                        <small className="text-danger">
                          {errors.fullname.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label>
                        Email <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            maxLength={emailMaxLength}
                            className="form-control"
                            placeholder="Enter Email"
                            onBlur={() => trigger("email")}
                            onChange={(e) => {
                              const lowerCaseValue =
                                e.target.value.toLowerCase();
                              field.onChange(lowerCaseValue);
                              trigger("email");
                            }}
                          />
                        )}
                      />
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label>
                        Contact Number <span className="text-danger">*</span>
                      </label>
                      <Controller
                        defaultValue=""
                        name="contact_number"
                        control={control}
                        render={({ field }) => (
                          <PhoneInputWithRef
                            country={"us"}
                            placeholder="Enter Contact Number"
                            {...field}
                            onChange={(value: string, country: any) => {
                              field.onChange(value);
                              setSelectedCountry(country.countryCode);
                              trigger("contact_number");
                            }}
                            onBlur={() => trigger("contact_number")}
                          />
                        )}
                      />
                      {errors.contact_number && (
                        <small className="text-danger">
                          {errors.contact_number.message}
                        </small>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                    <div className="form-wrap">
                      <label>
                        Date of Birth <span className="text-danger">*</span>
                      </label>
                      <div className="form-wrap-icon">
                        <Controller
                          name="dob"
                          control={control}
                          render={({ field }) => (
                            <Calendar
                              className="datetimepicker"
                              value={date1}
                              placeholder="MM/DD/YYYY"
                              maxDate={new Date()}
                              onChange={(e: any) => {
                                setDate1(e.value);
                                field.onChange(e.value);
                                trigger("dob");
                              }}
                              onBlur={() => trigger("dob")}
                            />
                          )}
                        />
                        <i className="ti ti-calendar-share" />
                      </div>
                      {errors.dob && (
                        <small className="text-danger">
                          {errors.dob.message}
                        </small>
                      )}
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label>
                        Address <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            maxLength={addressMaxLength}
                            className="form-control"
                            placeholder="Enter Full Address"
                            onBlur={() => trigger("address")}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("address");
                            }}
                          />
                        )}
                      />
                      {errors.address && (
                        <small className="text-danger">
                          {errors.address.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-wrap">
                      <div className="upload-info">
                        <label>
                          Profile Image <span className="text-danger">*</span>
                        </label>
                        <p>
                          <i className="feather icon-alert-circle" /> Maximum
                          File size 2MB &amp; PNG, JPEG, SVG
                        </p>
                        <label className="choose-file">
                          <input
                            type="file"
                            accept="image/jpeg, image/png, image/svg+xml, image/jpg"
                            onChange={(e) =>
                              handleFileChange(e, "profile_image")
                            }
                          />
                          <span>
                            <i className="feather icon-upload" /> Choose to File
                            Upload
                          </span>
                        </label>
                        {errors.profile_image && (
                          <small className="text-danger">
                            {errors.profile_image.message}
                          </small>
                        )}
                        {preview.image && (
                          <div
                            className={`upload-grid ${
                              courseAdmin_id ? "edit-upload" : ""
                            }`}
                          >
                            <div className="upload-img">
                              <img src={preview.image} alt="Profile" />
                            </div>
                            <div className="upload-text">
                              <h6>{preview.fileName}</h6>
                              <p>{preview.fileSize}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-wrap">
                      <div className="upload-info">
                        <label>
                          Upload Logo <span className="text-danger">*</span>
                        </label>
                        <p>
                          <i className="feather icon-alert-circle" /> Maximum
                          File size 2MB &amp; PNG, JPEG, SVG
                        </p>
                        <label className="choose-file">
                          <input
                            type="file"
                            accept="image/jpeg, image/png, image/svg+xml, image/jpg"
                            onChange={handleFileChangeELogo}
                          />
                          <span>
                            <i className="feather icon-upload" /> Choose to File
                            Upload
                          </span>
                        </label>
                        {errors.logo && (
                          <small className="text-danger">
                            {errors.logo.message}
                          </small>
                        )}

                        {previewLogo.image && (
                          <div className="upload-signature">
                            <img src={previewLogo.image} alt="Signature" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-wrap">
                      <div className="upload-info">
                        <label>
                          Upload E-Signature{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <p>
                          <i className="feather icon-alert-circle" /> Maximum
                          File size 2MB &amp; PNG, JPEG, SVG
                        </p>
                        <label className="choose-file">
                          <input
                            type="file"
                            accept="image/jpeg, image/png, image/svg+xml, image/jpg"
                            onChange={handleFileChangeEsign}
                          />
                          <span>
                            <i className="feather icon-upload" /> Choose to File
                            Upload
                          </span>
                        </label>
                        {errors.e_signature_image && (
                          <small className="text-danger">
                            {errors.e_signature_image.message}
                          </small>
                        )}

                        {previewEsign.image && (
                          <div className="upload-signature">
                            <img src={previewEsign.image} alt="Signature" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sidebar-sub-header">
                <h4>Subscription Fee</h4>
                <p>
                  You can set fee for course admin - all pricing decisions are
                  yours and yours only
                </p>
              </div>
              <div className="truck-information-grid">
                <div className="row">
                  <label>
                    Fees Deduction (Subscription Revenue){" "}
                    <span className="manitary">*</span>
                  </label>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <Controller
                        name="fees"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="$ 10"
                            {...field}
                            maxLength={5}
                            onChange={(event: any) => {
                              field.onChange(event);
                              trigger("fees");
                            }}
                            onBlur={() => trigger("fees")}
                            onKeyDown={(event) => {
                              const allowedKeys = [
                                "Backspace",
                                "Tab",
                                "ArrowLeft",
                                "ArrowRight",
                                "Delete",
                                "Enter",
                                ".",
                              ];

                              if (
                                event.key === "." &&
                                event.target.value.includes(".")
                              ) {
                                event.preventDefault();
                              }
                              if (
                                (event.key >= "0" && event.key <= "9") ||
                                allowedKeys.includes(event.key)
                              ) {
                                return;
                              }
                              event.preventDefault();
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={"Deduction from Course Admin"}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {errors.fees && (
                  <small className="text-danger">{errors.fees.message}</small>
                )}
              </div>
              <div className="truck-information-grid mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-2">
                      <label>
                        About <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="course_description"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <textarea
                            maxLength={DESCRIPTION_LENGTH}
                            className="form-control"
                            placeholder="Enter Description"
                            {...field}
                            onBlur={() => trigger("course_description")}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("course_description");
                            }}
                          />
                        )}
                      />
                      {errors.course_description && (
                        <small className="text-danger">
                          {errors.course_description.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <p className="text-muted">
                        <i className="ti ti-info-circle me-2" />
                        Maximum 255 Characters
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sidebar-switch">
                <h6>Status</h6>
                <div className="toggles">
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                        <div className="slider round">
                          <span className="on">
                            <i className="ti ti-check" />
                          </span>
                          <span className="off">
                            <i className="ti ti-x" />
                          </span>
                        </div>
                      </label>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer sidebar-btn">
            <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
              Cancel
            </Link>
            <button type="submit" className="btn btn-add">
              {courseAdmin_id ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditCourseAdmin;
