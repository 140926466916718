import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  truck06,
  truck02,
  signatureImg,
  noImg,
  notes,
  logoImg,
  logo,
  loginLogo,
} from "../../../../utils/imagepath";
import { routes } from "../../../../utils/routes";
import { RepairDetail } from "../../../../components/json/repair-detail";
import PrimeDataTable from "../../../../components/data-table";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { end_points } from "../../../../services/end_point/end_points";
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from "../../../../utils/shared.module";
import { Calendar } from "primereact/calendar";
import { Controller, useForm } from "react-hook-form";
import {
  convertToDate,
  DESCRIPTION_LENGTH,
} from "../../../../utils/patterns/regex.pattern";
import { toast } from "react-toastify";
import RejectionCommentDialog from "./rejection-comment";

declare global {
  interface Window {
    bootstrap: any;
  }
}

const InvoiceDetails = ({ isPageWrapper = true, divRef }: any) => {
  const [listData, setListData] = useState<any>([]);
  const [rows, setRows] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [repairTrackingData, setGeneratedInvoiceData] = useState<any[]>([]);
  const [repairViewData, setRepairViewData] = useState<any>({});
  const [status, setStatus] = useState<string>("");
  const [rejectionComments, setRejectionComments] = useState<string>("");
  const navigate = useNavigate();
  const { getData, putData } = useContext(ApiServiceContext);
  const role = useSelector((state: any) => state.login.role);
  const Data = useSelector((state: any) => state.common.generatedInvoiceData);
  const login_id = useSelector((state: any) => state.login.userDetails.id);
  const loginusername = useSelector(
    (state: any) => state.login.userDetails.name
  );
  const loginusereamil = useSelector(
    (state: any) => state.login.userDetails.email
  );
  const loginuserphone = useSelector(
    (state: any) => state.login.userDetails.phone_number
  );
  const modelRef = useRef<any>(null);
  const closeRef = useRef<any>(null);
  const [dialogVisible, setDialogVisible] = useState(false);


  const statusOptions = {
    paid: 1,
    unpaid: 2,
    approve: 3,
    // pending_approval : 4,
    rejected: 5,
  };

  const handleShowDialog = () => {
    setDialogVisible(true);
  };

  const handleHideDialog = () => {
    setDialogVisible(false);
  };

  const getInvoiceById = async () => {
    try {
      let url = end_points.get_InvoiceById.url;
      url += `?invoice_id=${Data.invoice_Id}`;
      const response = await getData(url);
      if (response.status === 200) {
        setRepairViewData(response?.data?.data?.invoice);
        setListData(response?.data?.repairComponents);
        setRepairStatus(response?.data?.repair_status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleStatusChange = async (e: {
    value: any;
    target: { value: any };
  }) => {
    const selectedValue = e.value || e.target.value;

    // Set the selected status in state
    setStatus(selectedValue);

    // Check if the selected status is 'rejected'
    if (selectedValue === String(statusOptions.rejected)) {
      // Show the rejection comment dialog
    } else {
      // Proceed with status update for statuses other than 'rejected'
      await updateStatus(selectedValue);
    }
  };

  const updateStatus = async (selectedValue: string, rejectionComment = "") => {
    const payload = {
      invoiceid: Data.invoice_Id,
      status: Number(selectedValue),
      rejection_comments:
        selectedValue === String(statusOptions.rejected)
          ? rejectionComment
          : "",
      // ...(selectedValue === String(statusOptions.rejected) && {
      //   rejection_comments: rejectionComment ? rejectionComment : "",
      // }),
    };

    try {
      const url = end_points.update_inspectionstatus.url;
      const response = await putData(url, payload);
      if (response.status === 200) {
        toast.success("Status updated successfully!");
        getInvoiceById();
      } else {
        toast.error("Failed to update status.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating the status.");
    }
  };

  // Updated handleSaveComment to update status with comment

  const handleSaveComment = async (comment: string | undefined) => {
    // Update status with rejection comment
    await updateStatus(String(statusOptions.rejected), comment);

    // Close the dialog after saving
    handleHideDialog();
  };

  useEffect(() => {
    getInvoiceById();
  }, []);

  const formatDate = (date: any) => {
    return new Date(date)
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-");
  };

  return (
    <>
      <div className={isPageWrapper ? "page-wrapper" : ""}>
        <div className="content container-fluid" ref={divRef}>
          {/* Page Header */}
          <div className={isPageWrapper ? "page-header" : "download-hide"}>
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">
                  Invoice ID - {Data.invoice_refid}
                </h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.invoiceList.path} className="text-black">
                      Invoices
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{Data.invoice_refid}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={isPageWrapper ? "page-header" : "download-hide"}>
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">
                  {/* Invoice ID - {Data.invoice_refid} */}
                </h3>
              </div>
              <div className="col-sm-6 invoice-dropdown-container">
                <div className="invoice-status-dropdown">
                  <Dropdown
                    options={Object.entries(statusOptions).map(
                      ([key, value]) => ({
                        label:
                          key.replace("_", " ").charAt(0).toUpperCase() +
                          key.slice(1),
                        value: String(value),
                      })
                    )}
                    className="invoice-action-dropdown"
                    value={status}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  />
                </div>
                <div className="invoice-status-action-btn">
                  <button
                    className="invoice-action-btn"
                    style={{
                      display:
                        status === String(statusOptions.rejected)
                          ? "block"
                          : "none",
                    }}
                    onClick={handleShowDialog}
                  >
                    Add Comments
                  </button>
                  <RejectionCommentDialog
                    visible={dialogVisible}
                    onHide={handleHideDialog}
                    onSave={handleSaveComment}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body card-bodybt">
                  <div className="invoice-grid">
                    <div className="invoice-hdr">
                      <div className="invoice-hdr-left">
                        <img src={loginLogo} alt="" />
                        <h4>INVOICE - {repairViewData?.invoice_refid}</h4>
                      </div>
                      <div className="invoice-hdr-right">
                        <div className="invoice-status-bg">
                          <strong>
                            Total Amount : ${repairViewData?.total}
                          </strong>
                          <div>
                            Status :{" "}
                            <span className="badge status-inprogress">
                              {repairViewData?.invoice_status}
                            </span>
                          </div>
                          <div>
                            Date :{" "}
                            {repairViewData?.invoice_date
                              ? formatDate(repairViewData.invoice_date)
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-row invoice-row-bb">
                      <div className="d-flex justify-content-between">
                        <div className="invoice-st">
                          Service Type -{" "}
                          <strong>{repairViewData?.service_type}</strong>
                        </div>
                        <div className="invoice-dd">
                          Due Date -{" "}
                          <strong>
                            {repairViewData?.due_date
                              ? formatDate(repairViewData.due_date)
                              : ""}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-row invoice-row-bb">
                      <div className="d-flex justify-content-between">
                        <div className="invoice-address">
                          <div className="invoice-ft">From</div>
                          <strong>{loginusername}</strong>
                          <p className="mb-0">Email : {loginusereamil}</p>
                          <p className="mb-0">
                            Phone Number : {loginuserphone}
                          </p>
                        </div>
                        <div className="invoice-address">
                          <div className="invoice-ft">To</div>
                          <strong>{repairViewData?.send_to_user_name}</strong>
                          <p className="mb-0">
                            Email : {repairViewData?.send_to_user_email}
                          </p>
                          <p className="mb-0">
                            Phone Number : {repairViewData?.send_to_user_phone}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-row">
                      <table className="table table-center add-table-items">
                        <thead>
                          <tr>
                            <th>Repair Item</th>
                            <th>Cost</th>
                            <th className="text-end">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {repairViewData?.repair_components?.map(
                            (
                              item: {
                                inv_repairid: number;
                                component: string;
                                cost: number;
                                amount: number;
                              },
                              index: number
                            ) => (
                              <tr key={item.inv_repairid}>
                                <td>{item.component}</td>{" "}
                                {/* Display the component name */}
                                <td>
                                  <span className="text-muted">
                                    $
                                    {typeof item.cost === "number"
                                      ? item.cost.toFixed(2)
                                      : "0.00"}
                                  </span>
                                </td>{" "}
                                {/* Display the cost */}
                                <td className="text-end">
                                  $
                                  {typeof item.amount === "number"
                                    ? item.amount.toFixed(2)
                                    : "0.00"}
                                </td>{" "}
                                {/* Display the amount */}
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="invoice-row">
                      <ul className="invoice-price">
                        <li>
                          <span className="invoice-price-lbl text-muted">
                            Subtotal
                          </span>
                          <span className="invoice-price-val">
                            ${repairViewData?.sub_amount?.toFixed(2)}
                          </span>
                        </li>
                        <li>
                          <span className="invoice-price-lbl text-muted">
                            Tax
                          </span>
                          <span className="invoice-price-val">
                            $
                            {(
                              (repairViewData?.total || 0) -
                              (repairViewData?.sub_amount || 0)
                            ).toFixed(2)}
                          </span>
                        </li>
                        <li>
                          <span className="invoice-price-lbl">
                            <b>Total</b>
                          </span>
                          <span className="invoice-price-val">
                            <b>${repairViewData?.total?.toFixed(2)}</b>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="invoice-row">
                      <div className="d-flex justify-content-between">
                        <div className="invoice-notes">
                          <div className="invoice-notes-icon">
                            <img src={notes} alt="" />
                          </div>
                          <div className="invoice-notes-val">
                            <strong className="d-block">Note</strong>
                            <p>{repairViewData?.notes_term} </p>
                          </div>
                        </div>
                        <div className="invoice-sign">
                          <p>Authorized Sign</p>
                          <img
                            src={repairViewData?.authorised_sign}
                            alt=""
                            style={{ height: "100px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceDetails;
function setRepairStatus(repair_status: any) {
  throw new Error("Function not implemented.");
}
