import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Plans from "./RenewalPlans";
import PaymentForm from "./plans/paymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe as initializeStripe } from "@stripe/stripe-js";

const stripePromise = initializeStripe("pk_test_YAUsegA3YbnfQchAwsJn5oZ1");

interface LoginPopUpProps {
  loginVisible: boolean;
  setLoginVisible: (visible: boolean) => void;
}

interface PlansProps {
  setPaymentVisible: (visible: boolean) => void; // Add prop for setting payment visibility
}

const LoginPopUp: React.FC = ({
  loginVisible,
  setLoginVisible,
  paymentVisible,
  setPaymentVisible,
}) => {
  // Dialog Header for Login
  const loginHeaderElement = (
    <div
      className="inline-flex align-items-center justify-content-center gap-2"
      style={{ textAlign: "center" }}
    >
      <span className="font-bold white-space-nowrap">Subscription Expired</span>
    </div>
  );

  // Footer Section for Login
  const loginFooterContent = (
    <div style={{ textAlign: "center" }}>
      {/* <Button
        label="Proceed to Payment"
        onClick={() => {}}
        style={{ backgroundColor: "#0071C0", height: "48px", color: "#ffffff" }}
        // Disable button if no plan is selected
      /> */}
    </div>
  );

  // Dialog Header for Payment
  const paymentHeaderElement = (
    <div
      className="inline-flex align-items-center justify-content-center gap-2"
      style={{ textAlign: "center" }}
    >
      <span className="font-bold white-space-nowrap">Payment</span>
    </div>
  );

  // Footer Section for Payment
  const paymentFooterContent = (
    <div style={{ textAlign: "center" }}>
      {/* <Button
        label="Go to Homepage"
        onClick={() => {
          setPaymentVisible(false);
          window.location.href = "https://thecarai.ai/index";
        }}
        style={{ backgroundColor: "#0071C0", height: "48px", color: "#ffffff" }}
      /> */}
    </div>
  );

  return (
    <div className="card flex justify-content-center">
      {/* Login Dialog */}
      <Dialog
        visible={loginVisible}
        modal
        header={loginHeaderElement}
        footer={loginFooterContent}
        style={{ width: "50rem" }}
        onHide={() => setLoginVisible(false)}
      >
        <p className="m-0">
          Your company's subscription has expired. Please renew your
          subscription to continue using our services.
        </p>
        <Plans
          setPaymentVisible={setPaymentVisible}
          setLoginVisible={setLoginVisible}
        />
        {/* Pass the state setter to Plans */}
      </Dialog>

      {/* Payment Dialog (Shown After Login) */}
      <Dialog
        visible={paymentVisible}
        modal
        header={paymentHeaderElement}
        footer={paymentFooterContent}
        style={{ width: "50rem" }}
        onHide={() => setPaymentVisible(false)}
      >
        <Elements stripe={stripePromise}>
          <PaymentForm setPaymentVisible={setPaymentVisible} />
        </Elements>
      </Dialog>
    </div>
  );
};

export default LoginPopUp;
