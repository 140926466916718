import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { noImg, profile, signatureImg } from "../../../../utils/imagepath";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import PrimeDataTable from "../../../../components/data-table";
import { toast } from "react-toastify";
import { routes } from "../../../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddCanvasSidebar,
  setEditCanvasSidebar,
  setAddCanvasViewSidebar,
} from "../../../../core/redux/sidebarSlice";
import { ConfirmDialog } from "primereact/confirmdialog";
import { formatDate } from "../../../../utils/patterns/regex.pattern";
import { setEditData } from "../../../../core/redux/commonSlice";
import {
  setGeneratedInvoiceData,
  setRepairInvoiceData,
} from "../../../../core/redux/commonSlice";
import GenerateInvoice from "./generate-invoice";
import ServiceInvoiceView from "./view-invoice";

const ServiceInvoiceList = () => {
  const [date1, setDate1] = useState();
  const { getData, putData, deleteData } = useContext(ApiServiceContext);
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<any>();
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const planData = useSelector((state: any) => state.login.planDetails);
  const [selectedInvoice, setSelectedInvoice] = useState<any>(null);

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(listData.map((item) => item.invoice_Id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };

  function formatString(input: string) {
    return input
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/^\w/, (char) => char.toUpperCase()); // Capitalize the first letter
  }

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    tutorialId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(tutorialId);
    } else {
      newSelectedRows.delete(tutorialId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleEditPlanClick = (invoiceid: any) => {
    //dispatch(setEditData(data));
    getInvoiceById(invoiceid);
  };
  const handleViewClick = (invoiceid: any) => {
    //dispatch(setEditData(data));
    getInvoiceById(invoiceid);
  };

  const getInvoiceById = async (invoiceid: any) => {
    const id = invoiceid?.invoice_Id || invoiceid; // Adjust this based on your structure
    console.log("Fetching ID:", id); // Verify this logs an actual ID number
    try {
      let url = `${end_points.get_InvoiceById.url}?invoice_id=${id}`;
      const response = await getData(url);
      console.log("Response:", response.data);
      // Handle response as needed
    } catch (error) {
      console.error("Error fetching invoice:", error);
    }
  };

  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const row_id = row;
    try {
      const payload = {
        id: row_id,
        isActive: isChecked,
      };
      let url = end_points.common_status_update.url;

      const response = await putData(url, payload);
      if (response.status === 200) {
        setListData((prevData: any) =>
          prevData.map((row: any) =>
            row.invoice_Id === row_id ? { ...row, status: isChecked } : row
          )
        );
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {
      console.log();
    }
  };

  const [visible, setVisible] = useState(false);

  const deletePlan = async (planId: number) => {
    const url = `${end_points.delete_any_invoice.url}?id=${planId}`;

    if (planId) {
      try {
        const response = await putData(url);
        if (response?.status === 200) {
          toast.success(response?.data?.response?.responseMessage);
          setVisible(false);
          getList();
        }
      } catch (error) {}
    }
  };

  const accept = () => {
    if (selectedInvoice) {
      deletePlan(selectedInvoice.invoice_Id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (data: any) => {
    setSelectedInvoice(data);
    setVisible(true);
  };

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data.invoice_Id)}
              onChange={(e) => handleRowCheckboxChange(e, data.invoice_Id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "Invoice ID",
      field: "invoice_refid",
      key: "invoice_refid",
      sortField: "invoice_refid",
    },
    {
      header: "Admin/Fleet Manager",
      field: "send_to_name",
      key: "send_to_name",
      sortField: "send_to_name",
      body: (data: any) => (
        <h2 className="table-avatar">
          <Link to="#" className="avatar avatar-sm me-2">
            <img
              className="avatar-img rounded-circle"
              src={data.logo || noImg}
              alt="Profiles"
            />
          </Link>
          <p>{data.send_to_name}</p>
        </h2>
      ),
    },
    {
      header: "Email",
      field: "phone",
      key: "phone",
      sortField: "phone",
      body: (data: any) => <span>{data.phone || "-"}</span>,
    },

    {
      header: "Date",
      field: "invoice_date",
      key: "invoice_date",
      sortField: "invoice_date",
      body: (data: any) => (
        <span className="text-muted">{formatDate(data.invoice_date)}</span>
      ),
    },
    {
      header: "Service Type",
      field: "service_type",
      key: "service_type",
      sortField: "service_type",
      body: (data: any) => <span>{data.service_type || "Normal"}</span>,
    },
    {
      header: "Billed",
      field: "total",
      key: "total",
      sortField: "total",
      body: (data: any) => <span>${data.total}</span>,
    },
    {
      header: "Status",
      field: "invoice_status",
      key: "invoice_status",
      sortField: "invoice_status",
      body: (data: any) => (
        <span
          className={`badge ${
            data.invoice_status == "rejected"
              ? "status-danger"
              : data.invoice_status == "pending_approval"
              ? "status-inprogress"
              : data.invoice_status == "paid"
              ? "status-completed"
              : "status-completed"
          }`}
        >
          {formatString(data.invoice_status)}
        </span>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (data: any) => {
        return (
          <>
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>

              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  to={routes.serviceInvoiceDetails.path}
                  className="dropdown-item"
                  onClick={() => dispatch(setGeneratedInvoiceData(data))}
                >
                  <i className="feather icon-eye" /> View
                </Link>
                <Link
                  to="#"
                  className="dropdown-item"
                  // onClick={() => {
                  //   handleViewClick(data.invoice_Id);
                  //   dispatch(setGeneratedInvoiceData(data));
                  //   handleOffcanvasView();
                  // }}
                >
                  <i className="feather icon-navigation" /> Tracking
                </Link>
                <Link to="#" className="dropdown-item">
                  <i className="feather icon-download" /> Download
                </Link>
                <Link
                  // onClick={() => {
                  //   handleEditPlanClick(data.invoice_Id);
                  //   dispatch(setRepairInvoiceData(data));
                  //   handleOffcanvas();
                  // }}
                  to="#"
                  className="dropdown-item"
                >
                  <i className="feather icon-edit" /> Edit
                </Link>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => handleDeleteClick(data)}
                >
                  <i className="feather icon-trash-2" /> Delete
                </Link>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const [count, setCount] = useState<number>(0);

  const getList = async (page = 1, limit = 10) => {
    try {
      let url = `${end_points.get_invoicepaging.url}?PageNumber=${page}&PageSize=${limit}`;
      if (searchQuery) url += `&SearchTerm=${searchQuery}`;

      const response = await getData(url);
      if (response.status === 200) {
        setListData(response.data.invoices);
        setTotalRecords(response.data.totalCount);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching invoice list:", error);
      toast.error("An error occurred while fetching invoice data.");
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const offcanvasview = useSelector(
    (state: any) => state.sidebar.addCanvasViewSidebar
  );
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };

  const handleOffcanvasView = () => {
    dispatch(setAddCanvasViewSidebar(!offcanvasview));
  };

  const [driversCount, setDriversCount] = useState<any>({});

  const getCounts = async () => {
    try {
      const result = await getData(end_points.driver_assign_unassign_count.url);
      setDriversCount({
        assignedStatusCount: result.data.assignedStatusCount,
        unassignedStatusCount: result.data.unassignedStatusCount,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // getCounts();
  }, []);
  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Invoice</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.serviceManagerDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Invoice</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Drivers */}
          <div className="drivers-info">
            {/* Drivers Info */}
            <div className="row">
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card drivers-card success-border w-100">
                  <div className="card-body">
                    <div className="drivers-card-details">
                      <div className="drivers-text">
                        <p>Invoice Sent</p>
                        <h5>{totalRecords || 0}</h5>
                      </div>
                      <div className="drivers-icon">
                        <span className="bg-success-icon">
                          <i className="ti ti-user-up" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card drivers-card danger-border w-100">
                  <div className="card-body">
                    <div className="drivers-card-details">
                      <div className="drivers-text">
                        <p>Paid Invoice</p>
                        <h5>{0}</h5>
                      </div>
                      <div className="drivers-icon">
                        <span className="bg-danger-icon">
                          <i className="ti ti-user-x" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card drivers-card danger-border w-100">
                  <div className="card-body">
                    <div className="drivers-card-details">
                      <div className="drivers-text">
                        <p>Unpaid Invoice</p>
                        <h5>{0}</h5>
                      </div>
                      <div className="drivers-icon">
                        <span className="bg-danger-icon">
                          <i className="ti ti-user-x" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card drivers-card primary-border w-100">
                  <div className="card-body">
                    <div className="drivers-card-details">
                      <div className="drivers-text">
                        <p>Cancelled Invoice</p>
                        <h5>{0}</h5>
                      </div>
                      <div className="drivers-icon">
                        <span className="bg-primary-icon">
                          <i className="ti ti-user-star" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Drivers Info */}
            {/* Sub Header */}
            <div className="sub-header">
              <div className="sub-header-content">
                <ul className="nav">
                  <li>
                    <h4>Invoice ({totalRecords || 0})</h4>
                  </li>
                </ul>
              </div>
            </div>
            {/* /Sub Header */}
            {/* Table */}
            <div className="card table-card">
              <div className="card-body">
                <div className="table-responsive prime-table">
                  <PrimeDataTable
                    column={columns}
                    setSearchQuery={setSearchQuery}
                    data={listData}
                    rows={rows}
                    setRows={setRows}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalRecords={totalRecords}
                  />
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
          {/* /Drivers */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {offcanvas && <GenerateInvoice successcall={getList} />}
      {offcanvasview && <ServiceInvoiceView />}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this invoice?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
    </>
  );
};

export default ServiceInvoiceList;
