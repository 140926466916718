interface EndPoint {
  url?: string;
  list?: string;
  add?: string;
  edit?: string;
  delete?: string;
  service?: string;
}

export const end_points: Record<string, EndPoint> = {
  // Authentication
  verify_domain: { url: "/authentication/check-domain" },
  setup_payment: { url: "/Subscribers/setup-payment-intent" },
  price_list: { url: "/PricingPlan/priceplanactive" },
  updatePayment: { url: "/Subscribers/update-payment_companyreg" },
  login: { url: "/Superadmin/login" },
  admin_login: { url: "/authentication/login" },
  course_login: { url: "/CourseAdmin/login" },
  admin_otp_verify: { url: "/authentication/verify_login_otp" },
  otp_verify: { url: "/Superadmin/verify_login_otp" },
  course_otp_verify: { url: "/CourseAdmin/verify_login_otp" },
  update_superAdmin: { url: "/User/update_superadmin" },
  update_adminprofile: { url: "/User/update_adminprofile" },
  super_admin_reset: { url: "/Superadmin/reset_password" },
  admin_reset: { url: "/authentication/reset_password" },
  course_reset: { url: "/CourseAdmin/reset_password" },
  super_admin_forgotpwd: { url: "/Superadmin/forgot_password" },
  admin_forgotpwd: { url: "/authentication/forgot_password" },
  course_forgotpwd: { url: "/CourseAdmin/forgot_password" },
  super_admin_otp: { url: "/Superadmin/request_otp" },
  admin_otp: { url: "/authentication/request_otp" },
  course_otp: { url: "/CourseAdmin/request_otp" },
  otp_super_admin: { url: "/Superadmin/verify_otp" },
  otp_admin: { url: "/authentication/verify_otp" },
  otp_course: { url: "/CourseAdmin/verify_otp" },
  create_tutorial: { url: "/Tutorial/create_tutorial" },
  update_tutorial: { url: "/Tutorial/update_tutorial" },
  create_priceplan: { url: "/PricingPlan/create_priceplan" },
  update_priceplan: { url: "/PricingPlan/update_priceplan" },
  best_priceplan: { url: "/PricingPlan/best_priceplan" },
  get_companieslist: { url: "/Company/get_companies" },
  get_companycount: { url: "/Company/counts-active-inactive" },
  get_pricelist: { url: "/PricingPlan/get_priceplan" },
  update_companystatus: {
    url: "/Company/update_status/company/id",
  },
  update_tutorialstatus: {
    url: "/Tutorial/update_status/tutorial/id",
  },
  get_companies: { url: "/Company/companypaging" },
  tutorial_paging: { url: "/Tutorial/tutorial_paging" },
  registered_companies: { url: "Company/companyallcount" },
  get_owneroperatorDetails: {
    url: "Subscriptionplan/get_owneroperatordetails",
  },
  pricing_plans: { url: "/PricingPlan/active-count" },
  active_status: { url: "/Company/counts-active-inactive" },
  update_planstatus: { url: "/PricingPlan/update_status/plan/id" },
  delete_plan: { url: "/PricingPlan/deleteprice" },
  delete_tutorial: { url: "/Tutorial/deletetutorial" },
  update_truck: { url: "/truck/update_truck" },
  get_truck: { url: "/truck/get_truck" },
  create_truck: { url: "/truck/create_truck" },
  delete_truck: { url: "/truck/deletetruck" },
  update_truck_status: { url: "/truck/update_status/truck/id" },
  vehicle_paging: { url: "/truck/vehiclepaging" },
  getFleetmanager: { url: "/User/get_userallfleet" },
  create_fleetmanager: { url: "/User/create_fleet" },
  update_fleetmanager: { url: "/User/update_fleet" },
  common_status_update: { url: "/User/update_status/userid" },
  get_all_drivers: { url: "/User/get_useralldrivers" },
  create_driver: { url: "/User/create_driver" },
  update_driver: { url: "/User/update_driver" },
  delete_any_user: { url: "/User/deleteuser" },
  get_truckby_id: { url: "/truck/get_truckby_id" },
  get_userservicemanager: { url: "/User/get_userservicemanager" },
  create_service: { url: "/User/create_service" },
  delete_service: { url: "/User/deleteuser" },
  update_service_status: { url: "/User/update_status/userid" },
  update_service: { url: "/User/update_service" },
  get_vehicletype: { url: "/truck/get_vehicletype" },
  get_trip: { url: "/Trip/get_trippaging" },
  get_trip_tracking: { url: "/Trip/get_triplogbyid" },
  create_trip: { url: "/Trip/create_trip" },
  update_trip: { url: "/Trip/update_trip" },
  delete_trip: { url: "/Trip/deletetrip" },
  assign_drivers: { url: "/User/get_alldrivers" },
  assign_new_vechicle: { url: "/Trip/update_assignnewvehicle" },
  get_inspection_list: { url: "/checklist/webinspection_list" },
  add_service: { url: "/Repair/create_repair" },
  get_allservicemanager: { url: "/User/get_allservicemanager" },
  get_all_vehicle: { url: "/truck/get_allvehiclefortrip" },
  get_repairpaging: { url: "/Repair/get_repairpaging" },
  getInpectionDetailsApi: { url: "/checklist/get_Inspectiobyid" },
  get_trip_all_vehicle: { url: "/truck/get_allvehiclefortripedit" },
  get_trip_all_driver: { url: "/user/get_alldriversforedit" },
  get_admin_dashboard_metrics: { url: "/truck/dashboard_count" },
  driver_assign_unassign_count: { url: "/User/driver_assign_unassign_count" },
  vehicle_assign_unassign_count: { url: "/truck/assign_unassign_count" },
  get_InspectionStatus: { url: "/Repair/get_inspectionservice" },
  get_RepairComponents: {
    url: "/InspectionComponent/get_inspection_components",
  },
  get_RepairTracking: { url: "/Repair/get_repairlogbyid" },
  get_RepairById: { url: "/Repair/get_repairById" },
  update_RepairComponent: { url: "/Repair/update_RepairComponent" },
  update_RepairStatus: { url: "/Repair/update_RepairStatus" },
  update_RepairSummary: { url: "/Repair/update_summary" },
  get_Announcement: { url: "/Repair/get_Announce" },
  get_fleet_dashboard: { url: "/User/fleet_dashboardcounts" },
  get_service_dashboard: { url: "/User/service_dashboardcounts" },
  get_admin_chart: { url: "/checklist/admin_weeklycounts" },
  get_service_chart: { url: "/User/service_dashboardcounts" },
  get_courseAdmin: { url: "/Course/courseadminpaging" },
  create_courseAdmin: { url: "/Course/create_courseadmin" },
  update_courseAdmin: { url: "/Course/courseadmin_update" },
  delete_courseAdmin: { url: "/Course/delete_courseadmin" },
  update_status_courseAdmin: { url: "/Course/update_status/courseadmin" },
  create_invoice: { url: "/Invoice/create_invoice" },
  create_component: { url: "/Invoice/create_component" },
  update_invoice: { url: "/Invoice/update_invoice" },
  update_inspectionstatus: { url: "Invoice/update_inspectionstatus" },
  get_invoicepaging: { url: "/Invoice/get_invoicepaging" },
  delete_any_invoice: { url: "/Invoice/deleteinvoice" },
  get_InvoiceById: { url: "/Invoice/get_invoicebyid" },
  get_TaxType: { url: "/Course/get_amounttype" },
  get_invoicerepairpaging: { url: "/Repair/get_invoicerepairpaging" },
  get_CourseType: { url: "/Course/get_coursetype" },
  list_quiz: { url: "/Quiz/get_quizpaging" },
  update_status_quiz: { url: "/Quiz/update_status/quiz" },
  create_quiz: { url: "/Quiz/create_quiz" },
  edit_quiz: { url: "/Quiz/update_quiz" },
  delete_quiz: { url: "/Quiz/deletequiz" },
  quiz_category: { url: "/Quiz/get_quizcategory" },
  product_list: { url: "/Product/get_productpaging" },
  category_list: { url: "/Product/get_productcategory" },
  create_product: { url: "/Product/create_product" },
  update_product: { url: "/Product/update_product" },
  update_product_status: { url: "/Product/update_status/product" },
  course_list: { url: "/Course/coursepaging" },
  course_category_list: { url: "/Course/get_coursecategory" },
  upload_media: { url: "/Course/update_media" },
  update_course_status: { url: "/Course/update_status/course" },
  upload_product_images: { url: "/Product/upload-images" },
  delete_product: { url: "/Product/deleteproduct" },
  get_product: { url: "/Product/get_productbyid" },
  create_course: { url: "/Course/create_course" },
  update_course: { url: "/Course/update_course" },
  delete_course: { url: "/Course/delete_course" },
  get_course: { url: "/Course/course_byid" },
  get_quiz: { url: "/Quiz/get_quizbyid" },
  get_quiz_for_edit: { url: "/Quiz/get_quizcategoryforedit" },
  get_notification: { url: "/Notification/user" },
  get_course_notification: { url: "/Notification/notification_courseadmin" },
  read_notification: { url: "/Notification/notification_readstatus" },
  read_course_notification: { url: "/Notification/notification_courseadmin" },
  get_super_notification: { url: "/Notification/notification_superadmin" },
  read_super_notification: {
    url: "/Notification/notification_readstatus_superadmin",
  },
  op_get_subscription_plan: {
    url: "/Subscriptionplan/get_subscriptionpriceplanweb",
  },
  op_create_subscription_plan: {
    url: "/Subscriptionplan/create_subscriptionpriceplan",
  },
  op_update_subscription_plan: {
    url: "/Subscriptionplan/update_subscriptionpriceplan",
  },
  op_delete_subscription_plan: {
    url: "/Subscriptionplan/deletesubscriptionprice",
  },
  op_update_subscription_plan_status: {
    url: "/Subscriptionplan/update_subscriptionplanstatus",
  },
  get_invoiceTracking: { url: "/Invoice/get_invoicelogbyid" },
  update_termcondition: { url: "/CourseAdmin/update_termcondition" },
  get_payment: { url: "/Invoice/GetPaymentKeys" },
  update_payment: { url: "/Invoice/update_paymentkeylive" },
  update_fleet_termcondition: {
    url: "authentication/update_termcondition_fleetmanager",
  },
  subscriber_list: {
    url: "/Subscribers/subscribers_paging",
  },
  subscriber_view: {
    url: "/Subscribers/subscriber_byid",
  },
  subscriber_courseview: {
    url: "/Subscribers/subscribercourse_byid",
  },
};
