import React, { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { useSelector } from "react-redux";
import { favicon } from "../../utils/imagepath";

// Helper function to request notification permissions
const requestNotificationPermission = () => {
  if (Notification.permission !== "granted") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
      }
    });
  }
};

// Function to show browser notifications
const showNotification = (title: string, options: NotificationOptions) => {
  if (Notification.permission === "granted") {
    new Notification(title, options);
  }
};

const NotificationComponent: React.FC = () => {
  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null
  );
  const loginUser = useSelector((state: any) => state?.login?.userDetails);

  useEffect(() => {
    if (loginUser && Object.keys(loginUser).length > 0) {
      // Request notification permission when the component is mounted
      requestNotificationPermission();
      const token: any = localStorage.getItem("token");
      // Establish the SignalR connection
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl("https://api.thecarai.ai/tripNotificationHub", {
          accessTokenFactory: () => token,
          transport:
            signalR.HttpTransportType.WebSockets |
            signalR.HttpTransportType.ServerSentEvents |
            signalR.HttpTransportType.LongPolling,
        })
        .withAutomaticReconnect()
        .build();

      // Start the SignalR connection and listen for messages
      newConnection
        .start()
        .then(() => {
          console.log("Connected to SignalR");

          if (Notification.permission !== "granted") {
            Notification.requestPermission()
              .then((permission) => {
                console.log("Requested Notification Permission:", permission);
                if (permission !== "granted") {
                  console.log("Notification permission denied");
                }
              })
              .catch((error) => {
                console.error(
                  "Error requesting notification permission:",
                  error
                );
              });
          } else {
            console.log("Notification permission already granted");
          }

          // Listening for notifications from the backend
          newConnection.on(
            `${loginUser?.id}_ReceiveNotification`,
            (message) => {
              console.log("Notification received: ", message);
              if (Notification.permission === "granted") {
                showNotification("New Notification", {
                  body: message,
                  icon: favicon,
                });
              } else {
                console.log("Notification permission not granted");
              }
            }
          );
        })
        .catch((error) => console.log("SignalR connection error: ", error));

      setConnection(newConnection);

      // Clean up connection on component unmount
      return () => {
        if (connection) {
          connection.stop();
        }
      };
    }
  }, [loginUser]);

  return null;
};

export default NotificationComponent;
