import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import {
  nameMaxLength,
  onlyAlphabet,
  validMessage,
  DESCRIPTION_LENGTH,
  convertToDate,
} from "../../../../utils/patterns/regex.pattern";
import { ApiServiceContext } from "../../../../utils/shared.module";
import { toast } from "react-toastify";
import { clearEditData } from "../../../../core/redux/commonSlice";
import { end_points } from "../../../../services/end_point/end_points";

const validationSchema = Yup.object().shape({
  tripName: Yup.string()
    .required("Trip name is required")
    .trim(),
  description: Yup.string()
    .required("Description is required")
    .max(255, "Description cannot be longer than 255 characters")
    .trim(),
  startDate: Yup.date().required("Start date is required"),
  endDate: Yup.date().required("End date is required"),
  chooseVehicle: Yup.object()
    .nullable()
    .required("Choose vehicle is required")
    .typeError("Choose vehicle is required"),
  chooseDriver: Yup.object()
    .nullable()
    .required("Choose driver is required")
    .typeError("Choose driver is required"),
});

const AddEditTrip = (props: any) => {
  const [start_Date, setStartDate] = useState(null);
  const [end_Date, setEndDate] = useState<any>(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [isEndDateDisabled, setIsEndDateDisabled] = useState(true);
  const [vehiclesList, setVehiclesList] = useState(props.vehiclesList);
  const [driversList, setDriversList] = useState(props.driversList);
  const [availableVehiclesList, setAvailableVehiclesList] = useState(props.availableVehiclesList);
  const [availableDriversList, setAvailableDriversList] = useState(props.availableDriversList);

  const { postData, putData } = useContext(ApiServiceContext);

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const data = useSelector((state: any) => state.common.editData);
  const login_id = useSelector((state: any) => state.login.userDetails.id);
  const trip_id = data?.trip_id;

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<any>(validationSchema),
    defaultValues: {
      tripName: "",
      description: "",
      startDate: start_Date,
      endDate: null,
      chooseDriver: null,
      chooseVehicle: null,
    },
  });

  console.log("Available vehicles" , vehiclesList);
  

  useEffect(() => {
    if (trip_id) {
      setIsEndDateDisabled(false);
      setValue("tripName", data.trip_name || "");
      setValue("description", data.trip_description || "");
      setValue("startDate", data.trip_start ? new Date(data.trip_start) : null);
      setValue("endDate", data.trip_end ? new Date(data.trip_end) : null);

      if (data?.vehicle?.vc_id) {
        const selectedVechile =
          vehiclesList.find(
            (option: any) => option.code === data?.vehicle?.vc_id
          ) || null;

        setValue("chooseVehicle", selectedVechile || "");
      }

      if (data?.user_id) {
        const selectedDriver =
          driversList.find((option: any) => option.code === data.user_id) ||
          null;

        setValue("chooseDriver", selectedDriver || "");
      }
    }
  }, [data, setValue]);

  const onSubmit = async (data: any) => {
    try {
      let urls = trip_id
        ? end_points.update_trip.url
        : end_points.create_trip.url;

      const payload = {
        trip_name: data.tripName,
        trip_start: convertToDate(data.startDate),
        trip_end: convertToDate(data.endDate),
        trip_description: data.description,
        vc_id: data.chooseVehicle.code,
        user_id: data.chooseDriver.code,
        created_by: login_id,
        ...(trip_id && { trip_id }),
      };
      const response = trip_id
        ? await putData(urls, payload)
        : await postData(urls, payload);
      if (response?.status === 200) {
        toast.success(response?.data?.response?.responseMessage);
        dispatch(setAddCanvasSidebar(!offcanvas));
        dispatch(clearEditData());
        props.successcall();
      }
    } catch (error) {}
  };

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
    dispatch(clearEditData());
  };

  const startDate = watch('startDate')

  const handleStartDateChange = async (value: any) => {
    const startDate = new Date(value);
    const endDate = new Date(end_Date);
  
    setStartDate(value);
    setValue("startDate", value);
    
    if (endDate < startDate) {
      setEndDate(null);
      setValue("endDate", null);
    }

    setIsEndDateDisabled(!value);
  };

  return (
    <div
      className={`truck-list-sidebar ${
        offcanvas ? "truck-list-sidebar-open" : ""
      }`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">{trip_id ? "Edit" : "Create"} Trip</h4>
            <Link to="#" className="clear-truck-list" onClick={handleOffcanvas}>
              <i className="fas fa-xmark" />
            </Link>
          </div>
          <div className="card-body">
            <div className="sidebar-sub-header">
              <h6 className="mb-1">{trip_id ? "Edit" : "New"} Trip</h6>
              <p className="text-muted">
                {trip_id
                  ? "Please edit details to update a trip"
                  : "Please add details to create a new trip"}
              </p>
            </div>
            <div className="truck-information">
              <div className="truck-information-grid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label>
                        Trip Name <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="tripName"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className="form-control"
                            maxLength={nameMaxLength}
                            placeholder="Enter Trip Name"
                            onBlur={() => trigger("tripName")}
                            onKeyDown={(e) => {
                              if (
                                !/[a-zA-Z0-9\s]/.test(e.key) && 
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}                            
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("tripName");
                            }}
                          />
                        )} 
                      />
                      {errors?.tripName && (
                        <small className="text-danger">
                          {String(errors.tripName.message)}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>
                        Start Date <span className="manitary">*</span>
                      </label>
                      <div className="input-append">
                        <Controller
                          name="startDate"
                          control={control}
                          render={({ field }) => (
                            <Calendar
                              className="datetimepicker"
                              value={field.value || null}
                              placeholder="MM/DD/YYYY"
                              minDate={new Date()}
                              onChange={(e) => {
                                handleStartDateChange(e.value);
                                trigger("startDate");
                              }}
                              onBlur={() => trigger("startDate")}
                            />
                          )}
                        />
                        <span className="datetimepicker-span">
                          <i className="ti ti-calendar" />
                        </span>
                        {errors?.startDate && (
                          <small className="text-danger">
                            {String(errors.startDate.message)}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>
                        End Date <span className="manitary">*</span>
                      </label>
                      <div className="input-append">
                        <Controller
                          name="endDate"
                          control={control}
                          render={({ field }) => (
                            <Calendar
                              className="datetimepicker"
                              value={field.value || null}
                              placeholder="MM/DD/YYYY"
                              minDate={startDate || new Date()}
                              onChange={(e) => {
                                setEndDate(e.value);
                                field.onChange(e.value);
                              }}
                              disabled={isEndDateDisabled}
                            />
                          )}
                        />
                        <span className="datetimepicker-span">
                          <i className="ti ti-calendar" />
                        </span>
                        {errors?.endDate && (
                          <small className="text-danger">
                            {String(errors.endDate.message)}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>
                        Choose Vehicle <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="chooseVehicle"
                        control={control}
                        render={({ field }) => (
                          <Dropdown filter
                            value={selectedVehicle || field.value}
                            onChange={(e) => {
                              setSelectedVehicle(e.value);
                              field.onChange(e.value || null);
                              trigger("chooseVehicle");
                            }}
                            onBlur={() => trigger("chooseVehicle")}
                            options={vehiclesList}
                            optionLabel="name"
                            placeholder="Select"
                            className="w-100"
                          />
                        )}
                      />
                      {errors?.chooseVehicle && (
                        <small className="text-danger">
                          {String(errors.chooseVehicle.message)}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label>
                        Choose Driver <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="chooseDriver"
                        control={control}
                        render={({ field }) => (
                          <Dropdown filter
                            value={selectedDriver || field.value}
                            onChange={(e) => {
                              setSelectedDriver(e.value);
                              field.onChange(e.value || null);
                              trigger("chooseDriver");
                            }}
                            onBlur={() => trigger("chooseDriver")}
                            options={trip_id ? driversList : availableDriversList}
                            optionLabel="name"
                            placeholder="Select"
                            className="w-100"
                          />
                        )}
                      />
                      {errors?.chooseDriver && (
                        <small className="text-danger">
                          {String(errors.chooseDriver.message)}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-1">
                      <label>
                        Trip Description <span className="manitary">*</span>
                      </label>
                      <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                          <textarea
                            {...field}
                            className="form-control"
                            maxLength={DESCRIPTION_LENGTH}
                            onBlur={() => trigger("description")}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("description");
                            }}
                          />
                        )}
                      />
                      {errors?.description && (
                        <small className="text-danger">
                          {String(errors.description.message)}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="mb-0">
                      <p className="text-muted">
                        <i className="ti ti-info-circle me-2" />
                        Maximum 255 Characters
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer sidebar-btn">
            <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
              Cancel
            </Link>
            <button type="submit" className="btn btn-add">
              {trip_id ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditTrip;
