import React, { useContext, useState } from "react";
import { ApiServiceContext, routes } from "../../../utils/shared.module";
import { end_points } from "../../../services/end_point/end_points";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const CourseHowToUse = () => {
    const { putData } = useContext(ApiServiceContext);
    const userDetails = useSelector((state: any) => state.login.userDetails);
    const navigate = useNavigate();



    const handleSubmit = async () => {
        try {
            const payload = {
                courseadmin_id: userDetails?.user_id,
                term_condition: true,
            };
            const response = await putData(
                end_points?.update_termcondition?.url,
                payload
            );

            if (response) {
                navigate(routes.courseAdminDashboard.path);
            }
        } catch (error) {
            console.error(error);
        }
    };



    return (
        <div>
            <div className="forgot-page-wrapper">
                <div className="forgot-page-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="static-page">
                                    <div className="row">
                                        <div className="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3">
                                            <div className="forgot-pwd-topper">
                                                <div className="forgot-pwd text-center">
                                                    <div className="forgot-password-inner">
                                                        <div className="forgot-password-title">

                                                         
                                                                <h5 className="terms-of-service">How To Use</h5>

                                                                <div className="terms-and-condition-content">
                                                                    <h5 className="terms-of-service">Welcome to the CARAi Marketplace</h5>
                                                                    <p className="terms-of-service-content">Welcome to the CARAi Marketplace! We’re excited to offer you a convenient platform designed specifically for the driver community. The Marketplace provides easy access to a variety of products, services, and courses that help you manage your everyday needs efficiently, allowing you to focus more on the road.</p>

                                                                    <h5 className="terms-of-service">Key Features of the Marketplace</h5>

                                                                    <p className="terms-of-service-content"><strong>1. Convenient Access to Products:</strong> Browse a wide range of products tailored for truck drivers, including:</p>
                                                                    <p className="terms-of-service-content"><strong>• Health and Wellness Items:</strong> Supplements, fitness gear, and healthy meals and snacks to keep you energized on the road.</p>
                                                                    <p className="terms-of-service-content"><strong>• Practical Services:</strong> Essential services to assist you.</p>
                                                                    <p className="terms-of-service-content"><strong>• Truck Accessories:</strong> Tools and accessories that enhance your driving experience.</p>

                                                                    <p className="terms-of-service-content"><strong>2. Service Subscriptions:</strong> Subscribe to various services that simplify your life, such as:</p>
                                                                    <p className="terms-of-service-content"><strong>• Maintenance Services:</strong> Schedule regular maintenance checks and repairs with trusted service providers.</p>
                                                                    <p className="terms-of-service-content"><strong>• Insurance Options:</strong> Compare and choose insurance plans that best suit your needs as an owner-operator.</p>
                                                                    <p className="terms-of-service-content"><strong>• Roadside Assistance:</strong> Access 24/7 support for emergencies, ensuring peace of mind while you drive.</p>

                                                                    <p className="terms-of-service-content"><strong>3. Courses and Training:</strong> Enhance your skills and knowledge with a selection of courses, including:</p>
                                                                    <p className="terms-of-service-content"><strong>• Health and Wellness Programs:</strong> Learn about nutrition, exercise, and mental well-being tailored for truck drivers.</p>
                                                                    <p className="terms-of-service-content"><strong>• Driving Safety Courses:</strong> Improve your driving skills and stay updated on the latest safety regulations.</p>
                                                                    <p className="terms-of-service-content"><strong>• Business Management Training:</strong> Gain insights into managing your trucking business effectively.</p>

                                                                    <h5 className="terms-of-service">How to Use the Marketplace</h5>
                                                                    <p className="terms-of-service-content"><strong>1. Access the Marketplace:</strong> From the home screen, navigate to the Marketplace section to explore available products and services.</p>
                                                                    <p className="terms-of-service-content"><strong>2. Browse and Search:</strong> Use the search bar or browse categories to find specific items or services that meet your needs.</p>
                                                                    <p className="terms-of-service-content"><strong>3. Subscribe and Purchase:</strong> Select the items or services you wish to purchase or subscribe to, and follow the prompts to complete your transaction.</p>
                                                                    <p className="terms-of-service-content"><strong>4. Manage Your Subscriptions:</strong> Easily manage your subscriptions and orders through your account settings.</p>

                                                                    <h5 className="terms-of-service">Benefits of Using the Marketplace</h5>
                                                                    <p className="terms-of-service-content"><strong>• Time-Saving:</strong> Quickly find and order what you need without having to search multiple websites or stores.</p>
                                                                    <p className="terms-of-service-content"><strong>• Tailored for Drivers:</strong> All products and services are specifically chosen to meet the unique needs of truck drivers.</p>
                                                                    <p className="terms-of-service-content"><strong>• Health and Wellness Focus:</strong> Access resources that promote a healthier lifestyle, even while on the road.</p>

                                                                    <h5 className="terms-of-service">Privacy and Data Security</h5>
                                                                    <p className="terms-of-service-content">Your privacy is important to us. The Marketplace adheres to the same privacy and security standards as the CARAi app, ensuring that your personal information is protected. For more details, please refer to our full Privacy Policy.</p>

                                                                    <h5 className="terms-of-service">Support</h5>
                                                                    <p className="terms-of-service-content">If you have any questions or need assistance while using the Marketplace, visit our support section in the app or contact our customer service team. We’re here to help you!</p>

                                                                    <p className="terms-of-service-content">Enjoy the convenience of the CARAi Marketplace and make your trucking experience smoother and more enjoyable!</p>
                                                                </div>


                                                                <button
                                                                    className="btn submit-btn btn-hvr w-50"
                                                                    onClick={handleSubmit}
                                                                >
                                                                   Proceed
                                                                </button>
                                                       
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseHowToUse;
