import React, { createContext, useContext } from "react";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import store from "../../core/redux/store";
import { end_points } from "../end_point/end_points";
import { SpinnerContext } from "../../components/spinner/spinner";
import { logout, setUserDetails } from "../../core/redux/loginSlice";
import { useDispatch } from "react-redux";
import { debounce } from "../../utils/debounce";

export const AxiosContext = createContext<any>({});

const AxiosProvider = (props: { children: any }) => {
  const axiosInstance: AxiosInstance = axios.create();
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const dispatch = useDispatch();
  const path = window.location?.pathname;
  const findPath = path.split("/")[1];
  const host = window.location.hostname;
  const domain = host.split(".")[0].replace("https://", "");
  const domainName = host === "localhost" ? "bmwone" : domain;

  const hideLoadersApi: Array<string | undefined> = [];
  axiosInstance.interceptors.request.use(
    async (config: any) => {
      if (!hideLoadersApi.includes(config.url)) {
        showLoader();
      }

      // Development URL
      const token: any = localStorage.getItem("token");
      config.baseURL =
        "https://staging-truckingapp-backend.dreamstechnologies.com/api";

      const isMultipart =
        config.url.startsWith(end_points.create_truck.url) ||
        config.url.startsWith(end_points.update_truck.url) ||
        config.url.startsWith(end_points.upload_product_images.url) ||
        config.url.startsWith(end_points.upload_media.url) ||
        config.url.startsWith(end_points.create_courseAdmin.url) ||
        config.url.startsWith(end_points.update_courseAdmin.url) ||
        config.url.startsWith(end_points.create_invoice.url) ||
        config.url.startsWith(end_points.create_fleetmanager.url) ||
        config.url.startsWith(end_points.update_fleetmanager.url) ||
        config.url.startsWith(end_points.update_superAdmin.url) ||
        config.url.startsWith(end_points.update_adminprofile.url) ||
        config.url.startsWith(end_points.update_service.url) ||
        config.url.startsWith(end_points.create_service.url) ||
        config.url.startsWith(end_points.create_course.url) ||
        config.url.startsWith(end_points.update_course.url) ||
        config.url.startsWith(end_points.create_driver.url) ||
        config.url.startsWith(end_points.update_driver.url) ||
        config.url.startsWith(end_points.create_tutorial.url) ||
        config.url.startsWith(end_points.update_tutorial.url);

      config.baseURL =
        "https://staging-truckingapp-backend.dreamstechnologies.com/api";
      config.url.startsWith(end_points.update_superAdmin.url);
      if (
        config.url.startsWith(end_points.create_tutorial.url) ||
        config.url.startsWith(end_points.update_tutorial.url) ||
        config.url.startsWith(end_points.create_fleetmanager.url) ||
        config.url.startsWith(end_points.create_truck.url) ||
        config.url.startsWith(end_points.create_driver.url) ||
        config.url.startsWith(end_points.create_service.url) ||
        config.url.startsWith(end_points.update_truck.url) ||
        config.url.startsWith(end_points.update_fleetmanager.url) ||
        config.url.startsWith(end_points.update_driver.url) ||
        config.url.startsWith(end_points.update_service.url) ||
        config.url.startsWith(end_points.create_courseAdmin.url) ||
        config.url.startsWith(end_points.update_courseAdmin.url) ||
        config.url.startsWith(end_points.create_invoice.url) ||
        config.url.startsWith(end_points.create_component.url) ||
        config.url.startsWith(end_points.update_invoice.url) ||
        config.url.startsWith(end_points.upload_product_images.url) ||
        config.url.startsWith(end_points.upload_media.url)
      ) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
          token: `${token}`,
          "Content-Type": isMultipart
            ? "multipart/form-data"
            : "application/json",
          Accept: "application/json",
          "Domain-Name": domainName, // Use the updated domainName
        };
      } else {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
          token: `${token}`,
          "Content-Type": isMultipart
            ? "multipart/form-data"
            : "application/json",
          Accept: "application/json",
          "Domain-Name": domainName, // Use the updated domainName
        };
      }

      return config;
    },
    (error) => {
      hideLoader();
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    async (response: AxiosResponse) => {
      hideLoader();
      await handleResponseStatus(response);
      return response;
    },
    async (error) => {
      hideLoader();
      await handleResponseStatus(error.response);
      return Promise.reject(error);
    }
  );

  const handleUnauthorised = debounce((findPath: string) => {
    toast.error("Unauthorised");
    setTimeout(() => {
      dispatch(logout(findPath));
      dispatch(setUserDetails(undefined));
    }, 1500);
  }, 500);

  const handleResponseStatus = async (response: AxiosResponse | undefined) => {
    if (response) {
      switch (response.status) {
        case 200:
          // toast.success(response?.data?.response?.responseMessage);
          break;
        case 201:
          // toast.error(response.data.message);
          break;
        case 422:
          toast.error(response.data.errors[0].msg);
          break;
        case 500:
          toast.error("Internal server error");
          break;
        case 502:
          toast.error(response.data.error);
          break;
        case 400:
          toast.error(response?.data?.response?.responseMessage);
          break;
        case 403:
          // toast.error(response?.data?.response?.responseMessage);
          break;
        case 404:
          toast.error(response?.data?.response?.responseMessage);
          break;
        case 409:
          toast.error(response?.data?.response?.responseMessage);
          break;
        case 401:
          handleUnauthorised(findPath);
          break;
        default:
          break;
      }
    }
  };

  return (
    <AxiosContext.Provider value={{ axiosInstance }}>
      {props.children}
    </AxiosContext.Provider>
  );
};

export default AxiosProvider;
