import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setExpandMenu,
  setMiniSidebar,
  setMobileSidebar,
} from "../../../../core/redux/sidebarSlice";
import { routes } from "../../../../utils/routes";
import { avatar02, hello, logo, noImg } from "../../../../utils/imagepath";
import store from "../../../../core/redux/store";
import { logout } from "../../../../core/redux/loginSlice";
import { capitalizeFirstLetter } from "./../../../../utils/constant";
import Notification from "../notifications/notifications";
interface UserDetails {
  cour_type: any;
  name?: string;
  profile_url?: string;
}
const Header = () => {
  const miniSidebar = useSelector((state: any) => state.sidebar.miniSidebar);
  const mobileSidebar = useSelector(
    (state: any) => state.sidebar.mobileSidebar
  );
  const userDetails: UserDetails = store?.getState()?.login?.userDetails || {};
  const { name = "", profile_url } = userDetails;
  const profile_img = profile_url || noImg;
  const path = window.location?.pathname;
  const findPath = path.split("/")[1];
  const pathname: any = window.location?.pathname;
  const role = useSelector((state: any) => state.login.role);
  const roleType = userDetails.cour_type;

  const isCourseAdmin =
    findPath.includes("course-admin") ||
    roleType === "Product" ||
    roleType === "Course";

  const isSuperAdmin =
    findPath.includes("super-admin") || roleType === "super_admin";

  const dispatch = useDispatch();
  const handleMiniSidebar = () => {
    dispatch(setMiniSidebar(!miniSidebar));
  };
  const handleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebar));
  };
  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };
  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };
  const [headerClass, setHeaderClass] = useState("header");
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 100;

      if (scrollPosition > scrollThreshold) {
        setHeaderClass("header active");
      } else {
        setHeaderClass("header");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = (data: any) => {
    dispatch(logout(data));
  };

  return (
    <>
      {/* Header */}
      <div className={headerClass}>
        {/* Logo */}
        <div
          className={`header-left ${miniSidebar ? "active" : ""}`}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {isCourseAdmin && (
            <>
              <Link
                to={routes.courseAdminDashboard.path}
                className="logo logo-normal"
              >
                <img src={logo} alt="" />
              </Link>
              <Link
                to={routes.courseAdminDashboard.path}
                className="logo-small"
              >
                <p>C</p>
              </Link>
              <Link id="toggle_btn" to="#" onClick={handleMiniSidebar}>
                <i className="ti ti-chevron-left" />
              </Link>
            </>
          )}

          {isSuperAdmin && (
            <>
              <Link to={routes.dashboard.path} className="logo logo-normal">
                <img src={logo} alt="" />
              </Link>
              <Link to={routes.dashboard.path} className="logo-small">
                <p>C</p>
              </Link>
              <Link id="toggle_btn" to="#" onClick={handleMiniSidebar}>
                <i className="ti ti-chevron-left" />
              </Link>
            </>
          )}

          {role === 1 && (
            <>
              <Link
                to={routes.adminDashboard.path}
                className="logo logo-normal"
              >
                <img src={logo} alt="" />
              </Link>
              <Link to={routes.adminDashboard.path} className="logo-small">
                <p>C</p>
              </Link>
              <Link id="toggle_btn" to="#" onClick={handleMiniSidebar}>
                <i className="ti ti-chevron-left" />
              </Link>
            </>
          )}

          {role === 3 && (
            <>
              <Link
                to={routes.fleetManagerDashboard.path}
                className="logo logo-normal"
              >
                <img src={logo} alt="" />
              </Link>
              <Link
                to={routes.fleetManagerDashboard.path}
                className="logo-small"
              >
                <p>C</p>
              </Link>
              <Link id="toggle_btn" to="#" onClick={handleMiniSidebar}>
                <i className="ti ti-chevron-left" />
              </Link>
            </>
          )}

          {role === 4 && (
            <>
              <Link
                to={routes.serviceManagerDashboard.path}
                className="logo logo-normal"
              >
                <img src={logo} alt="" />
              </Link>
              <Link
                to={routes.serviceManagerDashboard.path}
                className="logo-small"
              >
                <p>C</p>
              </Link>
              <Link id="toggle_btn" to="#" onClick={handleMiniSidebar}>
                <i className="ti ti-chevron-left" />
              </Link>
            </>
          )}
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#sidebar"
          onClick={handleMobileSidebar}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        {/* Header Menu */}
        <ul className="nav user-menu">
          {/* Search */}
          <li className="nav-item nav-welcome">
            <h6>
              Hello! {capitalizeFirstLetter(name)}
              {/* {name}  */}
              <img src={hello} className="mx-1" alt="" />
              Welcome Back!
            </h6>
          </li>
          {/* /Search */}
          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="notify-menu">
                <i className="ti ti-bell"></i>
                <span className="notify-status"></span>
              </span>
            </Link>
            <Notification />
          </li>
          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                <span className="user-letter">
                  <img src={profile_img} className="img-fluid" alt="Profile" />
                </span>
              </span>
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              <Link
                to={
                  isSuperAdmin
                    ? routes.adminProfile.path
                    : isCourseAdmin
                    ? routes.courseAdminProfile.path
                    : routes.profile.path
                }
                className="dropdown-item"
              >
                <i className="ti ti-user-check" /> My Profile
              </Link>
              <Link
                to={"#"}
                className="dropdown-item"
                onClick={() => {
                  handleLogout(findPath);
                }}
              >
                <i className="ti ti-logout" /> Logout
              </Link>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
      </div>
      {/* /Header */}
    </>
  );
};

export default Header;
