import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { yup } from "../../../../utils/shared.module";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import {
  formatTime,
  nameMaxLength,
} from "../../../../utils/patterns/regex.pattern";
import { clearEditData } from "../../../../core/redux/commonSlice";
import { Dropdown } from "primereact/dropdown";
import { videoPreview } from "../../../../utils/imagepath";
import ViewCourse from "./view-course";

const AddCourse = ({
  successcall = () => {},
  courseList,
  type,
  setType,
  deletePlan,
}: any) => {
  const { postData, putData, getData } = useContext(ApiServiceContext);
  const courseVideoNameRef = useRef<HTMLElement | null>(null);
  const courseVideoRef = useRef<HTMLElement | null>(null);
  const userDetails = useSelector((state: any) => state.login.userDetails);
  const [divs, setDivs] = useState<any>([
    {
      course_video_name: "",
      video_path: "",
      thumbnail: null,
      fileName: "",
      fileSize: "",
      input_error: "",
    },
  ]);
  const [isErrorShow, setIsErrorShow] = useState<any>(false);
  const [isErrorShow1, setIsErrorShow1] = useState<any>(false);

  const handleAddDiv = () => {
    setDivs([
      ...divs,
      {
        course_video_name: "",
        video_path: "",
        thumbnail: "", // Empty string as a fallback to prevent issues
        fileName: "",
        fileSize: "",
        input_error: "",
      },
    ]);
  };

  const datas = useSelector((state: any) => state.common.editData);
  const quiz_id = datas && Object?.keys(datas)?.length > 0;
  const [editValue, setEditValue] = useState<any>({});

  const validationSchema = Yup.object().shape({
    category: Yup.number().required("Course Category is required"),
    title: Yup.string().trim().required("Course title is required"),
    status: yup.boolean().required("Status is required"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (quiz_id) {
      getEditValue();
    }
  }, [datas, setValue, quiz_id]);

  const getEditValue = async () => {
    try {
      let url = end_points.get_course.url;
      if (quiz_id) {
        url += `?course_id=${datas.course_id}&courseadmin_id=${userDetails?.courseadmin_id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        const data = response?.data?.data?.course;
        setEditValue(data);
        setValue("title", data.course_name || "");
        setValue("category", data.cour_categoryid || "");
        setValue("status", data.status === 1 ? true : false);
        setDivs(JSON.parse(data?.course_video));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data: any) => {
    if (
      (courseVideoNameRef?.current === undefined ||
        courseVideoNameRef?.current === null) &&
      (courseVideoRef?.current === undefined ||
        courseVideoRef?.current === null)
    ) {
      try {
        const course_status: any = data.status === true ? 1 : 2;
        const category_id: any = Number(data.category);
        const courseVideos: any = divs?.map((item: any) => {
          return {
            course_video_name: item?.course_video_name,
            video_path: item?.video_path,
            duration: item?.duration,
            fileName: item?.fileName,
            fileSize: item?.fileSize,
          };
        });
        const id: any = Number(userDetails?.courseadmin_id);

        const payload: any = {
          course_name: data.title,
          courseadmin_id: id,
          cour_categoryid: category_id,
          course_videos: courseVideos,
          status: course_status,
        };

        if (datas?.course_id) {
          payload["course_id"] = datas.course_id;
          payload["updated_by"] = id;
        } else {
          payload["created_by"] = id;
        }

        let urls = quiz_id
          ? end_points.update_course.url
          : end_points.create_course.url;
        dispatch(setAddCanvasSidebar(!offcanvas));
        const response = quiz_id
          ? await putData(urls, payload)
          : await postData(urls, payload);

        if (response.status === 200) {
          toast.success(response?.data?.response?.responseMessage);
          dispatch(clearEditData());
          successcall();
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleCustomSubmit = () => {
    updateErrors();
    handleSubmit(onSubmit)();
    setIsErrorShow(true);
    setIsErrorShow1(true);
  };

  const dispatch = useDispatch();

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
    dispatch(clearEditData());
    setType("");
    reset();
  };

  const handleInputChange = async (index: any, event: any) => {
    const newDivs = [...divs];
    newDivs[index].course_video_name = event.target.value;
    setDivs(newDivs);
    setIsErrorShow(true);
    await updateErrors();
  };

  const handleVideoUpload = async (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event.target.files?.[0]; // Check if a file was selected
      if (!file) {
        toast.error("No file selected. Please select a valid video file.");
        return;
      }

      // Validate file type
      const validFileTypes = [
        "video/mp4",
        "video/x-msvideo",
        "video/x-ms-wmv",
        "video/webm",
      ];
      if (!validFileTypes.includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload an MP4, AVI, WMV, or WebM video."
        );
        return;
      }

      // Validate file size (e.g., 100MB limit)
      const maxSizeMB = 100;
      const fileSizeMB = file.size / (1024 * 1024); // Convert size to MB
      if (fileSizeMB > maxSizeMB) {
        toast.error(
          `File size exceeds ${maxSizeMB}MB. Please upload a smaller video.`
        );
        return;
      }

      // Prepare FormData for API upload
      const formData = new FormData();
      formData.append("file", file);

      const uploadUrl = end_points.upload_media.url; // Ensure this endpoint is correctly set

      // API Call
      const response = await putData(uploadUrl, formData);

      if (response.status === 200) {
        // On successful upload
        toast.success(
          response?.data?.responseMessage || "Video uploaded successfully!"
        );

        // Update the `divs` state with the uploaded video details
        const newDivs = [...divs];
        const videoUrl = URL.createObjectURL(file);
        newDivs[index] = {
          ...newDivs[index],
          video_path: response?.data?.mediaFilePath,
          fileName: file.name,
          fileSize: fileSizeMB.toFixed(2), // Rounded to 2 decimals
        };
        setDivs(newDivs);

        // Generate thumbnail for the uploaded video
        createThumbnail(index, videoUrl);
      } else {
        // Handle upload failure
        toast.error(
          response?.data?.responseMessage ||
            "Failed to upload video. Please try again."
        );
      }
    } catch (error) {
      // Log and display errors
      console.error("Error uploading video:", error);
      toast.error(
        "An error occurred while uploading the video. Please try again."
      );
    } finally {
      // Ensure error visibility logic runs
      setIsErrorShow1(true);
      setTimeout(() => {
        updateErrors();
      }, 500);
    }
  };

  const createThumbnail = (index: number, videoUrl: string) => {
    try {
      const video = document.createElement("video");
      video.src = videoUrl;
      video.crossOrigin = "anonymous";
      video.muted = true;
      video.currentTime = 2;

      video.onloadeddata = () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth || 640; // Provide default values if necessary
        canvas.height = video.videoHeight || 360; // Provide default values if necessary
        const ctx = canvas.getContext("2d");

        if (ctx) {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const thumbnailUrl = canvas.toDataURL("image/jpeg"); // Generate base64 image data URL
          const duration = video.duration;

          // Update state correctly with functional setDivs
          setDivs((prevDivs: any) => {
            const updatedDivs = [...prevDivs];
            updatedDivs[index] = {
              ...updatedDivs[index],
              thumbnail: thumbnailUrl,
              duration: formatTime(duration),
            };
            return updatedDivs;
          });

          // Clean up the video URL
          URL.revokeObjectURL(videoUrl);
        } else {
          console.error(
            "Failed to create canvas context for thumbnail generation."
          );
          toast.error("Failed to generate thumbnail.");
        }
      };

      video.onerror = () => {
        console.error("Error loading video for thumbnail generation.");
        toast.error("Failed to load video for thumbnail generation.");
      };
    } catch (error) {
      console.error("Error in createThumbnail:", error);
      toast.error("An error occurred while generating the thumbnail.");
    }
  };

  console.log("divs", divs);

  const updateErrors = () => {
    const regex = /^[a-zA-Z0-9 ]*$/;
    const updates = divs.map((video: any) => {
      const errors: any = {};
      if (video?.course_video_name?.trim() === "") {
        errors.course_video_name = "Course video name is required";
      } else if (!regex.test(video?.course_video_name)) {
        errors.course_video_name = "accept only alphanumerical";
      } else {
        errors.course_video_name = "";
      }
      if (video?.video_path) {
        errors.video_path = "Video is required";
      } else {
        errors.video_path = "";
      }
      return { ...video, input_error: errors };
    });

    setDivs(updates);
  };

  useEffect(() => {
    updateErrors();
  }, []);
  return (
    <>
      {type === "View" ? (
        <ViewCourse
          successcall={successcall}
          handleOffcanvas={handleOffcanvas}
          deletePlan={deletePlan}
          editValue={editValue}
          setType={setType}
        />
      ) : (
        <div
          className={`truck-list-sidebar ${
            offcanvas ? "truck-list-sidebar-open" : ""
          }`}
        >
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{quiz_id ? "Edit" : "Add"} Course </h4>
              <Link
                onClick={handleOffcanvas}
                to="#"
                className="clear-truck-list"
              >
                <i className="fas fa-xmark" />
              </Link>
            </div>
            <div className="card-body">
              <div className="truck-information mb-0">
                <h4 className="mb-1">Course Video</h4>
                <p className="text-muted">
                  Please below details to create new Course
                </p>
                <div className="truck-information-grid mb-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label>
                          Course Title <span className="manitary">*</span>
                        </label>
                        <Controller
                          name="title"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Name"
                              {...field}
                              onBlur={() => trigger("title")}
                              maxLength={nameMaxLength}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("title");
                              }}
                            />
                          )}
                        />
                        {errors.title && (
                          <small className="text-danger">
                            {errors.title.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label>
                          Course Category <span className="manitary">*</span>
                        </label>
                        <Controller
                          name="category"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              filter
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                                // setDiscountTypes(e.value);
                              }}
                              options={courseList}
                              placeholder="Choose"
                              className="w-100"
                            />
                          )}
                        />
                        {errors.category && (
                          <small className="text-danger">
                            {errors.category.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {divs.map((div: any, index: any) => (
                  <div key={index}>
                    <h6 className="mb-3">Course Video {index + 1}</h6>
                    <div className="truck-information-grid mb-3">
                      <div className="row">
                        {/* Video Title Input */}

                        {/* File Upload Section */}
                        <div className="col-md-12">
                          <div className="form-wrap">
                            <div className="upload-info">
                              <label>
                                Course Video{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <p>
                                <i className="feather icon-alert-circle" />{" "}
                                Maximum File size 100MB & MP4, AVI, WMV, WebM
                              </p>
                              <label className="choose-file w-50">
                                <input
                                  type="file"
                                  accept="video/mp4, video/x-msvideo, video/x-ms-wmv, video/webm"
                                  onChange={(e) => handleVideoUpload(index, e)}
                                />
                                <span>
                                  <i className="feather icon-upload" /> Choose
                                  File to Upload
                                </span>
                              </label>
                              {/* Error Message */}
                              <div>
                                {div.thumbnail && (
                                  <div className="upload-grid w-50">
                                    <div className="upload-img">
                                      <img
                                        src={div.thumbnail}
                                        alt="Video Thumbnail"
                                      />
                                    </div>
                                    <div className="upload-text">
                                      <h6>{div.fileName}</h6>
                                      <p>{div.fileSize} MB</p>
                                      <p>Duration: {div.duration}</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {/* Uploaded Video Preview */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="addgrid-btn mb-3">
                  <Link
                    to="#"
                    className="add-btn me-2 font-"
                    onClick={handleAddDiv}
                  >
                    <strong>+ Add New Course Video</strong>
                  </Link>
                </div>
                <div className="col-md-12 d-flex align-items-center justify-content-between mb-3">
                  <h6>Status</h6>
                  <div className="toggles">
                    <Controller
                      name="status"
                      control={control}
                      defaultValue={true}
                      render={({ field }) => (
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="togBtn"
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                          <div className="slider round">
                            <span className="on">
                              <i className="ti ti-check"></i>
                            </span>
                            <span className="off">
                              <i className="ti ti-x"></i>
                            </span>
                          </div>
                        </label>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer canvas-btn tutorial-footer">
              <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
                Cancel
              </Link>
              <Link to="#" className="btn btn-add" onClick={handleCustomSubmit}>
                {quiz_id ? "Update" : "Add"}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddCourse;
