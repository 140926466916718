import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  editData: {},
  selectedData:{},
  vehicleEditData: {},
  inspectionData: {},
  serviceVehicleData:{},
  repairViewData:{},
  repairInvoiceData:{},
  generatedInvoiceData:{},
}

const CommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setEditData: (state, { payload }) => {
      state.editData = payload
    },
    clearEditData: (state) => {
      state.editData = {};
    },
    setSelectedData: (state, { payload }) => {
      state.selectedData = payload
    },
    clearSelectedData: (state) => {
      state.selectedData = {};
    },
    setVehicleEditData: (state, { payload }) => {
      state.vehicleEditData = payload
    },
    clearVehicleEditData: (state) => {
      state.vehicleEditData = {};
    },
    setInspectionData: (state, { payload }) => {
      state.inspectionData = payload
    },
    clearInspectionData: (state) => {
      state.inspectionData = {};
    },
    setServiceVehicleData: (state, { payload }) => {
      state.serviceVehicleData = payload
    },
    setRepairViewData: (state, { payload }) => {
      state.repairViewData = payload
    },
    setRepairInvoiceData: (state, { payload }) => {
      state.repairInvoiceData = payload
    },
    setGeneratedInvoiceData: (state, { payload }) => {
      state.generatedInvoiceData = payload
    },
    clearGeneratedInvoiceData: (state) => {
      state.generatedInvoiceData = {};
    },
  },
})

export const {
  setEditData,
  clearEditData,
  setSelectedData,
  clearSelectedData,
  setVehicleEditData,
  clearVehicleEditData,
  setInspectionData,
  clearInspectionData,
  setServiceVehicleData,
  setRepairViewData,
  setRepairInvoiceData,
  setGeneratedInvoiceData,
  clearGeneratedInvoiceData,
} = CommonSlice.actions

export default CommonSlice.reducer
