import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { noImg, profile, signatureImg } from "../../../../utils/imagepath";

import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../services/api/api.service";
import PrimeDataTable from "../../../../components/data-table";
import { toast } from "react-toastify";
import { routes } from "../../../../utils/routes";
import { Calendar } from "primereact/calendar";
import AddEditDrivers from "./add-edit-drivers";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddCanvasSidebar,
  setEditCanvasSidebar,
} from "../../../../core/redux/sidebarSlice";
import { ConfirmDialog } from "primereact/confirmdialog";
import { formatDate } from "../../../../utils/patterns/regex.pattern";
import { setEditData } from "../../../../core/redux/commonSlice";

const DriversList = () => {
  const { getData, putData, deleteData } = useContext(ApiServiceContext);
  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<any>();
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const planData = useSelector((state: any) => state.login.planDetails);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(listData.map((item) => item.user_id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    tutorialId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(tutorialId);
    } else {
      newSelectedRows.delete(tutorialId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleEditPlanClick = (data: any) => {
    dispatch(setEditData(data));
  };

  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number
  ) => {
    const isChecked = event.target.checked ? 1 : 2;
    const row_id = row;
    try {
      const payload = {
        id: row_id,
        isActive: isChecked,
      };
      let url = end_points.common_status_update.url;

      const response = await putData(url, payload);
      if (response.status === 200) {
        setListData((prevData: any) =>
          prevData.map((row: any) =>
            row.user_id === row_id ? { ...row, status: isChecked } : row
          )
        );
        toast.success(response?.data?.response?.responseMessage);
      }
    } catch (error) {}
  };

  const [visible, setVisible] = useState(false);

  const deletePlan = async (planId: number) => {
    const url = `${end_points.delete_any_user.url}?id=${planId}`;

    if (planId) {
      try {
        const response = await deleteData(url);
        if (response?.status === 200) {
          toast.success(response?.data?.response?.responseMessage);
          setVisible(false);
          getList();
        }
      } catch (error) {}
    }
  };

  const accept = () => {
    if (selectedUser) {
      deletePlan(selectedUser.user_id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (data: any) => {
    setSelectedUser(data);
    setVisible(true);
  };

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data.user_id)}
              onChange={(e) => handleRowCheckboxChange(e, data.user_id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "ID",
      field: "user_refid",
      key: "user_refid",
      sortField: "user_refid",
    },
    {
      header: "Driver Name",
      field: "firstname",
      key: "firstname",
      sortField: "firstname",
      body: (data: any) => (
        <h2 className="table-avatar">
          <Link to="#" className="avatar avatar-sm me-2">
            <img
              className="avatar-img rounded-circle"
              src={data.profile_Image || noImg}
              alt="Profiles"
            />
          </Link>
          <p>{data.firstname}</p>
        </h2>
      ),
    },
    {
      header: "Contact Number",
      field: "phone",
      key: "phone",
      sortField: "phone",
      body: (data: any) => <span>{data.phone}</span>,
    },

    {
      header: "Vehicle Number",
      field: "vehicle_number",
      key: "vehicle_number",
      sortField: "vehicle_number",
      body: (data: any) => <span>{data.vehicle_number || "-"}</span>,
    },
    {
      header: "Assigned Date",
      field: "assigned_date",
      key: "assigned_date",
      sortField: "assigned_date",
      body: (data: any) => <span className="text-muted">11-29-2024</span>,
    },
    // {
    //   header: "Assigned Status",
    //   field: "assigned_status",
    //   key: "assigned_status",
    //   sortField: "assigned_status",
    //   body: (data: any) => (
    //     <span className="badge status-completed">Assigned</span>
    //   ),
    // },
    {
      header: "Status",
      field: "status",
      key: "status",
      sortField: "status",
      body: (data: any) => (
        <div className="toggles">
          <label className="switch">
            <input
              type="checkbox"
              id="togBtn"
              checked={data.status === 1}
              onChange={(event) => handleToggle(event, data.user_id)}
            />
            <div className="slider round">
              <span className="on">
                <i className="ti ti-check" />
              </span>
              <span className="off">
                <i className="ti ti-x" />
              </span>
            </div>
          </label>
        </div>
      ),
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (data: any) => {
        return (
          <>
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="feather icon-more-vertical" />
              </Link>

              <div className="dropdown-menu dropdown-menu-end">
                {/* <Link to="#" className="dropdown-item">
                  <i className="feather icon-eye" /> View
                </Link> */}
                <Link
                  onClick={() => {
                    handleEditPlanClick(data);
                    handleOffcanvas();
                  }}
                  to="#"
                  className="dropdown-item"
                >
                  <i className="feather icon-edit" /> Edit
                </Link>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => handleDeleteClick(data)}
                >
                  <i className="feather icon-trash-2" /> Delete
                </Link>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const [count, setCount] = useState<number>(0);

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      let url = end_points.get_all_drivers.url;
      if (page) {
        url += `?PageNumber=${page}&PageSize=${limit}`;
      }
      if (searchQuery) {
        url += `&SearchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.users);
        setCount(response?.data?.totalCount);
        setTotalRecords(response?.data?.totalCount);
        setCount(1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows, searchQuery]);

  const dispatch = useDispatch();
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);

  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };
  const [driversCount, setDriversCount] = useState<any>({});

  const getCounts = async () => {
    try {
      const result = await getData(end_points.driver_assign_unassign_count.url);
      setDriversCount({
        assignedStatusCount: result.data.assignedStatusCount,
        unassignedStatusCount: result.data.unassignedStatusCount,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCounts();
  }, []);
  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">Drivers</h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Drivers</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Drivers */}
          <div className="drivers-info">
            {/* Drivers Info */}
            <div className="row">
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card drivers-card primary-border w-100">
                  <div className="card-body">
                    <div className="drivers-card-details">
                      <div className="drivers-text">
                        <p>All Drivers</p>
                        <h5>{totalRecords || 0}</h5>
                      </div>
                      <div className="drivers-icon">
                        <span className="bg-primary-icon">
                          <i className="ti ti-user-star" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card drivers-card success-border w-100">
                  <div className="card-body">
                    <div className="drivers-card-details">
                      <div className="drivers-text">
                        <p>Assigned Drivers</p>
                        <h5>{driversCount.assignedStatusCount || 0}</h5>
                      </div>
                      <div className="drivers-icon">
                        <span className="bg-success-icon">
                          <i className="ti ti-user-up" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card drivers-card danger-border w-100">
                  <div className="card-body">
                    <div className="drivers-card-details">
                      <div className="drivers-text">
                        <p>Unassigned</p>
                        <h5>{driversCount.unassignedStatusCount || 0}</h5>
                      </div>
                      <div className="drivers-icon">
                        <span className="bg-danger-icon">
                          <i className="ti ti-user-x" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="card drivers-card bg-primary w-100">
                  <div className="card-body">
                    <div className="create-driver-info">
                      <Link
                        onClick={() => {
                          if (planData?.driver_companyplan == totalRecords) {
                            toast.warning("Kindly upgrade plan to add more");
                          } else {
                            handleOffcanvas();
                          }
                        }}
                        to="#"
                      >
                        <i className="ti ti-circle-plus" /> Create Drivers
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Drivers Info */}
            {/* Sub Header */}
            <div className="sub-header">
              <div className="sub-header-content">
                <ul className="nav">
                  <li>
                    <h4>All Drivers ({totalRecords || 0})</h4>
                  </li>
                </ul>
              </div>
            </div>
            {/* /Sub Header */}
            {/* Table */}
            <div className="card table-card">
              <div className="card-body">
                <div className="table-responsive prime-table">
                  <PrimeDataTable
                    column={columns}
                    setSearchQuery={setSearchQuery}
                    data={listData}
                    rows={rows}
                    setRows={setRows}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalRecords={totalRecords}
                  />
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
          {/* /Drivers */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {offcanvas && <AddEditDrivers successcall={getList} />}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this driver?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
    </>
  );
};

export default DriversList;
