import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { email, location, phone } from "../../../../utils/imagepath";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../utils/patterns/regex.pattern";
import { ConfirmDialog } from "primereact/confirmdialog";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../utils/shared.module";
import { toast } from "react-toastify";

const ViewProduct = ({ editValue, handleOffcanvas, setType, getList }: any) => {
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const userDetails = useSelector((state: any) => state.login.userDetails);
  const [imageArray, setImageArray] = useState([]);
  const [visible, setVisible] = useState(false);
  const { putData } = useContext(ApiServiceContext);

  const accept = () => {
    if (editValue) {
      deletePlan(editValue.prod_id);
    }
  };

  const deletePlan = async (planId: number) => {
    try {
      let url = end_points.delete_product.url;
      url += `?id=${planId}&deleted_by=${userDetails?.courseadmin_id}`;
      const response = await putData(url);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        getList();
        handleOffcanvas();
      }
    } catch (error) {}
  };

  const reject = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (Object.keys(editValue)?.length > 0) {
      setImageArray(JSON.parse(editValue?.product_image));
    }
  }, [editValue]);

  return (
    <>
      <div
        className={`truck-list-sidebar ${
          offcanvas ? "truck-list-sidebar-open" : ""
        }`}
      >
        <div className={`popup-canvas popup-canvas-view offcanvas-end`}>
          <div className="card">
            <div className="card-header">
              <h4 id="offcanvasRightLabel" className="card-title">
                View Product
              </h4>
              <Link
                to="#"
                className="clear-truck-list"
                onClick={handleOffcanvas}
              >
                <i className="fas fa-xmark" />
              </Link>
            </div>
            <div className="card-body">
              <div className="truck-information p-0 mb-0">
                <div className="course-act-list">
                  <Link
                    to="#"
                    className="edit-product-cls"
                    onClick={() => setType("Edit")}
                  >
                    <i className="ti ti-edit"></i> Edit
                  </Link>
                  <Link
                    to="#"
                    className="delete-product-cls"
                    onClick={() => setVisible(true)}
                  >
                    <i className="ti ti-trash"></i> Delete
                  </Link>
                </div>
                <div className="course-view-grid">
                  <div className="course-view-hdr">
                    <div className="course-hdr">
                      <h6>Product ID - {editValue?.prod_refid}</h6>
                      <span className="badge status-food">
                        {editValue?.prod_typename}
                      </span>
                    </div>
                    <div className="course-info-list">
                      <ul className="text-muted">
                        <li>
                          <span>{imageArray?.length} Images</span>
                        </li>
                        <li>
                          <span>{formatDate(editValue?.created_at)}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="course-view-body">
                    <h5>"{editValue?.prod_title}"</h5>
                    <div>
                      <p className="essential-para mb-3">
                        {editValue?.product_desc}
                      </p>
                    </div>
                    {imageArray?.map((item: any, index: any) => (
                      <div className="col-md-6" key={index}>
                        <img
                          src={`https://api.thecarai.ai${item}`}
                          alt="img"
                          className="w-100 prod-liimg"
                        />
                      </div>
                    ))}
                    <div className="sidebar-sub-header mt-3">
                      <h6>Other Details</h6>
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <span>
                          <img src={location} alt="" />
                        </span>
                        <div>
                          <h6 className="mb-1">Location</h6>
                          <p>{editValue?.contact_address}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span>
                          <img src={email} alt="" />
                        </span>
                        <div>
                          <h6 className="mb-1">Email</h6>
                          <p>{editValue?.contact_email}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span>
                          <img src={phone} alt="" />
                        </span>
                        <div>
                          <h6 className="mb-1">Contact Number</h6>
                          <p>{editValue?.contact_number}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this product?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
        draggable={false}
      />
    </>
  );
};

export default ViewProduct;
