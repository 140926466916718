import parsePhoneNumberFromString from "libphonenumber-js";

export const alphaNumeric = /^[a-zA-Z0-9 ]+$/;
export const onlyAlphabet = /^[A-Za-z\s]+$/;
export const onlyNumber = /^[0-9]/;
export const testtt = /^\d+(\.\d{1,2})?$/;
export const numberWithDecimal = /^[0-9]*\.?[0-9]+$/;

export const userNameRegex = /^[a-zA-Z0-9@. ]+$/;
export const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z0-9\-]+\.)+[a-z]{2,6}))$/;
export const passwordPattern =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~`!@#$%^&*()\-_=+\{\}\[\]|\\;:"<>,./?]).{5,12}$/;
export const drivingLicence = /^[a-zA-Z0-9- ]+$/;

export const addressMaxLength = 100;
export const nameMaxLength = 30;
export const userNameMinLength = 5;
export const emailMaxLength = 60;
export const passwordMinLength = 5;
export const passwordMaxLength = 12;
export const pincodeMaxLength = 6;
export const aadharMaxLength = 12;
export const licenceMaxLength = 13;
export const vehicleMaxLength = 20;
export const licence_plate_number = 10;
export const VIN_LENGTH = 17;
export const VEHICLE_MAKE_LENGTH = 20;
export const VEHICLE_MODAL_LENGTH = 20;
export const DESCRIPTION_LENGTH = 255;
export const MAX_AMOUNT_LENGTH = 12; 
export const pricePerMonth = 6; 
export const pricePerYear = 7; 
export const planNumber = 3; 



export const validImageTypes = [
  'image/png',
  'image/jpeg',
  'image/svg',
  'image/gif',
  'image/jpg',
];

// message
export const validMessage = {
  onlyAlphabet: "Only alphabets and spaces are allowed",
  nameMaxLength: "Maximum characters reached",
  email: "Please enter your valid email address",
};


export const formatDate = (dateString: string) => {
  const dateObject = new Date(dateString);

  const day = String(dateObject.getDate()).padStart(2, '0');
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); 
  const year = dateObject.getFullYear();

  return `${month}-${day}-${year}`;
};

export const convertToDate = (dateString: string) => {
  const date = new Date(dateString);
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');

  return `${yyyy}-${mm}-${dd}`;
};