import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import { avatar02 } from "../../../../utils/imagepath";
import { end_points } from "../../../../services/end_point/end_points";
import { ApiServiceContext } from "../../../../utils/shared.module";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../../../utils/patterns/regex.pattern";
import { toast } from "react-toastify";

const Notification = () => {
  const { getData, putData } = useContext(ApiServiceContext);
  const userDetails: any = useSelector((state: any) => state.login.userDetails);
  const [listData, setListData] = useState<any[]>([]);
  const getNotifications = async () => {
    try {
      let url =
        userDetails?.cour_type === "super_admin"
          ? end_points.get_super_notification.url
          : userDetails?.cour_type === "Product" || "Course"
          ? end_points.get_course_notification.url
          : end_points.get_notification.url;
      if (userDetails?.user_id) {
        url += `/${userDetails?.user_id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        const data = response?.data?.data?.notification;
        setListData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const readNotifications = async () => {
    try {
      let url =
        userDetails?.cour_type === "super_admin"
          ? end_points.read_super_notification.url
          : userDetails?.cour_type === "Product" || "Course"
          ? end_points.read_course_notification.url
          : end_points.read_notification.url;
      if (userDetails?.id) {
        url += `?id=${userDetails?.id}`;
      }
      const response = await putData(url);
      toast.success(response?.data?.response?.responseMessage);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNotifications();
  }, [userDetails]);

  return (
    <div className="dropdown-menu dropdown-menu-end notification-dropdown">
      <div className="notification-grid">
        <div className="notification-head">
          <div className="notification-head-left">
            <h5>Notification ({listData?.length})</h5>
          </div>
          <div className="notification-head-right">
            <Link to="#" onClick={readNotifications}>
              Mark as all read<i className="far fa-check-circle"></i>
            </Link>
            <Link to={routes.notifications.path} className="fclrblue ms-2">
              View All
            </Link>
          </div>
        </div>
        <div className="notification-body">
          {listData?.length > 0 ? (
            listData?.map(
              (item: any, index: any) =>
                index < 3 && (
                  <div
                    className={`notification-list ${
                      item?.is_read ? "active" : ""
                    }`}
                    key={index}
                  >
                    <div className="notification-left">
                      <div className="notification-avatar">
                        <img
                          src={
                            item?.profileimage ? item?.profileimage : avatar02
                          }
                          alt=""
                        />
                      </div>
                      {item?.is_read && (
                        <span className="notification-status"></span>
                      )}
                    </div>
                    <div className="notification-right">
                      <div className="notification-grid-row-hdr">
                        <h5>{item?.firstName}</h5>
                        {/* <span className="badge status-inprogress">Driver</span> */}
                      </div>
                      <div className="notification-grid-row-content">
                        <p>{item?.notif_message}</p>
                      </div>
                      <div className="notification-grid-row-footer">
                        <span className="text-muted">
                          {formatDateTime(item?.notif_date)}
                        </span>
                      </div>
                    </div>
                  </div>
                )
            )
          ) : (
            <></>
          )}
        </div>
        <div className="notification-footer">
          <Link to={routes.notifications.path}>
            View All Notification
            <i className="fas fa-chevron-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Notification;
