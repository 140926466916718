interface EndPoint {
  url?: string;
  list?: string;
  add?: string;
  edit?: string;
  delete?: string;
  service?: string;
}

export const end_points: Record<string, EndPoint> = {
  // Authentication
  verify_domain: { url: "/authentication/check-domain" },
  login: { url: "/Superadmin/login" },
  admin_login: { url: "/authentication/login" },
  course_login: { url: "/CourseAdmin/login" },
  admin_otp_verify: { url: "/authentication/verify_login_otp" },
  otp_verify: { url: "/Superadmin/verify_login_otp" },
  course_otp_verify: { url: "/CourseAdmin/verify_login_otp" },
  super_admin_reset: { url: "/Superadmin/reset_password" },
  admin_reset: { url: "/authentication/reset_password" },
  course_reset: { url: "/CourseAdmin/reset_password" },
  super_admin_forgotpwd: { url: "/Superadmin/forgot_password" },
  admin_forgotpwd: { url: "/authentication/forgot_password" },
  course_forgotpwd: { url: "/CourseAdmin/forgot_password" },
  super_admin_otp: { url: "/Superadmin/request_otp" },
  admin_otp: { url: "/authentication/request_otp" },
  course_otp: { url: "/CourseAdmin/request_otp" },
  otp_super_admin: { url: "/Superadmin/verify_otp" },
  otp_admin: { url: "/authentication/verify_otp" },
  otp_course: { url: "/CourseAdmin/verify_otp" },
  create_tutorial: { url: "/Tutorial/create_tutorial" },
  update_tutorial: { url: "/Tutorial/update_tutorial" },
  create_priceplan: { url: "/PricingPlan/create_priceplan" },
  update_priceplan: { url: "/PricingPlan/update_priceplan" },
  best_priceplan: { url: "/PricingPlan/best_priceplan" },
  get_companieslist: { url: "/Company/get_companies" },
  get_companycount: { url: "/Company/counts-active-inactive" },
  get_pricelist: { url: "/PricingPlan/get_priceplan" },
  update_companystatus: {
    url: "/Company/update_status/company/id",
  },
  update_tutorialstatus: {
    url: "/Tutorial/update_status/tutorial/id",
  },
  get_companies: { url: "/Company/companypaging" },
  tutorial_paging: { url: "/Tutorial/tutorial_paging" },
  registered_companies: { url: "Company/companyallcount" },
  pricing_plans: { url: "/PricingPlan/active-count" },
  active_status: { url: "/Company/counts-active-inactive" },
  update_planstatus: { url: "/PricingPlan/update_status/plan/id" },
  delete_plan: { url: "/PricingPlan/deleteprice" },
  delete_tutorial: { url: "/Tutorial/deletetutorial" },
  update_truck: { url: "/truck/update_truck" },
  get_truck: { url: "/truck/get_truck" },
  create_truck: { url: "/truck/create_truck" },
  delete_truck: { url: "/truck/deletetruck" },
  update_truck_status: { url: "/truck/update_status/truck/id" },
  vehicle_paging: { url: "/truck/vehiclepaging" },
  getFleetmanager: { url: "/User/get_userallfleet" },
  create_fleetmanager: { url: "/User/create_fleet" },
  update_fleetmanager: { url: "/User/update_fleet" },
  common_status_update: { url: "/User/update_status/userid" },
  get_all_drivers: { url: "/User/get_useralldrivers" },
  create_driver: { url: "/User/create_driver" },
  update_driver: { url: "/User/update_driver" },
  delete_any_user: { url: "/User/deleteuser" },
  get_truckby_id: { url: "/truck/get_truckby_id" },
  get_userservicemanager: { url: "/User/get_userservicemanager" },
  create_service: { url: "/User/create_service" },
  delete_service: { url: "/User/deleteuser" },
  update_service_status: { url: "/User/update_status/userid" },
  update_service: { url: "/User/update_service" },
  get_vehicletype: { url: "/truck/get_vehicletype" },
  get_trip: { url: "/Trip/get_trippaging" },
  get_trip_tracking: { url: "/Trip/get_triplogbyid" },
  create_trip: { url: "/Trip/create_trip" },
  update_trip: { url: "/Trip/update_trip" },
  delete_trip: { url: "/Trip/deletetrip" },
  assign_drivers: { url: "/User/get_alldrivers" },
  assign_new_vechicle: { url: "/Trip/update_assignnewvehicle" },
  get_inspection_list: { url: "/checklist/webinspection_list" },
  add_service: { url: "/Repair/create_repair" },
  get_allservicemanager: { url: "/User/get_allservicemanager" },
  get_all_vehicle: { url: "/truck/get_allvehiclefortrip" },
  get_repairpaging: { url: "/Repair/get_repairpaging" },
  getInpectionDetailsApi: { url: "/checklist/get_Inspectiobyid" },
  get_trip_all_vehicle: { url: "/truck/get_allvehiclefortripedit" },
  get_trip_all_driver: { url: "/user/get_alldriversforedit" },
  get_admin_dashboard_metrics: { url: "/truck/dashboard_count" },
  driver_assign_unassign_count: { url: "/User/driver_assign_unassign_count" },
  vehicle_assign_unassign_count: { url: "/truck/assign_unassign_count" },
  get_InspectionStatus: { url: "/Repair/get_inspectionservice" },
  get_RepairComponents: { url: "/InspectionComponent/get_inspection_components" },
  get_RepairTracking: { url: "/Repair/get_repairlogbyid" },
  get_RepairById: { url: "/Repair/get_repairById" },
  update_RepairComponent: { url: "/Repair/update_RepairComponent" },
  update_RepairStatus: { url: "/Repair/update_RepairStatus" },
  update_RepairSummary: { url: "/Repair/update_summary" },
  get_Announcement: { url: "/Repair/get_Announce" },
  get_fleet_dashboard: { url: "/User/fleet_dashboardcounts" },
  get_service_dashboard: { url: "/User/service_dashboardcounts" },
  get_admin_chart: { url: "/checklist/admin_weeklycounts" },
  get_service_chart: { url: "/User/service_dashboardcounts" },
  get_courseAdmin: { url: "/Course/courseadminpaging" },
  create_courseAdmin: { url: "/Course/create_courseadmin" },
  update_courseAdmin: { url: "/Course/update_courseadmin" },
  delete_courseAdmin: { url: "/Course/delete_courseadmin" },
  update_status_courseAdmin: { url: "/Course/update_status/courseadmin" },
};
