const PrivacyPolicy = () => {
  return (
    <div className="page-wrapper custom-wrapper">
      <div className="content container">
        <div className="row">
          <h4 className="mb-4">Privacy Policy</h4>
          <h5 className="mb-2">1. Introduction</h5>
          <div className="mb-3">
            Your privacy is important to us. This privacy statement explains the
            personal data Core Four Productions, Corp. processes, how Core Four
            Productions, Corp. processes it, and for what purposes. Welcome to
            [Core Four Productions, Corp.] (“Company”, “we”, “our”, “us”). We
            are committed to protecting your personal information and your right
            to privacy. This Privacy Policy explains how we collect, use,
            disclose, and safeguard your information when you use our SaaS fleet
            management application (the “Service”).
          </div>

          <h5 className="mb-2">2. Information We Collect</h5>
          <ul className="mb-3 list-style-custom">
            <li>
              <b>Personal Information:</b> We may collect personal information
              such as your name, email address, phone number, and payment
              information.
            </li>
            <li>
              <b>Usage Data:</b> We collect information about your interactions
              with the Service, such as the pages you visit, the features you
              use, and the actions you take.
            </li>
            <li>
              <b>Device Information:</b> We collect information about the
              devices you use to access the Service, including IP address,
              browser type, and operating system.
            </li>
          </ul>

          <h5 className="mb-2">3. How We Use Your Information</h5>
          <div className="mb-2">We use the information we collect to:</div>
          <ul className="mb-3 list-style-custom">
            <li>Provide, operate, and maintain the Service</li>
            <li>Improve, personalize, and expand the Service</li>
            <li>Understand and analyze how you use the Service</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the Service, and for
              marketing and promotional purposes
            </li>
            <li>Process your transactions and manage your orders</li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>

          <h5 className="mb-2">4. Sharing Your Information</h5>
          <div className="mb-2">We may share your information with:</div>

          <ul className="mb-3 list-style-custom">
            <li>
              <b>Service Providers:</b> We may share your information with
              third-party vendors, service providers, contractors, or agents who
              perform services on our behalf.
            </li>
            <li>
              <b>Business Transfers:</b> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
            <li>
              <b>Legal Requirements:</b> We may disclose your information where
              we are legally required to do so in order to comply with
              applicable law, governmental requests, a judicial proceeding,
              court order, or legal process.
            </li>
          </ul>

          <h5 className="mb-2">5. Security of Your Information</h5>
          <div className="mb-3">
            We use administrative, technical, and physical security measures to
            help protect your personal information. While we have taken
            reasonable steps to secure the personal information you provide to
            us, please be aware that despite our efforts, no security measures
            are perfect or impenetrable, and no method of data transmission can
            be guaranteed against any interception or other type of misuse.
          </div>

          <h5 className="mb-2">6. Your Data Protection Rights</h5>
          <div className="mb-2">
            Depending on your location, you may have the following rights
            regarding your personal information:
          </div>
          <ul className="mb-3 list-style-custom">
            <li>
              The right to access – You have the right to request copies of your
              personal data.
            </li>
            <li>
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate or complete
              information you believe is incomplete.
            </li>
            <li>
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
            </li>
            <li>
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </li>
            <li>
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
            </li>
            <li>
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </li>
          </ul>

          <h5 className="mb-2">7. Changes to This Privacy Policy</h5>
          <div className="mb-3">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </div>

          <h5 className="mb-2">8. Contact Us</h5>
          <div className="mb-2">
            If you have any questions about this Privacy Policy, please contact
            us at:
          </div>
          <ul className="mb-3 list-style-custom">
            <li>Email: info@core4productions.com</li>
            <li>Address: 405 TX-121 Lewisville, TX 75067</li>
          </ul>

          <h5 className="mb-2">9. Cookies and Tracking Technologies</h5>
          <h6 className="mb-2">9.1 What Are Cookies?</h6>
          <div className="mb-3">
            Sites use cookies, small text files placed on your device which web
            servers utilize in the domain that placed the cookie can retrieve
            later. They are widely used to make websites work, or work more
            efficiently, as well as to provide information to the owners of the
            site. We use cookies to store your preferences and settings, help
            with sign-in, provide personalized ads, and analyze site operations.
          </div>

          <h6 className="mb-2">9.2 How We Use Cookies</h6>
          <div className="mb-3">
            We use cookies and similar tracking technologies to track the
            activity on our Service and hold certain information. Cookies help
            us to:
          </div>
          <ul className="mb-3 list-style-custom">
            <li>Understand and save your preferences for future visits</li>
            <li>Keep track of advertisements</li>
            <li>
              Compile aggregate data about site traffic and site interactions in
              order to offer better site experiences and tools in the future
            </li>
          </ul>

          <h6 className="mb-2">9.3 Types of Cookies We Use</h6>
          <ul className="mb-3 list-style-custom">
            <li>
              <b>Essential Cookies:</b> These cookies are necessary for the
              website to function and cannot be switched off in our systems.
              They are usually only set in response to actions made by you which
              amount to a request for services, such as setting your privacy
              preferences, logging in, or filling in forms.
            </li>
            <li>
              <b>Performance Cookies:</b> These cookies allow us to count visits
              and traffic sources so we can measure and improve the performance
              of our site. They help us to know which pages are the most and
              least popular and see how visitors move around the site.
            </li>
            <li>
              <b>Functional Cookies:</b> These cookies enable the website to
              provide enhanced functionality and personalization. They may be
              set by us or by third-party providers whose services we have added
              to our pages.
            </li>
            <li>
              <b>Targeting Cookies:</b> These cookies may be set through our
              site by our advertising partners. They may be used by those
              companies to build a profile of your interests and show you
              relevant adverts on other sites.
            </li>
          </ul>

          <h6 className="mb-2">9.4 Managing Cookies</h6>
          <div className="mb-2">
            You can choose to have your computer warn you each time a cookie is
            being sent, or you can choose to turn off all cookies. You do this
            through your browser settings. Since each browser is a little
            different, look at your browser’s Help Menu to learn the correct way
            to modify your cookies.
          </div>
          <div className="mb-3">
            If you turn cookies off, some features will be disabled. It won’t
            affect the user experience that makes your site experience more
            efficient and may not function properly.
          </div>

          <h6 className="mb-2">9.5 Third-Party Cookies</h6>
          <div className="mb-3">
            In some special cases, we also use cookies provided by trusted third
            parties.
          </div>

          <h6 className="mb-2">9.6 More Information</h6>
          <div className="mb-3">
            If you have any questions about our use of cookies or other
            technologies, please contact us at info@core4productions.com.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
