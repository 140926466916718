import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  truck06,
  truck02,
  signatureImg,
  noImg,
  notes,
} from "../../../../utils/imagepath";
import { routes } from "../../../../utils/routes";
import { RepairDetail } from "../../../../components/json/repair-detail";
import PrimeDataTable from "../../../../components/data-table";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { end_points } from "../../../../services/end_point/end_points";
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from "../../../../utils/shared.module";
import { Calendar } from "primereact/calendar";
import { Controller, useForm } from "react-hook-form";
import {
  convertToDate,
  DESCRIPTION_LENGTH,
} from "../../../../utils/patterns/regex.pattern";
import { toast } from "react-toastify";
import {
  setAddCanvasSidebar,
  setAddCanvasViewSidebar,
} from "../../../../core/redux/sidebarSlice";

declare global {
  interface Window {
    bootstrap: any;
  }
}
const InvoiceView = () => {
  const [listData, setListData] = useState<any>([]);
  const [rows, setRows] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [repairTrackingData, setGeneratedInvoiceData] = useState<any[]>([]);
  const [repairViewData, setRepairViewData] = useState<any>({});
  const navigate = useNavigate();
  const { getData, putData } = useContext(ApiServiceContext);
  const role = useSelector((state: any) => state.login.role);
  const Data = useSelector((state: any) => state.common.generatedInvoiceData);
  //const viewData = useSelector((state: any) => state.common.repairViewData);
  const login_id = useSelector((state: any) => state.login.userDetails.id);
  const loginusername = useSelector(
    (state: any) => state.login.userDetails.name
  );
  const loginusereamil = useSelector(
    (state: any) => state.login.userDetails.email
  );
  const loginuserphone = useSelector(
    (state: any) => state.login.userDetails.phone_number
  );
  const modelRef = useRef<any>(null);
  const closeRef = useRef<any>(null);
  const dispatch = useDispatch();
  const offcanvasview = useSelector(
    (state: any) => state.sidebar.addCanvasViewSidebar
  );
  const handleOffcanvas = () => {
    dispatch(setAddCanvasViewSidebar(!offcanvasview));
  };
  const getInvoiceById = async () => {
    try {
      let url = end_points.get_InvoiceById.url;
      url += `?invoice_id=${Data.invoice_Id}`;
      const response = await getData(url);

      if (response.status === 200) {
        const invoiceData = response.data.invoice; // Correctly accessing 'invoice'

        // Update state with invoice data
        setRepairViewData(invoiceData);

        // Update listData with repair_components
        setListData(invoiceData.repair_components);

        // Optionally set repair status if needed
        // setRepairStatus(invoiceData.invoice_status);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const getInvoiceTracking = async () => {
    try {
      let url = end_points.get_invoiceTracking.url;
      url += `?invoiceid=${Data.invoice_Id}`;
      const response = await getData(url);
      if (response.status === 200) {
        // repairTrackingData(response?.data?.data?.trip);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getInvoiceTracking();
    getInvoiceById();
  }, []);

  const formatDate = (date: any) => {
    return new Date(date)
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-");
  };
  return (
    <>
      <div
        className={`truck-list-sidebar ${
          offcanvasview ? "truck-list-sidebar-open" : ""
        }`}
      >
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body card-bodybt">
                <div className="invoice-grid">
                  <div className="invoice-hdr">
                    <div className="invoice-hdr-left">
                      <img src={noImg} alt="" />
                      <h4>INVOICE - {repairViewData?.invoice_refid}</h4>
                    </div>
                    <div className="invoice-hdr-right">
                      <div className="invoice-status-bg">
                        <strong>Total Amount : ${repairViewData?.total}</strong>
                        <div>
                          Status :{" "}
                          <span className="badge status-inprogress">
                            {repairViewData?.invoice_status}
                          </span>
                        </div>
                        <div>
                          Date :{" "}
                          {repairViewData?.invoice_date
                            ? formatDate(repairViewData.invoice_date)
                            : ""}
                        </div>
                      </div>
                    </div>
                    <Link
                      to="#"
                      className="clear-truck-list"
                      onClick={handleOffcanvas}
                    >
                      <i className="fas fa-xmark"></i>
                    </Link>
                  </div>
                  <div className="invoice-row invoice-row-bb">
                    <div className="d-flex justify-content-between">
                      <div className="invoice-st">
                        Service Type -{" "}
                        <strong>{repairViewData?.service_type}</strong>
                      </div>
                      <div className="invoice-dd">
                        Due Date -{" "}
                        <strong>
                          {repairViewData?.due_date
                            ? formatDate(repairViewData.due_date)
                            : ""}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-row invoice-row-bb">
                    <div className="d-flex justify-content-between">
                      <div className="invoice-address">
                        <div className="invoice-ft">From</div>
                        <strong>{loginusername}</strong>
                        <p className="mb-0">Email : {loginusereamil}</p>
                        <p className="mb-0">Phone Number : {loginuserphone}</p>
                      </div>
                      <div className="invoice-address">
                        <div className="invoice-ft">To</div>
                        <strong>{repairViewData?.send_to_user_name}</strong>
                        <p className="mb-0">
                          Email : {repairViewData?.send_to_user_email}
                        </p>
                        <p className="mb-0">
                          Phone Number : {repairViewData?.send_to_user_phone}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-row">
                    <table className="table table-center add-table-items">
                      <thead>
                        <tr>
                          <th>Repair Item</th>
                          <th>Cost</th>
                          <th className="text-end">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {repairViewData?.repair_components?.map(
                          (
                            item: {
                              inv_repairid: number;
                              component: string;
                              cost: number;
                              amount: number;
                            },
                            index: number
                          ) => (
                            <tr key={item.inv_repairid}>
                              <td>{item.component}</td>{" "}
                              {/* Display the component name */}
                              <td>
                                <span className="text-muted">
                                  $
                                  {typeof item.cost === "number"
                                    ? item.cost.toFixed(2)
                                    : "0.00"}
                                </span>
                              </td>{" "}
                              {/* Display the cost */}
                              <td className="text-end">
                                $
                                {typeof item.amount === "number"
                                  ? item.amount.toFixed(2)
                                  : "0.00"}
                              </td>{" "}
                              {/* Display the amount */}
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="invoice-row">
                    <ul className="invoice-price">
                      <li>
                        <span className="invoice-price-lbl text-muted">
                          Subtotal
                        </span>
                        <span className="invoice-price-val">
                          ${repairViewData?.sub_amount?.toFixed(2)}
                        </span>
                      </li>
                      <li>
                        <span className="invoice-price-lbl text-muted">
                          Tax
                        </span>
                        <span className="invoice-price-val">
                          $
                          {(
                            (repairViewData?.total || 0) -
                            (repairViewData?.sub_amount || 0)
                          ).toFixed(2)}
                        </span>
                      </li>
                      <li>
                        <span className="invoice-price-lbl">
                          <b>Total</b>
                        </span>
                        <span className="invoice-price-val">
                          <b>${repairViewData?.total?.toFixed(2)}</b>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="invoice-row">
                    <div className="d-flex justify-content-between">
                      <div className="invoice-notes">
                        <div className="invoice-notes-icon">
                          <img src={notes} alt="" />
                        </div>
                        <div className="invoice-notes-val">
                          <strong className="d-block">Note</strong>
                          <p>{repairViewData?.notes_term} </p>
                        </div>
                      </div>
                      <div className="invoice-sign">
                        <p>Authorized Sign</p>
                        <img
                          src={repairViewData?.authorised_sign}
                          alt=""
                          style={{ height: "100px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvoiceView;
