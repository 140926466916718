import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";
import { avatar30, noImg } from "../../../../utils/imagepath";
import { useState, useContext, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { yup, ApiServiceContext } from "../../../../utils/shared.module";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  email,
  nameMaxLength,
  emailMaxLength,
  validImageTypes,
  addressMaxLength,
  convertToDate,
  formatDate,
  alphaNumeric,
} from "../../../../utils/patterns/regex.pattern";
import { Calendar } from "primereact/calendar";
import { end_points } from "../../../../services/end_point/end_points";
import { toast } from "react-toastify";
import {
  clearEditData,
  clearGeneratedInvoiceData,
} from "../../../../core/redux/commonSlice";
import { AnyARecord } from "dns";

const validationSchema = Yup.object().shape({
  startDate: yup.date().required("Invoice date is required"),
  endDate: yup.date().required("Due date is required"),
  invoice_refid: yup.string().trim().required("Invoice Number is required"),
  bill_to: Yup.string().required("Billing to is required"),
  notes_term: yup.string().trim(),
  // Validate components array
  components: yup
    .array()
    .of(
      yup.object().shape({
        component: yup.string().trim().required("Component is required"),
        cost: yup
          .number()
          .required("Cost is required")
          .integer("Must be an integer"),
        amount: yup
          .number()
          .required("Amount is required")
          .integer("Must be an integer"),
      })
    )
    .required("At least one component is required"),
  document: Yup.mixed()
    .nullable() // If the document is optional
    .test(
      "fileType",
      "Invalid file format. Please upload a jpg, jpeg, png, pdf, doc, docx file.",
      (value: any) => {
        if (!value) return true; // If no file, it's optional, so validation passes
        const validFileTypes = [
          "image/jpeg",
          "image/png",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        return validFileTypes.includes(value.type);
      }
    )
    .test(
      "fileSize",
      "File size is too large. Maximum allowed size is 50MB.",
      (value: any) => {
        if (!value) return true; // If no file, validation passes
        return value && value.size <= 50 * 1024 * 1024; // Maximum file size 50MB
      }
    ),
  // logo: yup
  //   .mixed()
  //   .test("image.value", "Please upload an image", (value: any) => {
  //     if (value == "") {
  //       return false;
  //     } else return true;
  //   })
  //   .test(
  //     "image.type",
  //     "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
  //     (value: any) => {
  //       if (value == "") {
  //         return false;
  //       }
  //       if (!value || !value.length || typeof value === "string") {
  //         // Skip validation if the field is empty
  //         return true;
  //       }
  //       return value && validImageTypes?.includes(value[0]?.type);
  //     }
  //   )
  //   .test("fileSize", "File size is too large", (value: any) => {
  //     if (value == "") {
  //       return false;
  //     }
  //     if (!value || !value.length || typeof value === "string") {
  //       // Skip validation if the field is empty
  //       return true;
  //     }
  //     return value && value[0].size <= 4194304;
  //   })
  //   .required("Profile image is required"),
});

const GenerateInvoice = (props: any) => {
  const [start_date, setStartDate] = useState<Date | null>(null);
  const [end_Date, setEndDate] = useState<Date | null>(null);
  const [isEndDateDisabled, setIsEndDateDisabled] = useState(true);
  const { postData, putData, getData } = useContext(ApiServiceContext);
  const dispatch = useDispatch();

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const handleOffcanvas = () => {
    dispatch(setAddCanvasSidebar(!offcanvas));
  };
  const [percentage, setPercentage] = useState(0);
  const data = useSelector((state: any) => state.common.repairInvoiceData);

  const [subtotal, setSubtotal] = useState(0); // Assume this comes from your components' amounts
  const [taxInput, setTaxInput] = useState(0); // Input value for tax (percentage or fixed)
  const [taxType, setTaxType] = useState("percentage"); // Either "percentage" or "amount"
  const [taxOptions, setTaxOptions] = useState([]); // State to store dropdown options
  const [total, setTotal] = useState(0); // Total after tax
  const [taxAmount, setTaxAmount] = useState(0); // Holds calculated tax value
  const [selectedTaxTypeId, setSelectedTaxTypeId] = useState<number | null>(
    null
  );

  const user_id = useSelector((state: any) => state.login.userDetails.id);
  const editdataf = useSelector(
    (state: any) => state.common.generatedInvoiceData
  );
  const editdata = editdataf?.data?.invoice;
  const invoice_Id = editdata?.invoice_Id;
  const repair_refid = editdata?.repair_refid;
  const repairId = editdata?.repair_Id || data?.repair_System?.repair_Id;
  const sendTo = editdata?.send_to || data?.created_by;
  const createdByName = editdata?.send_to_user_name || data?.created_by_name;
  const createdByImage = editdata?.send_to_user_image || data?.created_by_image;
  const createdByAddress =
    editdata?.send_to_user_address || data?.created_by_address;
  const tax_type = editdata?.tax_type;


  type PreviewState = {
    image: string | null;
    fileName: string | null;
    fileSize: string | null;
  };

  const [preview, setPreview] = useState<PreviewState>({
    image: null,
    fileName: "",
    fileSize: "",
  });

  type ComponentItem = {
    component: string;
    cost: number;
    amount: number;
  };

  type FormValues = {
    startDate: Date | null;
    endDate: Date | null;
    invoice_refid: string;
    bill_to: string;
    components: {
      component: string;
      cost: number;
      amount: number;
    }[];
    // logo: File | string | null; // Assuming logo is either a file or a URL
    notes_term: string;
    document: File | null;
  };

  const components = JSON.parse(data?.repair_System?.components || "[]").map(
    (item: any) => ({
      component: item.component || "",
      cost: item.cost || 0, // Default to 0 if undefined
      amount: item.amount || 0, // Default to 0 if undefined
    })
  );

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver<any>(validationSchema), // Ensure this matches the new schema
    defaultValues: {
      invoice_refid: "",
      startDate: start_date,
      endDate: null,
      components, // Default to parsed components
      // logo: null,
      notes_term: "",
      document: null,
      bill_to: createdByAddress || "test address",
    },
  });


  const { fields, append, remove } = useFieldArray({
    control,
    name: "components",
  });

  const watchedComponents = useWatch({
    control,
    name: "components", // Watch the components array
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      // setValue("logo", file, { shouldValidate: true });

      setPreview({
        image: URL.createObjectURL(file),
        fileName: file.name,
        fileSize: (file.size / 1024).toFixed(2), // File size in KB
      });
    }
  };
  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue("document", file, { shouldValidate: true }); // Set the file in the form state
    }
  };
  const getTaxType = async () => {
    try {
      let url = end_points.get_TaxType.url; // Ensure correct URL
      const response = await getData(url);

      if (response.status === 200 && response.data.data.amount_type) {
        // Dynamically map all returned amount types to Dropdown options
        const fetchedTaxOptions = response.data.data.amount_type.map(
          (type: any) => ({
            label: type.amounttype_name, // Use amounttype_name for label
            value: type.amounttype_id, // Use the name or id as the value, here converted to lowercase
          })
        );
        setTaxOptions(fetchedTaxOptions); // Set dropdown options
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Main function to submit the invoice and create components
  const onSubmit = async (data: any) => {

    // Determine the URL for invoice creation or update
    const url = invoice_Id
      ? end_points.update_invoice.url
      : end_points.create_invoice.url;

    // Prepare formData for the API request
    const formData = {
      invoice_date: convertToDate(data.startDate),
      due_date: convertToDate(data.endDate),
      // logo: data.logo || "",
      document: data.document || null,
      notes_term: data.notes_term || "",
      invoice_refid: data.invoice_refid || "",
      sub_amount: subtotal.toString(),
      tax: taxAmount.toString(),
      total: total.toString(),
      repair_Id: repairId,
      send_to: sendTo,
      invoice_status: "4", // You can set the status accordingly
      created_by: user_id.toString(),
      updated_by: user_id.toString(),
      bill_to: "1",
      tax_type: selectedTaxTypeId ? selectedTaxTypeId.toString() : "",
      components: JSON.parse(data?.repair_System?.components || "[]").map(
        (item: any) => ({
          component: item.component || "",
          cost: item.cost || 0, // Default to 0 if undefined
          amount: item.amount || 0, // Default to 0 if undefined
        })
      ),
    };

   
    try {
      // Step 1: Create or update the invoice
      const response = invoice_Id
        ? await putData(url, formData)
        : await postData(url, formData);

      // Step 2: Handle the response and extract the invoice ID
      if (response?.status === 200 || response?.status === 201) {
        toast.success(response?.data?.response?.responseMessage);
        dispatch(setAddCanvasSidebar(!offcanvas));
        dispatch(clearGeneratedInvoiceData());
        props.successcall();

        // Extract invoice ID from the response based on the actual response structure
        const createdInvoiceId = response?.data?.data?.invoice?.invoice_Id;

        if (!createdInvoiceId) {
          toast.error(
            "Failed to retrieve invoice ID. Please check the API response."
          );
          return; // Exit early if no invoice ID
        }

        // Step 3: Create components only if the invoice was created successfully
        await createComponents(createdInvoiceId, data.components);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(
        "Failed to submit the invoice. Please check your inputs and try again."
      );
    }
  };

  // Separate function to create components for a given invoice ID
  const createComponents = async (invoiceId: number, components: any[]) => {
    try {
      const createComponentUrl = end_points.create_component.url;

      // Prepare payload by adding `invoiceId` to each component
      const payload = components.map((component) => ({
        ...component,
        invoiceId: invoiceId, // Add invoiceId to each component object
      }));


      // API call to create components
      const createComponentResponse = await postData(
        createComponentUrl,
        payload
      );

      if (
        createComponentResponse?.status === 200 ||
        createComponentResponse?.status === 201
      ) {
        toast.success("Invoice created successfully!");
      } else {
        console.error(
          "Component creation failed with response:",
          createComponentResponse
        );
        toast.error("Failed to create components. Please try again.");
      }
    } catch (error) {
      console.error("Error creating components:", error);
      toast.error(
        "An error occurred while creating components. Please check the payload and try again."
      );
    }
  };


  const startDate = watch("startDate");

  const handleStartDateChange = async (value: any) => {
    const startDate = new Date(value);
    const endDate = end_Date ? new Date(end_Date) : null;

    setStartDate(value);
    setValue("startDate", value);

    if (endDate && startDate && endDate < startDate) {
      setEndDate(null);
      setValue("endDate", null);
    }

    setIsEndDateDisabled(!value);
  };

  useEffect(() => {
    // Fetch tax type once when the component mounts
    getTaxType();
  }, []); // Only runs once

  // Calculate subtotal whenever watchedComponents changes
  useEffect(() => {
    const calcSubtotal = watchedComponents.reduce((acc, curr) => {
      const amount = parseFloat(curr.amount.toString()) || 0;
      return acc + amount;
    }, 0);

    if (isNaN(calcSubtotal)) {
      console.error(
        "Invalid calculation: Ensure all amounts are valid numbers."
      );
      setSubtotal(0);
    } else {
      setSubtotal(calcSubtotal);
    }
  }, [watchedComponents]);

  // Function to calculate tax
  const calculateTax = () => {
    if (taxType === "percentage") {
      return (subtotal * taxInput) / 100; // Calculate tax as percentage
    } else {
      return parseFloat(taxInput.toString()); // If taxType is "amount", return the fixed tax input
    }
  };

  // Function to calculate total
  // const calculateTotal = () => {
  //   const tax = calculateTax();
  //   setTaxAmount(tax);
  //   return subtotal + tax; // Add tax to subtotal
  // };

  useEffect(() => {
    // Recalculate total whenever subtotal or taxInput changes
    // setTotal(calculateTotal());
    let taxAmount = 0;

    // Check if the selected type is "Percentage" or "Fixed Amount"
    if (selectedTaxTypeId === 2) {
      // Percentage selected
      const percentage = parseFloat(taxInput.toString()) || 0;
      taxAmount = (subtotal * percentage) / 100;
    } else if (selectedTaxTypeId === 1) {
      // Fixed Amount selected
      taxAmount = parseFloat(taxInput.toString()) || 0;
    }

    const newTotal = subtotal + taxAmount;
    setTotal(newTotal); // Update the total state
    setTaxAmount(taxInput);
  }, [subtotal, taxInput, selectedTaxTypeId]);

  useEffect(() => {
    if (invoice_Id && editdata) {
      // Set form values
      setValue("invoice_refid", editdata.invoice_refid || "");
      setSubtotal(editdata.sub_amount || 0);

      const invoiceDate = new Date(editdata.invoice_date); // Convert to Date object
      setValue("startDate", invoiceDate); // Patch startDate
      setStartDate(invoiceDate); // Update local state as well

      const invoiceDueDate = new Date(editdata.due_date); // Convert to Date object
      setValue("endDate", invoiceDueDate); // Patch endDate
      setEndDate(invoiceDueDate); // Update local state as well

      setTotal(editdata.total || 0);
      setTaxInput(editdata.tax);
      setTaxType(editdata.tax_type);
      setValue("notes_term", editdata.notes_term);
      // setValue("logo", editdata.logo || "");
      setSelectedTaxTypeId(editdata.tax_type); // Ensure tax_type is coming from editdata

      // if (editdata.logo) {
      //   setPreview({
      //     image: editdata.logo,
      //     fileName: "default-file-name", // Replace with appropriate value if available
      //     fileSize: "0", // Convert to string if needed
      //   });
      // }

      // Check if repair_components exists and is an array before mapping
      if (Array.isArray(editdata.repair_components)) {
        const mappedComponents = editdata.repair_components.map(
          (item: any) => ({
            inv_repairid: item.inv_repairid || "0",
            component: item.component || "",
            cost: item.cost?.toString() || "0", // Convert cost to string safely
            amount: item.amount?.toString() || "0", // Convert amount to string safely
          })
        );

        setValue("components", mappedComponents); // Patch components
      } else {
        console.warn("editdata.repair_components is undefined or not an array");
        // Handle the absence of repair_components if necessary
      }
    }
  }, [invoice_Id, setValue, editdata]);

  return (
    <div
      className={`truck-list-sidebar ${
        offcanvas ? "truck-list-sidebar-open" : ""
      }`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Generate Invoice</h4>
            <Link to="#" className="clear-truck-list" onClick={handleOffcanvas}>
              <i className="fas fa-xmark"></i>
            </Link>
          </div>
          <div className="card-body">
            <div className="sidebar-sub-header">
              <h6>Repair ID - {repair_refid}</h6>
            </div>
            <div className="truck-information">
              <div className="truck-information-grid mb-3">
                <div className="generate-invoice-logo">
                  <div
                    className="add-logo-info"
                    style={{ visibility: "hidden" }}
                  >
                    <label
                      className="choose-file"
                      style={{
                        backgroundImage: preview.image
                          ? `url(${preview.image})`
                          : "none", // Apply background if image is selected
                        backgroundSize: "contain", // Ensures the image is contained within the label
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/svg+xml, image/jpg"
                        onChange={(e) => handleFileChange(e)}
                      />
                      <span>+ Add Logo</span>
                    </label>
                    {/* {errors.logo && (
                      <small className="text-danger">
                        {errors.logo.message}
                      </small>
                    )} */}
                  </div>
                  <div className="add-logo-input">
                    <h6>Invoice</h6>
                    <Controller
                      name="invoice_refid"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          className="form-control"
                          maxLength={nameMaxLength}
                          placeholder="#"
                          onChange={(e) => {
                            field.onChange(e);
                            trigger("invoice_refid");
                          }}
                          onBlur={() => trigger("invoice_refid")}
                        />
                      )}
                    />
                    {errors.invoice_refid && (
                      <small className="text-danger">
                        {errors.invoice_refid.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label>Send to</label>
                      <div className="send-to-info">
                        <img
                          src={createdByImage ? createdByImage : noImg}
                          className="img-fluid"
                          alt="Profile"
                        />
                        <p>{createdByName}</p>
                      </div>
                      <div className="send-to-text">
                        {/* <textarea
                              className="form-control"
                              placeholder="Billing To"
                            ></textarea> */}
                        <Controller
                          name="bill_to"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              maxLength={addressMaxLength}
                              className="form-control"
                              placeholder="Billing To"
                              onBlur={() => trigger("bill_to")}
                              onChange={(e) => {
                                field.onChange(e);
                                trigger("bill_to");
                              }}
                            />
                          )}
                        />
                        {errors.bill_to && (
                          <small className="text-danger">
                            {errors.bill_to.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap form-wrap-invoice">
                      <label>Invoice Date</label>
                      <div className="form-wrap-icon">
                        <Controller
                          name="startDate"
                          control={control}
                          render={({ field }) => (
                            <Calendar
                              className="datetimepicker"
                              value={field.value || null}
                              placeholder="MM/DD/YYYY"
                              minDate={new Date()}
                              onChange={(e) => {
                                handleStartDateChange(e.value);
                                trigger("startDate");
                              }}
                              onBlur={() => trigger("startDate")}
                            />
                          )}
                        />
                        <span className="datetimepicker-span">
                          <i className="ti ti-calendar" />
                        </span>
                        {errors?.startDate && (
                          <small className="text-danger">
                            {errors.startDate.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="form-wrap">
                      <label>Due Date</label>
                      <div className="form-wrap-icon">
                        <Controller
                          name="endDate"
                          control={control}
                          render={({ field }) => (
                            <Calendar
                              className="datetimepicker"
                              value={field.value || null}
                              placeholder="MM/DD/YYYY"
                              minDate={startDate || new Date()}
                              onChange={(e) => {
                                const newEndDate = e.value ?? null; // Use nullish coalescing
                                setEndDate(newEndDate);
                                field.onChange(newEndDate);
                              }}
                              disabled={isEndDateDisabled}
                            />
                          )}
                        />
                        <span className="datetimepicker-span">
                          <i className="ti ti-calendar" />
                        </span>
                        {errors?.endDate && (
                          <small className="text-danger">
                            {errors.endDate.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-wrap">
                  <div className="invoice-add-table">
                    <div className="table-responsive">
                      <table className="table table-center add-table-items">
                        <thead>
                          <tr>
                            <th>Repair Item</th>
                            <th>Cost</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map((item, index) => (
                            <tr key={item.id} className="add-row">
                              <td>
                                <Controller
                                  name={`components.${index}.component`}
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      type="text"
                                      maxLength={100}
                                      className="form-control"
                                      placeholder="Enter Repair Item"
                                      onBlur={() =>
                                        trigger(`components.${index}.component`)
                                      }
                                    />
                                  )}
                                />
                                {errors.components?.[index]?.component && (
                                  <small className="text-danger">
                                    {errors.components[index].component.message}
                                  </small>
                                )}
                              </td>
                              <td>
                                <Controller
                                  name={`components.${index}.cost`}
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Cost"
                                      onBlur={() => {
                                        trigger(`components.${index}.cost`);

                                        // Automatically calculate and set the amount field based on the cost input
                                        const calculatedAmount =
                                          parseFloat(field.value.toString()) ||
                                          0;

                                        // Set the amount as a number in the form
                                        setValue(
                                          `components.${index}.amount`,
                                          calculatedAmount,
                                          {
                                            shouldValidate: true,
                                          }
                                        );
                                      }}
                                    />
                                  )}
                                />
                                {errors.components?.[index]?.cost && (
                                  <small className="text-danger">
                                    {errors.components[index].cost.message}
                                  </small>
                                )}
                              </td>
                              <td>
                                <div className="amount-remove">
                                  <div className="d-none">
                                    <Controller
                                      name={`components.${index}.amount`}
                                      control={control}
                                      render={({ field }) => (
                                        <input
                                          {...field}
                                          placeholder="Amount"
                                          className={
                                            errors.components?.[index]?.amount
                                              ? "invalid form-control"
                                              : "form-control"
                                          }
                                          readOnly // This ensures the user can't edit the amount directly
                                        />
                                      )}
                                    />
                                    {errors.components?.[index]?.amount && (
                                      <small className="text-danger">
                                        {
                                          errors.components[index].amount
                                            .message
                                        }
                                      </small>
                                    )}
                                  </div>
                                  {/* Display the calculated amount as a formatted value */}
                                  <span>
                                    $
                                    {watch(`components.${index}.amount`) ||
                                      "0.00"}
                                  </span>
                                  <Link
                                    to="#"
                                    className="remove-btn"
                                    onClick={() => remove(index)} // Remove this row
                                  >
                                    <i className="ti ti-trash-x"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="add-invoice-btn">
                        <Link
                          to="#"
                          className="add-btn me-2"
                          onClick={() =>
                            append({ component: "", cost: 0, amount: 0 })
                          } // Add new row
                        >
                          + Add Item
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-wrap">
                  <div className="row">
                    <div className="col-md-12 col-xl-8 ms-auto">
                      <div className="table-responsive">
                        <table className="invoice-table-two table">
                          <tbody>
                            {/* Subtotal row */}
                            <tr>
                              <td>
                                <p>
                                  Subtotal <span className="sub-space">:</span>
                                </p>
                              </td>
                              <td className="text-end">
                                ${subtotal.toFixed(2)}
                              </td>{" "}
                              {/* Dynamic subtotal */}
                            </tr>

                            {/* Tax row */}
                            <tr>
                              <td>
                                <p>
                                  Tax <span className="sub-space">:</span>
                                </p>
                              </td>
                              <td className="text-end">
                                <div className="tax-info">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="0.00"
                                    value={taxInput}
                                    onChange={(e) =>
                                      setTaxInput(
                                        parseFloat(e.target.value) || 0
                                      )
                                    } // Capture user input for tax
                                  />
                                  <Dropdown
                                    options={taxOptions} // Use fetched tax options from API
                                    value={selectedTaxTypeId}
                                    onChange={(e) => {
                                      setSelectedTaxTypeId(e.value); // Set selected tax type ID
                                    }} // Switch between percentage or amount based on user selection
                                    className="select generate-invoice-cls"
                                  />
                                </div>
                              </td>
                            </tr>

                            {/* Total row */}
                            <tr>
                              <td>
                                <p>
                                  <b>Total</b>{" "}
                                  <span className="sub-space">:</span>
                                </p>
                              </td>
                              <td className="text-end">
                                <b>${total.toFixed(2)}</b>{" "}
                                {/* Dynamic total: subtotal + tax */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-wrap terms-form-wrap">
                  <label className="terms-label">
                    Notes & Terms <span>(Optional)</span>
                  </label>
                  <Controller
                    name="notes_term"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        maxLength={addressMaxLength}
                        className="form-control"
                        placeholder="Enter"
                        onBlur={() => trigger("notes_term")}
                        onChange={(e) => {
                          field.onChange(e);
                          trigger("notes_term");
                        }}
                      />
                    )}
                  />
                  {errors.notes_term && (
                    <small className="text-danger">
                      {errors.notes_term.message}
                    </small>
                  )}
                </div>
                <div className="form-wrap terms-form-wrap mb-0">
                  <label className="terms-label">
                    Document <span>(Optional)</span>
                  </label>
                  <div className="upload-info pb-3">
                    <label className="choose-file">
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                        onChange={(e) => handleDocumentChange(e)}
                      />
                      <span>
                        <i className="feather icon-upload"></i> Choose to File
                        Upload
                      </span>
                    </label>
                    <p>
                      <i className="feather icon-alert-circle"></i> Maximum File
                      size 50MB
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer sidebar-btn custom-footer-card">
            <Link to="#" className="btn btn-light" onClick={handleOffcanvas}>
              Cancel
            </Link>
            <Link to="#" className="btn btn-light">
              Preview
            </Link>
            <button className="btn btn-add" type="submit">
              {invoice_Id ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GenerateInvoice;
