import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { videoPreview, videotag } from "../../../../utils/imagepath";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../utils/patterns/regex.pattern";
import Lightbox from "yet-another-react-lightbox";
import { setAddCanvasSidebar } from "../../../../core/redux/sidebarSlice";

const ViewCourse = ({
  successcall = () => {},
  handleOffcanvas,
  deletePlan,
  editValue,
  setType,
}: any) => {
  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const [videoArray, setVideoArray] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const datas = useSelector((state: any) => state.common.editData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(editValue)?.length > 0) {
      setVideoArray(JSON.parse(editValue?.course_video));
    }
  }, [editValue]);

  return (
    <>
      <div
        className={`truck-list-sidebar ${
          offcanvas ? "truck-list-sidebar-open" : ""
        }`}
      >
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">View Course </h4>
            <Link onClick={handleOffcanvas} to="#" className="clear-truck-list">
              <i className="fas fa-xmark" />
            </Link>
          </div>
          <div className="card-body">
            <div className="truck-information mb-0">
              <div className="course-act-list">
                <Link
                  onClick={() => setType("Edit")}
                  to="#"
                  className="course-act-edit"
                >
                  <i className="feather icon-edit" /> Edit
                </Link>
                <Link
                  to="#"
                  className="course-act-delete"
                  onClick={() => {
                    deletePlan(datas.course_id);
                    dispatch(setAddCanvasSidebar(!offcanvas));
                  }}
                >
                  <i className="feather icon-trash-2" /> Delete
                </Link>
              </div>
              <div className="course-view-grid">
                <div className="course-view-hdr">
                  <div className="course-hdr">
                    <h6>Course Id - {editValue?.course_refid}</h6>
                    <span className="badge status-food">
                      {editValue?.categoryName}
                    </span>
                  </div>
                  <div className="course-info-list">
                    <ul className="text-muted">
                      <li>
                        <span>{videoArray?.length} Videos</span>
                      </li>
                      <li>
                        <span>{formatDate(editValue?.created_at)}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="course-view-body">
                  <h5>
                    {editValue?.course_name}
                    <span className="text-muted">
                      ({videoArray?.length} Videos)
                    </span>
                  </h5>
                  <ul>
                    {videoArray?.length > 0 &&
                      videoArray?.map((item: any, index: any) => (
                        <li>
                          <div className="course-list-grid">
                            <div className="course-list-left">
                              <span className="coursesl">{index + 1}.</span>
                              <div className="course-list-info">
                                <h4>{item?.course_video_name}</h4>
                                <span className="text-muted">
                                  Video - {item?.duration} mins
                                </span>
                              </div>
                            </div>
                            <div
                              className="course-vtag"
                              onClick={async () => {
                                await setVideoUrl(item?.video_path);
                                await setIsOpen(true);
                              }}
                            >
                              <img src={videoPreview} alt="" />
                              {/* <i className="feather icon-play-circle"></i> */}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        <Lightbox
          open={isOpen}
          close={() => {
            setIsOpen(false);
            setVideoUrl("");
          }}
          slides={[
            {
              type: "video",
              source: `https://api.thecarai.ai${videoUrl}`,
            },
          ]}
          render={{
            slide: (props) => (
              <video
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                controls
                autoPlay
              >
                <source
                  src={`https://api.thecarai.ai${videoUrl}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ),
          }}
        />
      }
    </>
  );
};

export default ViewCourse;
