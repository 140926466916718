import { useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { routes } from "../../utils/shared.module";

interface ProtectedRouteProps {
  children: ReactNode;
}

const RedirectAuthenticated = ({ children }: ProtectedRouteProps) => {
  const token = localStorage.getItem("token");
  const role = useSelector((state: any) => state.login.role);
  const userDetails = useSelector((state: any) => state.login.userDetails);
  const navigate = useNavigate();
  const findPath = window.location.pathname;
  const isAdmin = findPath.split("/")[1];

  if (token) {
    if (role === 1) {
      navigate(routes.adminDashboard.path);
    } else if (role === 3) {
       if (!userDetails?.term_condition) {
              navigate(routes.fleetTermCondition.path);
            } else {
              navigate(routes.fleetManagerDashboard.path);
            }
    } else if (role === 4) {
      navigate(routes.serviceManagerDashboard.path);
    } else if (isAdmin === "course-admin") {
      !userDetails?.term_condition
        ? navigate(routes.courseTermCondition.path)
        : navigate(routes.courseAdminDashboard.path);
    } else {
      navigate(routes.dashboard.path);
    }
    return null;
  }

  return <>{children}</>;
};

export default RedirectAuthenticated;
