import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { setExpandMenu } from "../../../../core/redux/sidebarSlice";
import { routes } from "../../../../utils/routes";
import defaultimage from "../../../../assets/img/profiles/no-img.png";
import store from "../../../../core/redux/store";
import { logout } from "../../../../core/redux/loginSlice";

const SuperAdminSidebar = () => {
  interface UserDetails {
    name?: string;
    profile_url?: string;
  }
  const dispatch = useDispatch();
  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };
  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };
  const location = useLocation();

  const pathname: any = location?.pathname;
  const findPath = pathname.split("/")[1];
  const userData = useSelector((state: any) => state.login.userDetails);

  const handleLogout = (data: any) => {
    dispatch(logout(data));
  };

  return (
    <>
      {" "}
      {/* Sidebar */}
      <div
        className="sidebar"
        id="sidebar"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Scrollbars autoHide>
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Main</h6>
                <ul>
                  <li className="submenu">
                    <Link
                      to={routes.dashboard.path}
                      className={pathname.includes("dashboard") ? "active" : ""}
                    >
                      <i className="ti ti-layout-dashboard" />
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li className="submenu">
                    <Link
                      to={routes.tutorial.path}
                      className={pathname.includes("tutorial") ? "active" : ""}
                    >
                      <i className="ti ti-video-minus" />
                      <span>Tutorial</span>
                    </Link>
                  </li>
                  <li className="submenu">
                    <Link
                      to={routes.courseAdmin.path}
                      className={
                        pathname.includes("course-admin") ? "active" : ""
                      }
                    >
                      <i className="ti ti-users" />
                      <span>Marketplace</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Others</h6>
                <ul>
                  <li className="submenu">
                    <Link
                      to={routes.registeredCompanies.path}
                      className={
                        pathname.includes("registered-companies")
                          ? "active"
                          : ""
                      }
                    >
                      <i className="ti ti-building-community" />
                      <span>Registered Companies</span>
                    </Link>
                  </li>
                  <li className="submenu">
                    <Link
                      to={routes.ownerOperator.path}
                      className={
                        pathname.includes("owner-operator") ? "active" : ""
                      }
                    >
                      <i className="ti ti-truck-delivery" />
                      <span>Owner Operators</span>
                    </Link>
                  </li>
                  <li className="submenu">
                    <Link
                      to={routes.pricingPlan.path}
                      className={
                        pathname.includes("pricing-plan") ? "active" : ""
                      }
                    >
                      <i className="ti ti-list-check" />
                      <span>Pricing Plans</span>
                    </Link>
                  </li>
                  <li className="submenu">
                    <Link
                      to={routes.adminProfile.path}
                      className={pathname.includes("profile") ? "active" : ""}
                    >
                      <i className="ti ti-user-circle" />
                      <span>Profile</span>
                    </Link>
                  </li>
                  <li className="submenu">
                    <Link
                      to={routes.settings.path}
                      className={pathname.includes("settings") ? "active" : ""}
                    >
                      <i className="ti ti-settings" />
                      <span>Settings</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="sidebar-footer">
              <div className="sidebar-profile">
                <Link to={routes.login.path}>
                  <div className="sidebar-profile-img">
                    <img
                      src={userData.profile_url || defaultimage}
                      className="img-fluid"
                      alt="Profile"
                    />
                    <p>
                      <span>{userData.name}</span>
                      Super Admin
                    </p>
                  </div>
                </Link>
                <div className="sidebar-profile-logout">
                  <Link
                    to={"#"}
                    onClick={() => {
                      handleLogout(findPath);
                    }}
                  >
                    <i className="ti ti-logout-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
      {/* /Sidebar */}
    </>
  );
};

export default SuperAdminSidebar;
