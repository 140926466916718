import CourseHowToUse from "../pages/auth/course-terms/course-how-to-use";

interface Route {
  path: string;
}
export const routes: Record<string, Route> = {
  //super Admin
  login: { path: "/super-admin/login" },
  forgotPassword: { path: "/super-admin/forgot-password" },
  otpSuccess: { path: "/super-admin/otp-success" },
  otp: { path: "/super-admin/otp" },
  resetPassword: { path: "/super-admin/reset-password" },

  //course Admin
  courseLogin: { path: "/course-admin/login" },
  courseForgotPassword: { path: "/course-admin/forgot-password" },
  courseOtpSuccess: { path: "/course-admin/otp-success" },
  courseOtp: { path: "/course-admin/otp" },
  courseResetPassword: { path: "/course-admin/reset-password" },
  quiz: { path: "/course-admin/quiz" },
  course: { path: "/course-admin/courses" },
  courseDashboard: { path: "/course-admin/dashboard" },
  coursesubscribers: { path: "/course-admin/courses/course-subscribers" },
  quizDetails: { path: "/course-admin/quiz-details" },

  terms: { path: "/terms-conditions" },
  policy: { path: "/privacy-policy" },

  // super-admin
  dashboard: { path: "/super-admin/dashboard" },
  editTruck: { path: "/super-admin/edit-truck" },
  registeredCompanies: {
    path: "/super-admin/registered-companies",
  },
  ownerOperator: {
    path: "/super-admin/owner-operator",
  },
  pricingPlan: {
    path: "/super-admin/pricing-plan",
  },
  tutorial: { path: "/super-admin/tutorial" },
  courseAdmin: { path: "/super-admin/course-admin" },
  adminProfile: { path: "/super-admin/profile" },
  courseAdminProfile: { path: "/course-admin/profile" },
  profile: { path: "/profile" },

  // admin
  adminLogin: { path: "/login" },

  adminForgotPassword: {
    path: "/forgot-password",
  },
  adminOtpSuccess: { path: "/otp-success" },
  adminOtp: { path: "/otp" },
  adminResetPassword: {
    path: "/reset-password",
  },
  invoiceList: {
    path: "/invoice",
  },
  invoiceDetails: {
    path: "/invoice-details",
  },
  fleetManager: { path: "/fleet-manager" },
  driversList: { path: "/drivers-list" },
  vehiclesDetails: {
    path: "/vehicles-details",
  },
  vehiclesList: { path: "/vehicles-list" },
  adminDashboard: { path: "/dashboard" },
  repairDetails: {
    path: "/repair-details",
  },
  repairList: { path: "/repair-list" },
  trip: { path: "/trip" },
  serviceManagerList: {
    path: "/service-manager-list",
  },
  serviceInvoiceList: {
    path: "/service-invoice",
  },
  serviceInvoiceDetails: {
    path: "/service-invoice-details",
  },
  inspectionList: {
    path: "/inspection-list",
  },
  inspectionPreview: {
    path: "/inspection-preview",
  },
  tripBreakdownDetails: {
    path: "/trip-breakdown-details",
  },

  // fleet manager
  fleetManagerDashboard: {
    path: "/fleet-manager/dashboard",
  },
  fleetManagerDriversList: {
    path: "/fleet-manager/drivers-list",
  },

  // service manager
  serviceManagerDashboard: {
    path: "/service-manager/dashboard",
  },
  repairDetailsServiceManager: {
    path: "/service-manager/repair-details",
  },
  tripDetails: {
    path: "/trip-details",
  },

  //course admin
  courseAdminDashboard: {
    path: "/course-admin/dashboard",
  },

  product: {
    path: "/course-admin/product",
  },
  productList: {
    path: "/course-admin/product-list",
  },
  subscribersList: {
    path: "/course-admin/subscribers-list",
  },
  courseTermCondition: {
    path: "/course-admin/term-condition",
  },
  courseHowToUse: {
    path: "/course-admin/how-to-use",
  },
  fleetTermCondition: {
    path: "/term-condition",
  },
  fleetHowToUse: {
    path: "/how-to-use",
  },

  notifications: {
    path: "/notifications",
  },
  settings: {
    path: "/super-admin/settings",
  },
};
