import React, { useState } from "react";
import "./Tabs.css";
import PendingInvoice from "./pending-invoice";
import InvoiceList from "./invoice-list";

const InvoicePlanTabs = () => {
  const [activeTab, setActiveTab] = useState("invoice");

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  return (
    <div className="page-wrapper admin-invoice-wrapper">
      <div className="tabs-container">
        <div className="tabs invoice-tabs">
          <button
            className={`tab ${activeTab === "invoice" ? "active" : ""}`}
            onClick={() => handleTabClick("invoice")}
          >
            All Invoices
          </button>
          <button
            className={`tab ${activeTab === "pending" ? "active" : ""}`}
            // onClick={() => handleTabClick("pending")}
          >
            Pending Invoice
          </button>
        </div>
        {activeTab === "invoice" && <InvoiceList />}
        {activeTab === "pending" && <PendingInvoice />}
      </div>
    </div>
  );
};

export default InvoicePlanTabs;
