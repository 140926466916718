import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { setExpandMenu } from "../../../../core/redux/sidebarSlice";
import { routes } from "../../../../utils/routes";
import defaultimage from "../../../../assets/img/profiles/no-img.png";
import store from "../../../../core/redux/store";
import { logout } from "../../../../core/redux/loginSlice";

const CourseAdminSidebar = () => {
  interface UserDetails {
    name?: string;
    profile_url?: string;
  }
  const dispatch = useDispatch();
  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };
  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };
  const location = useLocation();

  const pathname: any = location?.pathname;
  const findPath = pathname.split("/")[1];
  const userData = useSelector((state: any) => state.login.userDetails);

  const handleLogout = (data: any) => {
    dispatch(logout(data));
  };

  return (
    <>
      {" "}
      {/* Sidebar */}
      <div
        className="sidebar"
        id="sidebar"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Scrollbars autoHide>
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Main</h6>
                <ul>
                  <li className="submenu">
                    <Link
                      to={routes.courseDashboard.path}
                      className={pathname.includes("dashboard") ? "active" : ""}
                    >
                      <i className="ti ti-layout-dashboard" />
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  {userData?.cour_type !== "Product" && (
                    <li className="submenu">
                      <Link
                        to={routes.course.path}
                        className={pathname.includes("courses") ? "active" : ""}
                      >
                        <i className="ti ti-video-minus" />
                        <span>Course</span>
                      </Link>
                    </li>
                  )}
                  {userData?.cour_type === "Product" && (
                    <li className="submenu">
                      <Link
                        to={routes.productList.path}
                        className={
                          pathname.includes("product-list") ? "active" : ""
                        }
                      >
                        <i className="ti ti-video-minus" />
                        <span>Product</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
              <li className="submenu-open">
                <h6 className="submenu-hdr">Others</h6>
                <ul>
                  {userData?.cour_type !== "Product" && (
                    <li className="submenu">
                      <Link
                        to={routes.quiz.path}
                        className={pathname.includes("quiz") ? "active" : ""}
                      >
                        <i className="ti ti-help" />
                        <span>Quiz</span>
                      </Link>
                    </li>
                  )}
                  <li className="submenu">
                    <Link
                      to={routes.subscribersList.path}
                      className={
                        pathname.includes("subscribers-list") ? "active" : ""
                      }
                    >
                      <i className="ti ti-users" />
                      <span>Subscription List</span>
                    </Link>
                  </li>
                  <li className="submenu">
                    <Link
                      to={routes.courseAdminProfile.path}
                      className={pathname.includes("profile") ? "active" : ""}
                    >
                      <i className="ti ti-user-circle" />
                      <span>Profile</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="sidebar-footer">
              <div className="sidebar-profile">
                <Link to="#">
                  <div className="sidebar-profile-img">
                    <img
                      src={userData.profile_url || defaultimage}
                      className="img-fluid"
                      alt="Profile"
                    />
                    <p>
                      <span>{userData.name}</span>
                      Course Admin
                    </p>
                  </div>
                </Link>
                <div className="sidebar-profile-logout">
                  <Link
                    to={"#"}
                    onClick={() => {
                      handleLogout(findPath);
                    }}
                  >
                    <i className="ti ti-logout-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
      {/* /Sidebar */}
    </>
  );
};

export default CourseAdminSidebar;
