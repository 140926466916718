import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  resetMobileSidebar,
  setAddCanvasSidebar,
  setEditCanvasSidebar,
} from "../../core/redux/sidebarSlice";
import SuperAdminSidebar from "./common/sidebar/super-admin";
import AdminSidebar from "./common/sidebar/admin-sidebar";
import Header from "./common/header";
import { clearEditData } from "../../core/redux/commonSlice";
import CourseAdminSidebar from "./common/sidebar/course-sidebar";

const FeatureModule: React.FC = () => {
  const location = useLocation();
  const miniSidebar = useSelector((state: any) => state.sidebar.miniSidebar);
  const expandMenu = useSelector((state: any) => state.sidebar.expandMenu);
  const userDetails: any = useSelector((state: any) => state.login.userDetails);

  // const roleType = userDetails.cour_type;

  const mobileSidebar = useSelector(
    (state: any) => state.sidebar.mobileSidebar
  );

  const authPages =
    location.pathname.includes("login") ||
    location.pathname.includes("forgot-password") ||
    location.pathname.includes("reset-password") ||
    location.pathname.includes("otp") ||
    location.pathname.includes("privacy-policy") ||
    location.pathname.includes("terms-conditions") ||
    location.pathname.includes("term-condition") ||
    location.pathname.includes("how-to-use");

  const baseWrapperPage =
    location.pathname.includes("forgot-password") ||
    location.pathname.includes("otp-success") ||
    location.pathname.includes("otp") ||
    location.pathname.includes("reset-password") ||
    location.pathname.includes("term-condition") ||
    location.pathname.includes("how-to-use");
  // location.pathname.includes("privacy-policy") ||
  // location.pathname.includes("terms-conditions");

  const loginWrapperPage = location.pathname.includes("login");

  const withoutWrapperPage =
    location.pathname.includes("privacy-policy") ||
    location.pathname.includes("terms-conditions");

  const dispatch = useDispatch();

  const role = useSelector((state: any) => state.login.role);

  useEffect(() => {
    dispatch(resetMobileSidebar());
  }, [location.pathname, dispatch]);
  const renderSidebar = () => {
    if (role == 1 || role == 3 || role == 4) {
      return <AdminSidebar />;
    } else if (
      location.pathname.startsWith("/course-admin") ||
      (location.pathname.startsWith("/notifications") &&
        (roleType === "Product" || roleType === "Course"))
    ) {
      return <CourseAdminSidebar />;
    } else if (
      location.pathname.startsWith("/super-admin") ||
      (location.pathname.startsWith("/notifications") &&
        roleType === "super_admin")
    ) {
      return <SuperAdminSidebar />;
    }
    return null; // No sidebar for other paths
  };

  const offcanvas = useSelector((state: any) => state.sidebar.addCanvasSidebar);
  const offcanvascourseview = useSelector(
    (state: any) => state.sidebar.addCanvasSidebarCourseView
  );
  const editOffcanvas = useSelector(
    (state: any) => state.sidebar.editCanvasSidebar
  );
  const handleOverlayClick = () => {
    dispatch(setAddCanvasSidebar(false));
    dispatch(setEditCanvasSidebar(false));
    dispatch(clearEditData());
  };
  useEffect(() => {
    dispatch(setAddCanvasSidebar(false));
    dispatch(setEditCanvasSidebar(false));
  }, [dispatch]);
  return (
    <>
      <div
        className={`
        ${miniSidebar ? "mini-sidebar" : ""}
        ${expandMenu && miniSidebar ? "expand-menu" : ""}
        ${mobileSidebar ? "menu-opened slide-nav" : ""}
        `}
      >
        <div
          className={`main-wrapper
             ${baseWrapperPage ? "base-wrapper" : ""}
             ${loginWrapperPage ? "login-wrapper" : ""}
             `}
        >
          {!authPages && (
            <>
              <Header />
              {renderSidebar()}
            </>
          )}
          <Outlet />
        </div>
      </div>
      <div
        onClick={handleOverlayClick}
        id="truck-list-sidebar-overlay"
        className={`
          ${offcanvas ? "opened" : ""}
          ${editOffcanvas ? "opened-edit" : ""}
          ${offcanvascourseview ? "opened" : ""}
          `}
      ></div>
    </>
  );
};

export default FeatureModule;
