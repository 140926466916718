import { routes } from "./routes";
import Dashboard from "../pages/feature-module/super-admin/companies/dashboard";
import RegisteredCompanies from "../pages/feature-module/super-admin/companies/registered-companies";
import Tutorial from "../pages/feature-module/super-admin/tutorial";
import Profile from "../pages/feature-module/super-admin/profile";
import DriversList from "../pages/feature-module/admin/drivers";
import VehiclesList from "../pages/feature-module/admin/vehicles-list";
import AdminDashboard from "../pages/feature-module/admin/dashboard";
import RepairDetails from "../pages/feature-module/admin/repair/repair-details";
import RepairList from "../pages/feature-module/admin/repair/repair-list";
import InspectionList from "../pages/feature-module/admin/inspection/inspection-list";
import InspectionPreview from "../pages/feature-module/admin/inspection/inspection-preview";
import TripBreakdownDetails from "../pages/feature-module/admin/trip-breakdown-details";
import Trip from "../pages/feature-module/admin/trip";
import FleetManagerDashboard from "../pages/feature-module/fleet-manager/dashboard";
import FleetManagerDriversList from "../pages/feature-module/fleet-manager/drivers-list";
import ServiceManagerDashboard from "../pages/feature-module/service-manager/dashboard";
import FleetManager from "../pages/feature-module/admin/fleetmanager";
import ServiceMangerList from "../pages/feature-module/admin/service-manager-list";
import TripDetails from "../pages/feature-module/admin/trip/trip-details";
import VehicleDetails from "../pages/feature-module/admin/vehicles-details/vehicle-details";
import CourseAdmin from "../pages/feature-module/super-admin/course-admin/courseAdmin";
import CourseDashboard from "../pages/feature-module/course-admin/dashboard/dashboard";
import InvoiceList from "../pages/feature-module/admin/invoice/invoice-list";
import InvoiceDetails from "../pages/feature-module/admin/invoice/invoice-details";
import ServiceInvoiceList from "../pages/feature-module/service-manager/invoice/invoive-list";
import ServiceInvoiceDetails from "../pages/feature-module/service-manager/invoice/invoice-details";
import Quiz from "../pages/feature-module/course-admin/quiz";
import Course from "../pages/feature-module/course-admin/courses";
import CourseSubscribers from "../pages/feature-module/course-admin/courses/course-subscribers";
import SubscribersList from "../pages/feature-module/course-admin/subscribers";
import ProductList from "../pages/feature-module/course-admin/product";
import Notifications from "../pages/feature-module/notification";
import QuizDetails from "../pages/feature-module/course-admin/quiz/quiz-details";
import PlanTabs from "../pages/feature-module/super-admin/pricing-plan";
import OwnerOperator from "../pages/feature-module/super-admin/owner-operator/ownerOperator";
import InvoicePlanTabs from "../pages/feature-module/admin/invoice";
import TermsCondition from "../pages/auth/course-terms/terms-conditions";
import FleetManagerTerms from "../pages/auth/fleet-terms/fleet-manager-terms";
import CourseAdminTerms from "../pages/auth/course-terms/course-admin-terms";
import FleetTermsCondition from "../pages/auth/fleet-terms/fleet-terms-conditions";
import CourseTermsCondition from "../pages/auth/course-terms/terms-conditions";
import CourseHowToUse from "../pages/auth/course-terms/course-how-to-use";
import FleetHowToUse from "../pages/auth/fleet-terms/fleet-how-to-use";
import Settings from "../pages/feature-module/admin/settings";

export const authRoutes = [
  // super-admin
  {
    id: 1,
    path: routes.dashboard.path,
    element: <Dashboard />,
  },
  {
    id: 2,
    path: routes.registeredCompanies.path,
    element: <RegisteredCompanies />,
  },
  {
    id: 34,
    path: routes.ownerOperator.path,
    element: <OwnerOperator />,
  },
  {
    id: 3,
    path: routes.pricingPlan.path,
    element: <PlanTabs />,
  },
  {
    id: 4,
    path: routes.tutorial.path,
    element: <Tutorial />,
  },
  {
    id: 33,
    path: routes.courseAdmin.path,
    element: <CourseAdmin />,
  },
  {
    id: 5,
    path: routes.profile.path,
    element: <Profile />,
  },
  {
    id: 99,
    path: routes.adminProfile.path,
    element: <Profile />,
  },
  {
    id: 101,
    path: routes.courseAdminProfile.path,
    element: <Profile />,
  },
  // admin
  {
    id: 6,
    path: routes.fleetManager.path,
    element: <FleetManager />,
  },
  {
    id: 7,
    path: routes.driversList.path,
    element: <DriversList />,
  },
  {
    id: 8,
    path: routes.vehiclesDetails.path,
    element: <VehicleDetails />,
  },
  {
    id: 9,
    path: routes.vehiclesList.path,
    element: <VehiclesList />,
  },
  {
    id: 10,
    path: routes.adminDashboard.path,
    element: <AdminDashboard />,
  },
  {
    id: 10,
    path: routes.repairDetails.path,
    element: <RepairDetails />,
  },
  {
    id: 11,
    path: routes.repairList.path,
    element: <RepairList />,
  },
  {
    id: 12,
    path: routes.serviceManagerList.path,
    element: <ServiceMangerList />,
  },
  {
    id: 13,
    path: routes.inspectionList.path,
    element: <InspectionList />,
  },
  {
    id: 14,
    path: routes.inspectionPreview.path,
    element: <InspectionPreview />,
  },
  {
    id: 15,
    path: routes.tripBreakdownDetails.path,
    element: <TripBreakdownDetails />,
  },
  {
    id: 16,
    path: routes.tripDetails.path,
    element: <TripDetails />,
  },
  {
    id: 17,
    path: routes.trip.path,
    element: <Trip />,
  },
  {
    id: 35,
    path: routes.invoiceList.path,
    element: <InvoicePlanTabs />,
  },
  // fleet manager
  {
    id: 18,
    path: routes.fleetManagerDashboard.path,
    element: <FleetManagerDashboard />,
  },
  {
    id: 19,
    path: routes.fleetManagerDriversList.path,
    element: <FleetManagerDriversList />,
  },
  // service manager
  {
    id: 20,
    path: routes.serviceManagerDashboard.path,
    element: <ServiceManagerDashboard />,
  },
  {
    id: 21,
    path: routes.tripDetails.path,
    element: <TripDetails />,
  },
  // Course Admin
  {
    id: 22,
    path: routes.courseAdminDashboard.path,
    element: <CourseDashboard />,
  },
  {
    id: 23,
    path: routes.serviceInvoiceList.path,
    element: <ServiceInvoiceList />,
  },
  {
    id: 24,
    path: routes.invoiceDetails.path,
    element: <InvoiceDetails />,
  },
  {
    id: 36,
    path: routes.serviceInvoiceDetails.path,
    element: <ServiceInvoiceDetails />,
  },
  {
    id: 25,
    path: routes.quiz.path,
    element: <Quiz />,
  },
  {
    id: 26,
    path: routes.course.path,
    element: <Course />,
  },
  {
    id: 27,
    path: routes.productList.path,
    element: <ProductList />,
  },
  {
    id: 25,
    path: routes.subscribersList.path,
    element: <SubscribersList />,
  },
  {
    id: 28,
    path: routes.coursesubscribers.path,
    element: <CourseSubscribers />,
  },
  {
    id: 29,
    path: routes.notifications.path,
    element: <Notifications />,
  },
  {
    id: 30,
    path: routes.quizDetails.path,
    element: <QuizDetails />,
  },
  {
    id: 31,
    path: routes.courseTermCondition.path,
    element: <CourseTermsCondition />,
  },
  {
    id: 37,
    path: routes.fleetTermCondition.path,
    element: <FleetTermsCondition />,
  },
  {
    id: 38,
    path: routes.courseHowToUse.path,
    element: <CourseHowToUse />,
  },
  {
    id: 39,
    path: routes.fleetHowToUse.path,
    element: <FleetHowToUse />,
  },
  {
    id: 40,
    path: routes.settings.path,
    element: <Settings />,
  },
];
