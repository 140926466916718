import React, { useContext, useState } from "react";
import { ApiServiceContext, routes } from "../../../utils/shared.module";
import { end_points } from "../../../services/end_point/end_points";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CourseAdmin from "../../feature-module/super-admin/course-admin/courseAdmin";
import FleetManagerTerms from "../fleet-terms/fleet-manager-terms";

const FleetTermsCondition = () => {
    const { putData } = useContext(ApiServiceContext);
    const userDetails = useSelector((state: any) => state.login.userDetails);
    const navigate = useNavigate();

    const [step, setStep] = useState<"terms" | "how-to-use">("terms");

    const handleSubmit = async () => {

        // Navigate to the correct dashboard based on user role
        navigate(routes.fleetHowToUse.path);

    };
    
    return (
        <div>
            <div className="forgot-page-wrapper">
                <div className="forgot-page-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="static-page">
                                    <div className="row">
                                        <div className="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3">
                                            <div className="forgot-pwd-topper">
                                                <div className="forgot-pwd text-center">
                                                    <div className="forgot-password-inner">
                                                        <div className="forgot-password-title">
                                                            <h5 className="terms-of-service">Terms & Conditions</h5>
                                                            <FleetManagerTerms />
                                                            <button
                                                                className="btn submit-btn btn-hvr w-50"
                                                                onClick={handleSubmit}
                                                            >
                                                                Accept
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FleetTermsCondition;
