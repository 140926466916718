import React, { useContext, useEffect, useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useForm, Controller } from "react-hook-form";
import { end_points } from "../../../services/end_point/end_points";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ApiServiceContext, routes } from "../../../utils/shared.module";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe("pk_test_YAUsegA3YbnfQchAwsJn5oZ1");
interface PaymentProps {
  setPaymentVisible: (visible: boolean) => void;
}
const PaymentForm: React.FC<PaymentProps> = ({ setPaymentVisible }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();
  //   const selectedPlan = useSelector((state: any) => state.plan.selectedPlan);
  const [isProcessing, setIsProcessing] = useState(false);
  const subdomain = useSelector((state: any) => state.login.domain);
  const [plantDetials, setPlanDetials] = useState();
  const [secretKey, setSecretKey] = useState("");
  const [apiKey, setApiKey] = useState("");
  const findPath = window.location.pathname;
  const isAdmin = findPath.split("/")[1];
  const planDetails = JSON.parse(
    localStorage.getItem("plandetailsdata") ?? "{}"
  );
  const { postData, getData, putData } = useContext(ApiServiceContext);

  const generateToken = async () => {
    try {
      // URL for the token generation endpoint
      const tokenUrl = end_points.setup_payment.url; // Replace with your actual endpoint

      // Perform the GET request to obtain the payment token
      const response = await getData(tokenUrl);

      // Check if the response was successful
      if (response.status === 200 && response.data) {
        setSecretKey(response?.data?.data?.intent?.secretKey);
        setApiKey(response?.data?.data?.intent?.apiKey);
        return response.data; // This contains the `intent` object with the token details
      } else {
        throw new Error("Failed to generate token: No data in response");
      }
    } catch (error: any) {
      console.error("Error generating token:", error);
      throw error; // Re-throw the error to be caught in the calling function
    }
  };

  useEffect(() => {
    generateToken();
  }, []);

  // 👉 Handle form submission and Stripe payment
  const onSubmit = async (data: any) => {
    if (!stripe || !elements) {
      toast.error("Stripe has not loaded yet. Please try again later.");
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      setError("cardDetails", {
        type: "manual",
        message: "Card details are missing.",
      });
      return;
    }

    setIsProcessing(true);

    try {
      // Generate a Stripe token
      const { token, error } = await stripe.createToken(cardElement);

      console.log(token, "tokkken");

      const companyEmail = localStorage.getItem("companyEmail");
      const companyFirstName = localStorage.getItem("companyFirstName");

      const payload = {
        email: companyEmail,
        source: token?.id,
        name: companyFirstName,
        description: "Dreams_CARAI",
      };
      const urlEncodedPayload = new URLSearchParams(payload).toString();

      const response1 = await fetch("https://api.stripe.com/v1/customers", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${secretKey}`,
        },
        body: urlEncodedPayload,
      });

      const result = await response1.json();

      if (error) {
        toast.error(error.message || "Error generating token.");
        return;
      }

      //   const companyDetailsString = localStorage.getItem("companyDetails");
      //   if (!companyDetailsString) {
      //     toast.error("Company details not found.");
      //     return;
      //   }

      const planDetails = JSON.parse(localStorage.getItem("plandetailsdata"));
      const domain =
        isAdmin === "super-admin" || isAdmin === "course-admn"
          ? {}
          : subdomain
          ? subdomain
          : {};

      const paymentPayload = {
        token: token.id,
        customerid: result?.id,
        user_email: companyEmail,
        domain_name: domain,
        price_planid: planDetails.plan_id,
        price_plantype: planDetails.discount_for,
        fees: planDetails.mainAmount,
      };

      // Send payment data to backend
      const response = await putData(
        end_points.updatePayment.url,
        paymentPayload
      );

      if (response.status === 200) {
        toast.success(
          "Payment successful! Proceeding with company registration..."
        );

        // Prepare registration data
        const formData = new FormData();

        const registerResponse = await postData(
          end_points.company_register.url,
          formData
        );

        if (registerResponse.status === 200) {
          toast.success("Company registration successful!");
        } else {
          toast.error("Company registration failed.");
        }
      } else {
        toast.error("Payment failed. Please try again.");
      }
    } catch (error) {
      console.error("Payment Error:", error);
    } finally {
      setIsProcessing(false);

      localStorage.clear();
      setPaymentVisible(false);
      navigate(routes.adminLogin.path);
    }
  };

  // 👉 Reset the form when the cancel button is clicked
  const handleCancel = () => {
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h4 className="company-title">Payment Details</h4>

      {/* Cardholder's Name Field */}
      <div className="form-wrap">
        <label>Name on Card</label>
        <Controller
          name="card_name"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <input
              type="text"
              className="form-control"
              placeholder="Enter Cardholder's Name"
              {...field}
            />
          )}
        />
      </div>

      {/* Card Number Field */}
      <div className="form-wrap">
        <label>
          Card Number <span className="text-danger">*</span>
        </label>
        <CardNumberElement className="payment-element" />
      </div>

      {/* Expiry Date and CVV Section */}
      <div className="expiry-date-cvv">
        <div className="form-wrap">
          <label>
            Expiry Date <span className="text-danger">*</span>
          </label>
          <CardExpiryElement className="payment-element" />
        </div>

        <div className="form-wrap">
          <label>
            CVV <span className="text-danger">*</span>
          </label>
          <CardCvcElement className="payment-element" />
        </div>
      </div>

      {/* Payment Summary Section */}
      <div className="company-grid">
        <h4 className="company-title">Payment Summary</h4>
        <div className="payment-summary-card">
          <div className="subtotal">
            <div className="subtotal-title">Subtotal</div>
            <div className="subtotal-title">${planDetails?.amount}</div>
          </div>
          <div className="divider-payment"></div>
          <div className="subtotal">
            <div className="total-title">Total</div>
            <div className="total-title">${planDetails?.mainAmount}</div>
          </div>
        </div>
      </div>

      {/* Buttons Section */}
      <div className="payment-btn-container">
        <button
          type="submit"
          className="make-payment"
          disabled={!stripe || isProcessing}
        >
          {isProcessing ? "Processing..." : "Make Payment"}
        </button>
        <button type="button" className="cancel-payment" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default PaymentForm;
