import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  truck06,
  truck02,
  signatureImg,
  noImg,
  notes,
  loginLogo,
} from "../../../../utils/imagepath";
import { routes } from "../../../../utils/routes";
import { RepairDetail } from "../../../../components/json/repair-detail";
import PrimeDataTable from "../../../../components/data-table";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { end_points } from "../../../../services/end_point/end_points";
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from "../../../../utils/shared.module";
import { Calendar } from "primereact/calendar";
import { Controller, useForm } from "react-hook-form";
import {
  convertToDate,
  DESCRIPTION_LENGTH,
} from "../../../../utils/patterns/regex.pattern";
import { toast } from "react-toastify";
import { setSelectedData } from "../../../../core/redux/commonSlice";

declare global {
  interface Window {
    bootstrap: any;
  }
}
const ServiceInvoiceDetails = () => {
  const [listData, setListData] = useState<any>([]);
  const [rows, setRows] = useState<number>(10);
  const [tripTrackingData, setTripTrackingData] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(20);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [repairTrackingData, setGeneratedInvoiceData] = useState<any[]>([]);
  const [repairViewData, setRepairViewData] = useState<any>({});
  const navigate = useNavigate();
  const { getData, putData } = useContext(ApiServiceContext);
  const role = useSelector((state: any) => state.login.role);
  const Data = useSelector((state: any) => state.common.generatedInvoiceData);
  const viewData = useSelector((state: any) => state.common.repairViewData);
  const login_id = useSelector((state: any) => state.login.userDetails.id);
  const defaultRepairComponents = [
    { component: "Engine Repair", cost: 100, amount: 100 },
    { component: "Oil Change", cost: 30, amount: 30 },
    { component: "Brake Replacement", cost: 150, amount: 150 },
  ];
  interface TripTrackingItem {
    label: string;
    description: string;
    is_Completed: boolean;
    date: string; // Ensure date is a string or a valid Date object
  }

  const loginusername = useSelector(
    (state: any) => state.login.userDetails.name
  );
  const loginusereamil = useSelector(
    (state: any) => state.login.userDetails.email
  );
  const loginuserphone = useSelector(
    (state: any) => state.login.userDetails.phone_number
  );
  const modelRef = useRef<any>(null);
  const closeRef = useRef<any>(null);
  const getInvoiceById = async () => {
    try {
      let url = end_points.get_InvoiceById.url;
      url += `?invoice_id=${Data.invoice_Id}`;
      const response = await getData(url);
      if (response.status === 200) {
        setRepairViewData(response?.data?.data?.invoice);
        setListData(response?.data?.repairComponents);
        setRepairStatus(response?.data?.repair_status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getInvoiceTracking = async () => {
    try {
      let url = end_points.get_invoiceTracking.url;
      url += `?invoiceid=${Data.invoice_Id}`;

      console.log("Invoice tracking " + url);
      const response = await getData(url);
      console.log("Invoice tracking", response);
      //   Data.repair_System.repair_Id

      if (response.status === 200) {
        setTripTrackingData(response?.data?.data?.trip);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getInvoiceTracking();
    getInvoiceById();
  }, []);

  const formatDate = (date: any) => {
    return new Date(date)
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-");
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h3 className="page-title">
                  Invoice ID - {Data.invoice_refid}
                </h3>
              </div>
              <div className="col-sm-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={routes.adminDashboard.path}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.invoiceList.path} className="text-black">
                      Invoices
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{Data.invoice_refid}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="card">
                <div className="card-body card-bodybt">
                  <div className="invoice-grid">
                    <div className="invoice-hdr">
                      <div className="invoice-hdr-left">
                        <img src={loginLogo} alt="" />
                        <h4>INVOICE - {repairViewData?.invoice_refid}</h4>
                      </div>
                      <div className="invoice-hdr-right">
                        <div className="invoice-status-bg">
                          <strong>
                            Total Amount : ${repairViewData?.total}
                          </strong>
                          <div>
                            Status :{" "}
                            <span className="badge status-inprogress">
                              {repairViewData?.invoice_status}
                            </span>
                          </div>
                          <div>
                            Date :{" "}
                            {repairViewData?.invoice_date
                              ? formatDate(repairViewData.invoice_date)
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-row invoice-row-bb">
                      <div className="d-flex justify-content-between">
                        <div className="invoice-st">
                          Service Type -{" "}
                          <strong>{repairViewData?.service_type}</strong>
                        </div>
                        <div className="invoice-dd">
                          Due Date -{" "}
                          <strong>
                            {repairViewData?.due_date
                              ? formatDate(repairViewData.due_date)
                              : ""}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-row invoice-row-bb">
                      <div className="d-flex justify-content-between">
                        <div className="invoice-address">
                          <div className="invoice-ft">From</div>
                          <strong>{loginusername}</strong>
                          <p className="mb-0">Email : {loginusereamil}</p>
                          <p className="mb-0">
                            Phone Number : {loginuserphone}
                          </p>
                        </div>
                        <div className="invoice-address">
                          <div className="invoice-ft">To</div>
                          <strong>{repairViewData?.send_to_user_name}</strong>
                          <p className="mb-0">
                            Email : {repairViewData?.send_to_user_email}
                          </p>
                          <p className="mb-0">
                            Phone Number : {repairViewData?.send_to_user_phone}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-row">
                      <table className="table table-center add-table-items">
                        <thead>
                          <tr>
                            <th>Repair Item</th>
                            <th>Cost</th>
                            <th className="text-end">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(repairViewData?.repair_components &&
                          repairViewData?.repair_components.length > 0
                            ? repairViewData.repair_components
                            : defaultRepairComponents
                          ).map((item: any, index: any) => (
                            <tr key={index}>
                              <td>{item.component}</td>{" "}
                              {/* Display the component name */}
                              <td>
                                <span className="text-muted">
                                  ${item.cost.toFixed(2)}
                                </span>
                              </td>{" "}
                              {/* Display the cost */}
                              <td className="text-end">
                                ${item.amount.toFixed(2)}
                              </td>{" "}
                              {/* Display the amount */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="invoice-row">
                      <ul className="invoice-price">
                        <li>
                          <span className="invoice-price-lbl text-muted">
                            Subtotal
                          </span>
                          <span className="invoice-price-val">
                            ${repairViewData?.sub_amount?.toFixed(2)}
                          </span>
                        </li>
                        <li>
                          <span className="invoice-price-lbl text-muted">
                            Tax
                          </span>
                          <span className="invoice-price-val">
                            $
                            {(
                              (repairViewData?.total || 0) -
                              (repairViewData?.sub_amount || 0)
                            ).toFixed(2)}
                          </span>
                        </li>
                        <li>
                          <span className="invoice-price-lbl">
                            <b>Total</b>
                          </span>
                          <span className="invoice-price-val">
                            <b>${repairViewData?.total?.toFixed(2)}</b>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="invoice-row">
                      <div className="d-flex justify-content-between">
                        <div className="invoice-notes">
                          <div className="invoice-notes-icon">
                            <img src={notes} alt="" />
                          </div>
                          <div className="invoice-notes-val">
                            <strong className="d-block">Note</strong>
                            <p>{repairViewData?.notes_term} </p>
                          </div>
                        </div>
                        <div className="invoice-sign">
                          <p>Authorized Sign</p>
                          <img
                            src={repairViewData?.authorised_sign}
                            alt=""
                            style={{ height: "100px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              {/* Sub Header */}
              <div className="sub-header">
                <div className="sub-header-content">
                  <ul className="nav">
                    <li>
                      <h4>Invoice Tracking</h4>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /Sub Header */}
              {/* Table */}
              <div className="card">
                <div className="tracking-list">
                  <div className="card-body">
                    <div className="trip-tracking">
                      <ul>
                        {tripTrackingData?.map((item: any, index) => (
                          <li key={index}>
                            <h5>{item?.label}</h5>
                            <h6>{item?.description}</h6>
                            <p>
                              Date:{" "}
                              {new Date(item?.created_at).toLocaleString()}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="details-btn">
                            <button
                            onClick={() => {
                                dispatch(setSelectedData(Data));
                                openModal();
                            }}
                            className="btn btn-add w-100"
                            disabled={Data.vehicle_status !== "major" && Data.trip_status !== "Breakdown"}
                            >
                            Assign New Vehicle
                            </button>
                        </div> */}
              {/* /Table */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ServiceInvoiceDetails;

function setRepairStatus(repair_status: any) {
  throw new Error("Function not implemented.");
}
