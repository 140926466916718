import React, { useContext, useState } from "react";
import { loginImg, loginLogo } from "../../utils/imagepath";
import { Link } from "react-router-dom";
import { routes } from "../../utils/routes";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ApiServiceContext } from "../../utils/shared.module";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompanyDetails,
  setLoginToken,
  setPlanDetails,
  setRole,
  setUserDetails,
} from "../../core/redux/loginSlice";
import {
  email,
  userNameRegex,
  emailMaxLength,
  passwordMaxLength,
} from "../../utils/patterns/regex.pattern";
import { end_points } from "../../services/end_point/end_points";
import LoginPopUp from "./LoginPopup";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subdomain = useSelector((state: any) => state.login.domain);
  const [loginVisible, setLoginVisible] = useState(false);
  const [paymentVisible, setPaymentVisible] = useState(false);

  const findPath = window.location.pathname;
  const isAdmin = findPath.split("/")[1];
  const { postData } = useContext(ApiServiceContext);

  const loginValidation = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Please enter a valid email")
      .trim()
      .matches(email, "Please enter a valid email"),

    password: yup.string().required("Password is required"),
  });

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidation),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      let urls;
      if (isAdmin === "super-admin") {
        urls = end_points.login.url; // super-admin
      } else if (isAdmin === "course-admin") {
        urls = end_points.course_login.url; // course-admin
      } else {
        urls = end_points.admin_login.url; // admin
      }

      console.log(subdomain);
      // Create the payload with domain_name conditionally added if it exists
      const payload = {
        email: data?.email,
        password: data?.password,
        ...(isAdmin === "super-admin" || isAdmin === "course-admn"
          ? {}
          : subdomain
          ? { domain_name: subdomain }
          : {}), // Ensure domain_name is added if subdomain exists
      };

      localStorage.setItem("companyEmail", payload.email);

      const response = await postData(urls, payload);

      if (response?.status === 200) {
        const loginData = response?.data?.data?.user;
        const companyData = response?.data?.data?.company;
        const planData = response?.data?.data?.plan;
        const token = response?.data?.data?.token;
        const role = response?.data?.data?.user?.roleid;
        console.log("response", response?.data?.data);

        if (token) {
          dispatch(setLoginToken(token));
          dispatch(setUserDetails(loginData));
          dispatch(setCompanyDetails(companyData));
          dispatch(setPlanDetails(planData));
          dispatch(setRole(role));
          localStorage.setItem("token", token);
          if (role === 1) {
            navigate(routes.adminDashboard.path);
          } else if (role === 3) {
            if (!loginData?.fleetTerm_condition) {
              navigate(routes.fleetTermCondition.path);
            } else {
              navigate(routes.fleetManagerDashboard.path);
            }
          } else if (role === 4) {
            navigate(routes.serviceManagerDashboard.path);
          } else if (isAdmin === "course-admin") {
            if (!loginData?.courseTerm_condition) {
              navigate(routes.courseTermCondition.path);
            } else {
              navigate(routes.courseAdminDashboard.path);
            }
          } else {
            navigate(routes.dashboard.path);
          }
        }
      }
    } catch (error) {
      console.log(error);
      const errorDetails = error.response
        ? error.response.data.response.errors
        : null;
      console.log(errorDetails, "errorDetials");

      if (error?.response && error?.response?.status === 403) {
        localStorage.setItem("companyFirstName", errorDetails);
        setLoginVisible(true);
      }
    }
  };

  return (
    <>
      <div className="log-wrapper login-lft">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <img src={loginImg} alt="login-img" className="login-lft-img" />
              <div className="login-content">
                <h1>
                  “Transforming the Road Ahead for Truck Drivers and Fleet
                  Managers”
                </h1>
                <p>Quick Inspections, Less Paperwork, More Road Time</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="log-wrapper login-rht">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-form d-block">
                <img src={loginLogo} alt="logo" />
                <div className="login-form-inner">
                  <h2 className="text-center text-uppercase">Login</h2>
                  <p className="mt-2">Enter Below Details to Continue Login</p>
                  <div className="cc-login-form text-start">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email address
                        </label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder="Enter Email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              onBlur={() => trigger("email")}
                              onChange={(event: any) => {
                                const lowerCaseValue =
                                  event.target.value.toLowerCase();
                                field.onChange(lowerCaseValue);
                                trigger("email");
                              }}
                              maxLength={emailMaxLength}
                            />
                          )}
                        />
                        {errors.email && (
                          <div className="invalid-feedback">
                            {errors.email.message}
                          </div>
                        )}
                      </div>

                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <Controller
                          name="password"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="password"
                              placeholder="Enter Password"
                              className={`form-control ${
                                errors.password ? "is-invalid" : ""
                              }`}
                              onBlur={() => trigger("password")}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger("password");
                              }}
                              maxLength={passwordMaxLength}
                            />
                          )}
                        />
                        {errors.password && (
                          <div className="invalid-feedback">
                            {errors.password.message}
                          </div>
                        )}
                      </div>

                      <button
                        type="submit"
                        className="btn login-btn menu btn-hvr w-100"
                      >
                        Login
                      </button>
                    </form>
                    <div className="forgot-user">
                      {isAdmin === "super-admin" ? (
                        <Link
                          to={routes.forgotPassword.path}
                          className="login-fgt"
                        >
                          Forgot Password?
                        </Link>
                      ) : isAdmin === "course-admin" ? (
                        <Link
                          to={routes.courseForgotPassword.path}
                          className="login-fgt"
                        >
                          Forgot Password?
                        </Link>
                      ) : (
                        <Link
                          to={routes.adminForgotPassword.path}
                          className="login-fgt"
                        >
                          Forgot Password?
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginPopUp
        loginVisible={loginVisible}
        setLoginVisible={setLoginVisible}
        paymentVisible={paymentVisible}
        setPaymentVisible={setPaymentVisible}
      />
      {/* <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Body>
          <GalleryList
            galleryItems={galleryItems}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default Login;
